import {
  Badge,
  Button,
  ButtonGroup,
  IndexTable,
  TextStyle,
} from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";

export default function Rows({ data = [] }) {
  return data.map(
    (
      {
        _id,
        title,
        description,
        selectType,
        maxSelectableSeans,
        active,
        seansTime,
      },
      index
    ) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>
          <Link url={`/reservationTypes/${_id}`}>
            <TextStyle variation="strong">{title}</TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="whitespace-pre-wrap">{description}</div>
        </IndexTable.Cell>
        <IndexTable.Cell>{selectType}</IndexTable.Cell>
        <IndexTable.Cell>{seansTime}</IndexTable.Cell>
        <IndexTable.Cell>{maxSelectableSeans}</IndexTable.Cell>
        <IndexTable.Cell>
          {active === true ? (
            <Badge status="success">Aktif</Badge>
          ) : (
            <Badge status="critical">Pasif</Badge>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ButtonGroup>
            <Button
              url={`/reservationTypes/${_id}`}
              icon={EditMinor}
              plain
              monochrome
            />
          </ButtonGroup>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
