import { IndexTable, Page, Card } from "@shopify/polaris";
import { useHistory } from "react-router";
import { useQuery } from "react-query";

import Rows from "./Rows";
import { getAllPackages } from "api";

export default function List(props) {
  const history = useHistory();

  const { data, isLoading } = useQuery("packages", () =>
    getAllPackages("?isDeleted=false")
  );

  const resourceName = {
    singular: "Paket",
    plural: "Paketler",
  };

  const accounting = data || [];

  return (
    <Page
      title="Paketler"
      primaryAction={{
        content: "Yeni Kayıt",
        onAction: () => history.push("/reservationPackages/new"),
      }}
    >
      <Card>
        <IndexTable
          selectable={false}
          loading={isLoading}
          lastColumnSticky
          resourceName={resourceName}
          itemCount={accounting.length}
          headings={[
            { title: "Başlık" },
            { title: "Açıklama" },
            { title: "İçerik" },
            { title: "Toplam Fiyat" },
            { title: "Durum" },
            { title: "" },
          ]}
        >
          <Rows data={accounting} />
        </IndexTable>
      </Card>
    </Page>
  );
}
