import {
  Page,
  DataTable,
  Card,
  TextField,
  FormLayout,
  Layout,
  Button,
  Label,
} from "@shopify/polaris";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { format } from "date-fns";
import { useFormik } from "formik";

import { getReservationsByEducators, getAllEducators } from "api";
import { validationSchema } from "./validationSchema";

export function ReservationsReports(props) {
  const educatorsQuery = useQuery("educators", getAllEducators);
  const reservationMutation = useMutation(
    "reservationByEducatorsReport",
    getReservationsByEducators
  );
  const today = new Date();
  const educators = educatorsQuery?.data || [];

  const reportsData = reservationMutation?.data || [];

  const postReportsHandler = ({ startDate, endDate }) => {
    const query = `?startDate=${startDate}&endDate=${endDate}`;

    reservationMutation.mutate(query, {
      onSuccess: (response) => {},
      onError: (error) => {},
    });
  };

  const { values, setFieldValue, errors, handleBlur, touched, handleSubmit } =
    useFormik({
      initialValues: {
        startDate: `${today.getFullYear()}-${today.getMonth() + 1}-01`,
        endDate: `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`,
      },
      onSubmit: postReportsHandler,
      validationSchema,
    });

  return (
    <Page title="Günlük Rezervasyon Raporu">
      <Layout sectioned>
        <Layout.Section fullWidth>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  value={values?.startDate}
                  onChange={(value) => setFieldValue("startDate", value)}
                  type="date"
                  label="Başlangıç Tarihi"
                  error={
                    errors.startDate && touched.startDate && errors.startDate
                  }
                  onBlur={handleBlur}
                  name="startDate"
                />
                <TextField
                  value={values?.endDate}
                  onChange={(value) => setFieldValue("endDate", value)}
                  type="date"
                  label="Bitiş Tarihi"
                  onBlur={handleBlur}
                  error={errors.endDate && touched.endDate && errors.endDate}
                  name="endDate"
                />
                <div>
                  <div className="pb-2 opacity-0">
                    <Label>ads</Label>
                  </div>
                  <Button
                    primary
                    loading={reservationMutation?.isLoading}
                    onClick={handleSubmit}
                  >
                    Sorgula
                  </Button>
                </div>
              </FormLayout.Group>
            </FormLayout>
            <div className="mb-10"></div>
            <DataTable
              hoverable
              totalsName={{ singular: "Toplam", plural: "Toplam" }}
              columnContentTypes={["text", ...educators?.map((e) => "numeric")]}
              headings={["Tarih", ...educators?.map((e) => e?.name)]}
              rows={
                educators &&
                reportsData?.map((r) => [
                  format(new Date(r?._id), "dd.MM.yyyy"),
                  ...educators.map(
                    (e) =>
                      `${
                        r.educators
                          ?.find((re) => re?._id?._id === e?._id)
                          ?.total?.toString() || 0
                      } adet`
                  ),
                ])
              }
              totals={[
                "",
                ...educators.map(
                  (e) =>
                    `${reportsData
                      ?.filter((r) =>
                        r?.educators?.some((re) => re?._id?._id === e?._id)
                      )
                      ?.reduce((acc, a) => acc + +a?.totalReservation, 0)} adet`
                ),
              ]}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
