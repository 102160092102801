import { Layout, Page, ContextualSaveBar } from "@shopify/polaris";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import { sendSms } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm/SharedForm";

export function NewSms(props) {
  const skipToContentRef = useRef(null);
  const history = useHistory();

  const initialValues = useRef({
    title: "",
    message: "",
    customers: []
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { toggleSnack } = useSnackbar();

  const postMutation = useMutation(sendSms);

  const onSubmitHandler = (values, helpers) => {
    postMutation.mutate(values, {
      onSuccess: (response) => {
        toggleSnack({
          key: "newCustomerSuccess",
          message: "SMS başarıyla gönderildi.",
          active: true,
        });

        history.push("/sms");
      },
      onError: (error) => {
        toggleSnack({
          key: "newCustomerError",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
      onSettled: () => {
        helpers.setSubmitting(false)
      }
    });
  };

  return (
    <Page
      title="Yeni SMS"
      fullWidth
      breadcrumbs={[{ content: "SMS", url: "/sms" }]}
    >
      <Layout sectioned>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <SharedForm
          onSubmit={onSubmitHandler}
          initialValues={initialValues.current}
          savebarRender={(values) => (
            <Savebar {...values} isLoading={postMutation.isLoading} />
          )}
        />
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Toplu Gönder",
      }}
      message="Yeni SMS"
      discardAction={{
        onAction: () => history.push("/sms"),
        content: "Geri",
      }}
    />
  );
}
