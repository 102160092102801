import { Badge, IndexTable, TextStyle, Button } from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";
import { startOfDay } from "date-fns";

export function ReservationsRows({ data = [] }) {
  return data.map(
    (
      {
        _id,
        date,
        from,
        to,
        status,
        description,
        creditValue,
        customer,
        educator,
        createdAt,
        reservationType,
      },
      index
    ) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>
          <Link url={`/reservations/${_id}`}>
            <TextStyle variation="strong">
              {new Date(date)?.toLocaleDateString()}
            </TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{reservationType?.title || "-"}</IndexTable.Cell>
        <IndexTable.Cell>
          {from}-{to}
        </IndexTable.Cell>

        <IndexTable.Cell>{customer?.name || "-"}</IndexTable.Cell>
        <IndexTable.Cell>{educator?.name || "-"}</IndexTable.Cell>
        <IndexTable.Cell>{creditValue}</IndexTable.Cell>
        <IndexTable.Cell>
          {new Date(createdAt).toLocaleString()}
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Badge
            status={
              status === "Aktif"
                ? "success"
                : status === "İptal"
                  ? "critical"
                  : "info"
            }
          >
            {status}
          </Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Button
            plain
            monochrome
            icon={EditMinor}
            url={`/reservations/${_id}`}
          ></Button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
