import { Link } from "components";

export function Logo(props) {
  return (
    <h1 className="text-xl font-bold">
      <Link href="/">
        <span className="pr-2">Hara</span>
        <span className="bg-blue-500 text-white text-xs rounded-full py-0.5 px-2">
          Admin
        </span>
      </Link>
    </h1>
  );
}
