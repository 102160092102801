import { Layout, Page, ContextualSaveBar } from "@shopify/polaris";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

import { createOneUser } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm/SharedForm";

export function NewUser(props) {
  const skipToContentRef = useRef(null);

  const initialValues = useRef({
    name: "",
    email: "",
    tel: "",
    password: "",
    avatar: undefined,
    status: "Aktif",
  });

  const { toggleSnack } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postMutation = useMutation(createOneUser, {
    onSuccess: (response) => {
      toggleSnack({
        key: "newUserSuccess",
        message: "Kullanıcı kaydı başarıyla oluşturulmuştur",
        active: true,
      });
    },
    onError: (error) => {
      toggleSnack({
        key: "newUserError",
        error: true,
        message: error?.response?.data?.message,
        active: true,
      });
    },
  });

  return (
    <Page
      title="Yeni Kullanıcı"
      narrowWidth={true}
      breadcrumbs={[{ content: "Kullanıcılar", url: "/users" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <SharedForm
          onSubmit={(values) => postMutation.mutate(values)}
          initialValues={initialValues.current}
          savebarRender={(values) => (
            <Savebar {...values} isLoading={postMutation.isLoading} />
          )}
        />
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Kaydet",
      }}
      message="Yeni kullanıcı"
      discardAction={{
        onAction: () => history.push("/users"),
        content: "Geri",
      }}
    />
  );
}
