import * as yup from "yup";

export const validationSchema = yup.object().shape({
  subject: yup
    .string()
    .required("Konu alanı zorunludur")
    .min(4, "Konu alanı en az 4 karakterden oluşmalıdır."),
  message: yup
    .string()
    .required("Konu alanı zorunludur")
    .min(4, "Mesaj alanı en az 4 karakterden oluşmalıdır."),
});
