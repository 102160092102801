import {
  Card,
  ChoiceList,
  FormLayout,
  Label,
  Layout,
  Select,
  TextField,
} from "@shopify/polaris";
import { getAllReservationTypes, queryAccountingCategories } from "api";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { array } from "utils";

export function TypeSection({ isDetail }) {
  const query = "?active=true&sort=order";
  const reservationTypesQuery = useQuery(["reservationTypes", query], () =>
    getAllReservationTypes(query)
  );

  const reservationTypesData = array(reservationTypesQuery?.data);
  const { values, setFieldValue, handleBlur } = useFormikContext();

  const creditValues = array(values?.credits);

  const handleChangeCreditValue = (reservationTypeId, value) => {
    const copyCreditValues = [...creditValues];
    const findIndex = copyCreditValues.findIndex(
      (credit) => credit?.reservationType === reservationTypeId
    );

    if (findIndex > -1) {
      copyCreditValues[findIndex].creditValue = +value;
    } else {
      copyCreditValues.push({
        reservationType: reservationTypeId,
        creditValue: +value,
      });
    }
    setFieldValue("credits", copyCreditValues);
  };

  return (
    <Layout.AnnotatedSection title="Diğer">
      <Card sectioned>
        <FormLayout>
          <TextField
            type="date"
            value={values.date}
            label="Tarih"
            requiredIndicator
            onChange={(value) => setFieldValue("date", value)}
          />
          <div className="border border-dashed px-8 py-7 rounded-md bg-gray-50">
            <Label requiredIndicator>
              <span className="font-medium">Bakiye Adetleri</span>
            </Label>
            <div className="mt-3 pt-7 border-t space-y-5">
              {reservationTypesData?.map((reservationType) => (
                <TextField
                  key={reservationType?._id}
                  type="number"
                  inputMode="numeric"
                  min={0}
                  value={String(
                    creditValues.find(
                      (credit) =>
                        credit?.reservationType === reservationType?._id
                    )?.creditValue || ""
                  )}
                  label={reservationType?.title + " için bakiye adeti"}
                  onChange={(value) =>
                    handleChangeCreditValue(reservationType?._id, value)
                  }
                />
              ))}
            </div>
          </div>
          <TextField
            type="number"
            inputMode="numeric"
            value={String(values.amount)}
            prefix="₺"
            label="Tutar"
            requiredIndicator
            onChange={(value) => setFieldValue("amount", value)}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
