import { ContextualSaveBar, Layout, Page } from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { getOneEducator, updateOneEducator } from "api";
import { SkeletonPage } from "components";
import { useToast } from "hooks";
import { SharedForm } from "../SharedForm";

export function EducatorDetail(props) {
  const history = useHistory();
  const { addMessage } = useToast();
  const skipToContentRef = useRef(null);
  const { educatorId } = useParams();

  const educatorQuery = useQuery(["educators", educatorId], () =>
    getOneEducator(educatorId)
  );

  const educatorData = educatorQuery?.data || {};

  const initialValues = {
    name: educatorData?.name,
    email: educatorData?.email,
    tel: educatorData?.tel,
    avatar: undefined,
    status: educatorData?.status || "Aktif",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const patchMutation = useMutation(
    (payload) => updateOneEducator(payload, educatorId),
    {
      onSuccess: (response) => {
        addMessage({
          content: "Eğitmen kaydı başarıyla güncellenmiştir",
        });
      },
      onError: (error) => {
        addMessage({
          error: true,
          message: error?.response?.data?.message,
        });
      },
    }
  );

  if (educatorQuery?.isLoading) {
    return <SkeletonPage narrowWidth />;
  }

  return (
    <Page
      title="Eğitmen Detay"
      narrowWidth={true}
      breadcrumbs={[{ content: "Eğitmenler", url: "/educators" }]}
      primaryAction={{
        content: "Eğitmen Saatleri",
        onAction: () => history.push(`/educators/${educatorId}/availability`),
      }}
      actionGroups={[
        {
          title: "Diğer",
          actions: [
            {
              content: "İzin günleri",
              onAction: () =>
                history.push(`/educators/${educatorId}/unavailability`),
            },
            {
              content: "Üyeler",
              onAction: () => history.push(`/educators/${educatorId}/members`),
            }
          ]
        }
      ]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {initialValues && (
          <SharedForm
            initialValues={initialValues}
            isDetail={true}
            onSubmit={(values) => patchMutation.mutate(values)}
            savebarRender={(values) => (
              <Savebar {...values} isLoading={patchMutation.isLoading} />
            )}
          />
        )}
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Güncelle",
      }}
      message="Eğitmen Detay"
      discardAction={{
        onAction: () => history.push("/educators"),
        content: "Geri",
      }}
    />
  );
}
