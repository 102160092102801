import { Badge, Navigation } from "@shopify/polaris";

import { useLocation } from "react-router";

export function Sidebar(props) {
  const { pathname } = useLocation();
  return (
    <Navigation location={pathname}>
      <Navigation.Section
        title="Genel"
        items={[
          {
            label: "Ana sayfa",
            url: "/",
            exactMatch: true,
            onClick: props.onClickedNavItem,
          },
          {
            url: "/reports",
            label: "Raporlar",
            subNavigationItems: [
              {
                url: "/reports",
                disabled: false,
                label: "Ödemeler Raporu",
              },
              {
                url: "/reports/reservations",
                disabled: false,
                label: "Rezervasyonlar Raporu",
              },
            ],
          },
          {
            label: "Rezervasyonlar",
            url: "/reservations",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Ödemeler",
            url: "/payments",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Paket Talepleri",
            url: "/paymentRequests",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Üyeler",
            url: "/customers",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Eğitmenler",
            url: "/educators",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Atlar",
            url: "/horses",
            onClick: props.onClickedNavItem,
          },

          {
            label: "Muhasebe",
            url: "/accounting",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Destek Talepleri",
            url: "/helps",
            onClick: props.onClickedNavItem,
          },
          {
            label: "SMS Paneli",
            url: "/sms",
            onClick: props.onClickedNavItem,
          },
        ]}
      />
      <Navigation.Section
        title="Diğer"
        items={[
          {
            label: "Profil",
            url: "/profile",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Paketler",
            url: "/reservationPackages",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Rezervasyon Türleri",
            url: "/reservationTypes",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Kullanıcılar",
            url: "/users",
            onClick: props.onClickedNavItem,
          },
          {
            label: "Sürüm Notları",
            url: "/surum-notlari",
            onClick: props.onClickedNavItem,
          },
        ]}
      />
    </Navigation>
  );
}
