import {
  IndexTable,
  Page,
  Card,
} from "@shopify/polaris";
import { useHistory } from "react-router";
import { useQuery } from "react-query";

import { getAllSMS } from "api";
import { SMSRows } from "./SMSRows";
import { array } from "utils";

export function SMSList(props) {
  const history = useHistory();

  const smsQuery = useQuery(["sms"], () =>
    getAllSMS()
  );

  const resourceName = {
    singular: "sms",
    plural: "sms",
  };

  const sms = array(smsQuery.data?.docs);

  return (
    <Page
      title="SMS Paneli"
      fullWidth
      primaryAction={{
        content: "Yeni SMS",
        onAction: () => history.push("/sms/send"),
      }}
    >
      <Card>
        <IndexTable
          selectable={false}
          loading={smsQuery.isFetching}
          resourceName={resourceName}
          itemCount={sms.length}
          lastColumnSticky
          headings={[
            {
              title: "Başlık"
            },
            {
              title: "Mesaj"
            },
            {
              title: "Başarılı / Gönderilen"
            }
          ]}
        >
          <SMSRows
            data={sms}
          />
        </IndexTable>
      </Card>
    </Page>
  );
}
