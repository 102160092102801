import { axios } from "utils";

export const availableReservations = (payload) =>
  axios
    .post(`/reservations/availableReservations`, payload)
    .then((res) => res.data?.docs);

export const getAvailableReservationTimes = (query = "") =>
  axios
    .get(`/reservations/availableReservationTimes?${query}`)
    .then((res) => res?.data);

export const getAllReservations = (query = "") =>
  axios.get(`/reservations?${query}`).then((res) => res.data);

export const queryReservations = (payload) =>
  axios.post(`/reservations/query`, payload).then((res) => res.data?.docs);

export const getOneReservation = (reservationId) =>
  axios.get(`/reservations/${reservationId}`).then((res) => res.data?.doc);

export const createOneReservation = (payload) =>
  axios.post("/reservations/adminCreate", payload).then((res) => res.data?.doc);

export const updateOneReservation = (payload, reservationId) =>
  axios
    .patch(`/reservations/${reservationId}`, payload)
    .then((res) => res.data?.doc);

export const deleteManyReservations = (payload) =>
  axios.post(`/reservations/deleteMany`, payload).then((res) => res.data?.docs);

export const cancelReservation = ({ reservationId, payload }) =>
  axios
    .post(`/reservations/${reservationId}/admin/cancel`, payload)
    .then((res) => res.data);

export const exportReservations = async ({ query = "", payload }) => {
  const res = await axios.post(`/reservations/export${query}`, payload, {
    responseType: "arraybuffer",
  });
  return res?.data;
};
