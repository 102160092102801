import { Button, ButtonGroup, IndexTable, TextStyle } from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";
import { array, formatCurrency } from "utils";

export default function Rows({ data = [] }) {
  return data.map(
    ({ _id, title, description, definitions, totalPrice, active }, index) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>
          <Link url={`/reservationPackages/${_id}`}>
            <TextStyle variation="strong">{title}</TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="whitespace-pre-wrap">{description}</div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <table>
            <tbody>
              {array(definitions).map((def) => (
                <tr key={def?._id}>
                  <td>{def?.reservationType?.title || "-"}</td>
                  <td className="pl-4">·</td>
                  <td className="px-4">{def?.creditCount} Kredi</td>
                  <td className="pr-4">·</td>
                  <td>{formatCurrency(def?.price)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </IndexTable.Cell>
        <IndexTable.Cell>{formatCurrency(totalPrice)}</IndexTable.Cell>
        <IndexTable.Cell>{active === true ? "Aktif" : "Pasif"}</IndexTable.Cell>
        <IndexTable.Cell>
          <ButtonGroup>
            <Button
              url={`/reservationPackages/${_id}`}
              icon={EditMinor}
              plain
              monochrome
            />
          </ButtonGroup>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
