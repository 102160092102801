import {
  Layout,
  Page,
  Card,
  TextStyle,
  Banner,
  IndexTable,
  Link,
} from "@shopify/polaris";
import { useRef } from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { SkeletonPage } from "components";
import {
  aggregateCustomers,
} from "api";
import { array } from "utils";

export function EducatorMembers(props) {
  const skipToContentRef = useRef(null);
  const { educatorId } = useParams();

  const mainMembersQuery = useQuery(["members", "mainEducator", educatorId], () => aggregateCustomers(`?status=Aktif&mainEducator=${educatorId}`));
  const backupMembersQuery = useQuery(["members", "backupEducator", educatorId], () => aggregateCustomers(`?status=Aktif&backupEducator=${educatorId}`));

  const mainMembersData = array(mainMembersQuery?.data?.docs)
  const backupMembersData = array(backupMembersQuery?.data?.docs)

  if (mainMembersQuery?.isLoading || backupMembersQuery?.isFetching) {
    return <SkeletonPage narrowWidth />;
  }

  if (mainMembersQuery?.isError || backupMembersQuery?.isError) {
    return (
      <Page
        title="Eğitmene atanan üyeler"
        narrowWidth={true}
        breadcrumbs={[
          { content: "Eğitmen Detay", url: `/educators/${educatorId}` },
        ]}
      >
        <Layout sectioned>
          <Layout.Section fullWidth>
            <Banner
              status="critical"
              title={
                mainMembersQuery?.error?.response?.data?.message ||
                mainMembersQuery?.error?.message ||
                backupMembersQuery?.error?.response?.data?.message ||
                backupMembersQuery?.error?.message ||
                "Bir hata oluştu"
              }
            ></Banner>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <Page
      title="Eğitmene atanan üyeler"
      narrowWidth={true}
      breadcrumbs={[
        { content: "Eğitmen Detay", url: `/educators/${educatorId}` },
      ]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <Layout.Section fullWidth>
          <Card sectioned title="Ana üyeler">
            <IndexTable
              emptyState="Henüz bir üye atanmamış"
              selectable={false}
              resourceName={{
                singular: "Ana Üyeler",
                plural: "Ana Üyeler",
              }}
              itemCount={mainMembersData?.length}
              headings={[
                { title: "Üye" },
                { title: "E-posta" },
                { title: "Telefon" },
              ]}
              loading={mainMembersQuery?.isFetching}
            >
              {mainMembersData?.map(({ _id, name, email, tel }, index) => (
                <IndexTable.Row id={_id} key={_id} position={index}>
                  <IndexTable.Cell>
                    <TextStyle>
                      {name}
                    </TextStyle>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <TextStyle>
                      {email}
                    </TextStyle>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <TextStyle>
                      {tel}
                    </TextStyle>
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </Card>
        </Layout.Section>
        <Layout.Section fullWidth>
          <Card sectioned title="Yedek atanan üyeler">
            <IndexTable
              emptyState="Henüz bir üye atanmamış"
              selectable={false}
              resourceName={{
                singular: "Yedek Üyeler",
                plural: "Yedek Üyeler",
              }}
              itemCount={backupMembersData?.length}
              headings={[
                { title: "Üye No" },
                { title: "İsim Soyisim" },
                { title: "E-posta" },
                { title: "Telefon" },
              ]}
              loading={backupMembersQuery?.isFetching}
            >
              {backupMembersData?.map(({ _id, memberNo, name, email, tel }, index) => (
                <IndexTable.Row id={_id} key={_id} position={index}>
                  <IndexTable.Cell>
                    <Link url={`/customers/${_id}`}>
                      {memberNo}
                    </Link>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <TextStyle>
                      {name}
                    </TextStyle>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <TextStyle>
                      {email}
                    </TextStyle>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <TextStyle>
                      {tel}
                    </TextStyle>
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
