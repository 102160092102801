import {
  Layout,
  Page,
  ContextualSaveBar,
  Card,
  FormLayout,
  Label,
  Spinner,
} from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "react-query";

import { createOneReservation, getAllReservationTypes } from "api";
import { WeekDates } from "components";
import { ReservationConfirmModal } from "./ReservationConfirmModal";
import { useSnackbar } from "providers";
import { array } from "utils";
import { Formik, useFormikContext } from "formik";
import {
  CustomerSection,
  EducatorSection,
  ReservationType,
  SeansTimes,
  SelectSeansCount,
} from "./components";
import { validationSchema } from "./validationSchema";

export const seansLabelMapper = {
  Saat: "Rezervasyon Saati",
  Adet: "Adet",
};

export function NewReservation() {
  const history = useHistory();
  const { toggleSnack } = useSnackbar();

  const reservastionTypesQuery = useQuery(["reservationTypes", "Aktif"], () =>
    getAllReservationTypes("?active=true&sort=order")
  );
  const reservationTypes = array(reservastionTypesQuery.data);

  const postMutation = useMutation(createOneReservation);

  if (reservastionTypesQuery.isLoading || reservationTypes.length === 0) {
    return <Spinner />;
  }

  const onSubmitHandler = (values, helpers) => {
    postMutation.mutate(values, {
      onSuccess: (response) => {
        toggleSnack({
          key: "reservationSuccess",
          error: false,
          message: "Rezervasyon kaydı başarıyla oluşturulmuştur.",
          active: true,
        });
        // TODO: push to reservation detail page
        history.push("/reservations");
      },
      onError: (error) => {
        helpers.setSubmitting(false);
        toggleSnack({
          key: "reservationError",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    });
  };

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        reservationTypeId: "",
        customer: "",
        customerId: "",
        educatorId: "",
        reservationTypes,
        seansCount: 1,
        hasCredit: true,
        from: "",
        to: "",
        date: "",
        isOpenConfirmModal: false,
      }}
      onSubmit={onSubmitHandler}
      enableReinitialize={true}
      validationSchema={validationSchema()}
    >
      <NewReservationComp />
    </Formik>
  );
}

function NewReservationComp(props) {
  const skipToContentRef = useRef(null);
  const { values, setFieldValue, isValid, setValues, isSubmitting } =
    useFormikContext();

  useEffect(() => {
    if (!values?.customer?._id || !values?.reservationTypeId)
      return setFieldValue("hasCredit", true);

    const customerCreditBySelectedReservationType = array(
      values?.customer?.credits
    ).find((credit) => credit?.reservationType === values?.reservationTypeId);

    const isExistsCustomerCredits =
      customerCreditBySelectedReservationType &&
      customerCreditBySelectedReservationType?.count >= +values?.seansCount;

    setFieldValue("hasCredit", isExistsCustomerCredits);
  }, [values?.customer, values?.reservationTypeId, values?.seansCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedReservationType = array(values?.reservationTypes).find(
    (item) => item?._id === values?.reservationTypeId
  );

  return (
    <Page
      title="Yeni Rezervasyon"
      breadcrumbs={[{ content: "Rezervasyonlar", url: "/reservations" }]}
    >
      <Layout sectioned>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {isValid && (
          <>
            <Savebar
              isValid={isValid}
              isLoading={isSubmitting}
              openConfirmModal={() => setFieldValue("isOpenConfirmModal", true)}
            />
            <ReservationConfirmModal />
          </>
        )}
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <ReservationType />
              <CustomerSection />
              <EducatorSection />
              <SelectSeansCount />
            </FormLayout>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <FormLayout>
              <WeekDates
                onChange={(date) => {
                  setValues({
                    ...values,
                    date: date || "",
                    from: "",
                    to: "",
                  });
                }}
              />
              <Label requiredIndicator>
                {seansLabelMapper[selectedReservationType?.selectType] ||
                  "Rezervasyon Saati"}
              </Label>
              <SeansTimes />
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

function Savebar({ openConfirmModal, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: openConfirmModal,
        loading: isLoading,
        disabled: !isValid,
        content: "Rezervasyon Onayı",
      }}
      discardAction={{
        onAction: () => history.push("/reservations"),
        content: "Geri",
      }}
    />
  );
}
