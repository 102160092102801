import { createContext, useState, useContext } from "react";

const SnackbarContext = createContext();

export function SnackbarProvider(props) {
  const [state, setState] = useState([]);

  return (
    <SnackbarContext.Provider value={{ state, setState }}>
      {props.children}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  const { state, setState } = context;

  const toggleSnack = (payload) => {
    const { key, message, error = false, active = false } = payload;
    const copyState = [...state];

    const index = copyState.findIndex((s) => s.key === key);

    if (index === -1) {
      copyState.push({ key, message, error, active });
    } else {
      let obj = copyState[index];
      copyState[index] = { ...obj, message, error, active: !obj.active };
    }
    setState(copyState);
  };

  return {
    state,
    toggleSnack,
  };
}
