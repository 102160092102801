import {
  Layout,
  Page,
  Card,
  Heading,
  TextStyle,
  Spinner,
  Banner,
  Avatar,
  FormLayout,
  Label,
  IndexTable,
  Select,
  TextField,
  Tag,
  Button,
  Badge,
} from "@shopify/polaris";
import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useMutation, useQuery } from "react-query";
import { ToggleButton, SkeletonPage } from "components";
import {
  queryEducatorHours,
  queryEducatorUnavailabilities,
  createEducatorUnavailability,
  deleteEducatorUnavailability,
  upsertEducatorHours,
  getOneEducator,
  getAllEducators,
  upsertEducatorTime,
} from "api";
import { useToast } from "hooks";
import { DeleteMinor } from "@shopify/polaris-icons";
import { endOfDay, startOfDay } from "date-fns";

export function EducatorUnavailability(props) {
  const [form, setForm] = useState({ from: "", to: "" });
  const history = useHistory();
  const [loadingCurrent, setLoadingCurrent] = useState(null);
  const skipToContentRef = useRef(null);
  const { educatorId } = useParams();
  const { addMessage } = useToast();
  const educatorsQuery = useQuery(["educators"], () => getAllEducators());

  const educatorsData = educatorsQuery?.data || [];

  const educatorUnavailabilitiesQuery = useQuery(
    ["educatorUnavailibilities", educatorId],
    () =>
      queryEducatorUnavailabilities({ educator: educatorId, isDeleted: false })
  );

  const createEducatorUnavailabilityMutation = useMutation(
    createEducatorUnavailability
  );
  const deleteEducatorUnavailabilityMutation = useMutation(
    deleteEducatorUnavailability
  );

  const createHandler = () => {
    const payload = { ...form, educator: educatorId };

    payload.from = startOfDay(new Date(payload.from));
    payload.to = endOfDay(new Date(payload.to));

    createEducatorUnavailabilityMutation?.mutate(payload, {
      onSuccess: async (response) => {
        addMessage({ content: "Başarılı" });
        setForm({ from: "", to: "" });
        await educatorUnavailabilitiesQuery.refetch();
      },
      onError: (error) => {
        const errorMessage =
          error?.response?.data?.message || error?.message || "Bir hata oluştu";
        addMessage({ content: errorMessage, duration: 1000, error: true });
        console.log(error?.message);
      },
    });
  };

  const deleteHandler = (id) => {
    deleteEducatorUnavailabilityMutation?.mutate(
      { id, educator: educatorId },
      {
        onSuccess: async (response) => {
          addMessage({ content: "Başarılı" });
          await educatorUnavailabilitiesQuery.refetch();
        },
        onError: (error) => {
          const errorMessage =
            error?.response?.data?.message ||
            error?.message ||
            "Bir hata oluştu";
          addMessage({ content: errorMessage, duration: 1000, error: true });
          console.log(error?.message);
        },
      }
    );
  };

  const unavailabilityData = educatorUnavailabilitiesQuery?.data || [];

  if (educatorsQuery?.isLoading || educatorUnavailabilitiesQuery?.isFetching) {
    return <SkeletonPage narrowWidth />;
  }

  if (educatorsQuery?.isError || educatorUnavailabilitiesQuery?.isError) {
    return (
      <Page
        title="Eğitmen İzin Günleri"
        narrowWidth={true}
        breadcrumbs={[
          { content: "Eğitmen Detay", url: `/educators/${educatorId}` },
        ]}
      >
        <Layout sectioned>
          <Layout.Section fullWidth>
            <Banner
              status="critical"
              title={
                educatorsQuery?.error?.response?.data?.message ||
                educatorsQuery?.error?.message ||
                educatorUnavailabilitiesQuery?.error?.response?.data?.message ||
                educatorUnavailabilitiesQuery?.error?.message ||
                "Bir hata oluştu"
              }
            ></Banner>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <Page
      title="Eğitmen İzin Günleri"
      narrowWidth={true}
      breadcrumbs={[
        { content: "Eğitmen Detay", url: `/educators/${educatorId}` },
      ]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <Layout.Section fullWidth>
          <Card sectioned>
            <FormLayout>
              <Select
                label="Eğitmen Seçiniz"
                value={educatorId}
                onChange={(eId) => {
                  history.push(`/educators/${eId}/unavailability`);
                }}
                options={educatorsData?.map((e) => ({
                  label: e?.name,
                  value: e?._id,
                }))}
              />
            </FormLayout>
          </Card>
        </Layout.Section>
        <Layout.Section fullWidth>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  label="Başlangıç"
                  type="date"
                  value={form.from}
                  onChange={(value) => setForm({ ...form, from: value })}
                />
                <TextField
                  label="Bitiş"
                  type="date"
                  value={form.to}
                  onChange={(value) => setForm({ ...form, to: value })}
                />
                <div>
                  <div style={{ visibility: "hidden", marginBottom: "3px" }}>
                    <Label>ASD</Label>
                  </div>
                  <Button
                    disabled={!form.from || !form.to}
                    primary
                    loading={createEducatorUnavailabilityMutation?.isLoading}
                    onClick={createHandler}
                  >
                    Ekle
                  </Button>
                </div>
              </FormLayout.Group>
            </FormLayout>
            <IndexTable
              selectable={false}
              resourceName={{
                singular: "İzin günleri",
                plural: "İzin günleri",
              }}
              itemCount={unavailabilityData?.length}
              headings={[
                { title: "Başlangıç Tarihi" },
                { title: "Bitiş Tarihi" },
                { title: "İşlem" },
              ]}
              loading={educatorUnavailabilitiesQuery?.isFetching}
            >
              {unavailabilityData?.map(({ _id, from, to }, index) => (
                <IndexTable.Row id={_id} key={_id} position={index}>
                  <IndexTable.Cell>
                    <TextStyle>{new Date(from)?.toLocaleString()}</TextStyle>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {new Date(to)?.toLocaleString()}
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Button
                      icon={DeleteMinor}
                      onClick={() => deleteHandler(_id)}
                      loading={deleteEducatorUnavailabilityMutation?.isLoading}
                      size="slim"
                      plain
                      destructive
                    ></Button>
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
