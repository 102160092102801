import {
  Banner,
  Card,
  ContextualSaveBar,
  Layout,
  Modal,
  Page,
} from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { getOneCustomer, updateOneCustomer } from "api";
import { SkeletonPage } from "components";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm";
import { Customer } from "../Model";

const statusMapper = {
  Aktif: {
    primaryAction: {
      content: "Pasifleştir",
      destructive: true,
    },
    oppositeStatus: "Pasif",
    content: (
      <div>
        Üye durumu <span className="font-medium">pasif</span> olarak
        değiştirilecektir. Onaylıyor musunuz?
      </div>
    ),
  },
  Pasif: {
    primaryAction: {
      content: "Aktifleştir",
    },
    oppositeStatus: "Aktif",
    content: (
      <div>
        Üye durumu <span className="font-medium">aktif</span> olarak
        değiştirilecektir. Onaylıyor musunuz?
      </div>
    ),
  },
};

export function CustomerDetail(props) {
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const skipToContentRef = useRef(null);
  const { customerId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customerQuery = useQuery(["customers", customerId], () =>
    getOneCustomer(customerId)
  );

  const { toggleSnack } = useSnackbar();

  const customerData = Customer(customerQuery?.data?.doc);

  const initialValues = customerData;

  const patchMutation = useMutation(
    (payload) => updateOneCustomer(payload, customerId),
    {
      onSuccess: async (response) => {
        await customerQuery.refetch();
        toggleSnack({
          key: "customerUpdateSuccess",
          message: "Üye kaydı başarıyla güncellenmiştir",
          active: true,
        });
      },
      onError: (error) => {
        toggleSnack({
          key: "customerUpdateError",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    }
  );

  if (customerQuery?.isLoading) {
    return <SkeletonPage narrowWidth />;
  }
  const currentCustomerStatus = statusMapper[customerData?.status];

  const onMemberStatusChangeHandler = () => {
    patchMutation.mutate(
      {
        status: currentCustomerStatus?.oppositeStatus,
      },
      {
        onSuccess: async (response) => {
          setConfirmModalIsVisible(false);
        },
      }
    );
  };

  return (
    <Page
      title="Üye Detay"
      narrowWidth={true}
      breadcrumbs={[{ content: "Üyeler", url: "/customers" }]}
      primaryAction={
        currentCustomerStatus && {
          content: currentCustomerStatus?.primaryAction?.content,
          destructive: currentCustomerStatus?.primaryAction?.destructive,
          onAction: () => setConfirmModalIsVisible(true),
        }
      }
    >
      <Modal
        title="Üye Durumu Değiştir"
        open={confirmModalIsVisible}
        onClose={() => setConfirmModalIsVisible(false)}
        primaryAction={{
          content: currentCustomerStatus?.primaryAction?.content,
          destructive: currentCustomerStatus?.primaryAction?.destructive,
          onAction: onMemberStatusChangeHandler,
          loading: patchMutation?.isLoading,
        }}
      >
        <Modal.Section>
          {currentCustomerStatus && currentCustomerStatus?.content}
        </Modal.Section>
      </Modal>
      <Layout sectioned>
        {customerQuery?.isError && (
          <Card sectioned>
            <Banner
              status="warning"
              title={
                customerQuery?.error?.response?.data?.message ||
                customerQuery?.error?.message ||
                "Hata oluştu"
              }
            />
          </Card>
        )}
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {initialValues && (
          <SharedForm
            initialValues={initialValues}
            isDetail={true}
            onSubmit={(values) => patchMutation.mutate(values)}
            savebarRender={(values) => (
              <Savebar {...values} isLoading={patchMutation.isLoading} />
            )}
          />
        )}
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        content: "Güncelle",
      }}
      message="Üye Detay"
      discardAction={{
        onAction: () => history.push("/customers"),
        content: "Geri",
      }}
    />
  );
}
