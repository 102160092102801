import {
  Button,
  Card,
  FormLayout,
  Layout,
  Page,
  TextField,
  Banner,
} from "@shopify/polaris";
import { useState } from "react";
import { Form, Formik } from "formik";
import { useAuth } from "providers";
import { validationSchema } from "./validationSchema";

export function Login(props) {
  const { makeAdminLoginRequest, isLoading } = useAuth();
  const [error, setError] = useState("");

  const onSubmitHandler = (values) => {
    setError("");
    makeAdminLoginRequest({
      payload: values,
      onSuccess: () => {
        alert("Login işlemi başarılı");
      },
      onError: (error) => {
        console.log(error);
        setError(error?.message);
      },
      onSettled: () => {},
    });
  };
  return (
    <Page title="Hara Admin Panel" narrowWidth>
      <Layout>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({ values, handleBlur, isValid, handleSubmit, setFieldValue }) => (
            <Layout.AnnotatedSection
              title="Giriş Yap"
              description="Sisteme giriş yaparak rezervasyonlarınızı yönetin."
            >
              <Form onSubmit={handleSubmit}>
                <Card sectioned>
                  <FormLayout>
                    {error && <Banner status="critical">{error}</Banner>}
                    <TextField
                      label="Email"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={(value) => setFieldValue("email", value)}
                      onBlur={handleBlur}
                    />
                    <TextField
                      label="Şifre"
                      type="password"
                      password="password"
                      labelAction={{
                        content: "Şifremi unuttum?",
                        url: "/forgotPassword",
                      }}
                      value={values.password}
                      onChange={(value) => setFieldValue("password", value)}
                      onBlur={handleBlur}
                    />
                    <Button
                      loading={isLoading}
                      submit
                      primary
                      disabled={!isValid}
                    >
                      Giriş Yap
                    </Button>
                  </FormLayout>
                </Card>
              </Form>
            </Layout.AnnotatedSection>
          )}
        </Formik>
      </Layout>
    </Page>
  );
}
