import {
  IndexTable,
  Page,
  Card,
  Tabs,
  Modal,
  DescriptionList,
  TextStyle,
  Banner,
} from "@shopify/polaris";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "react-query";

import { aggregateCustomers, deleteOneCustomer } from "api";
import { CustomerRows } from "./CustomerRows";
import { ExportModal } from "./ExportModal";
import { useState } from "react";
import { array } from "utils";
import { CustomerFilter } from "./CustomerFilter";
import { useDebounce } from "hooks";
import { useSnackbar } from "providers";

const initialFilters = {
  status: "",
  memberType: "",
  totalCredits: [],
  totalCreditsValue: [],
  totalReservations: [],
};

const makeQuery = (filters, query) => {
  const filtersArr = Object.keys(filters)
    .filter((filterKey) => {
      const filterValue = filters[filterKey];
      return Array.isArray(filterValue)
        ? filterValue.length > 0
        : typeof filterValue === "string" && !!filterValue;
    })
    .map((filterKey) => {
      const filterValue = filters[filterKey];
      return `${filterKey}=${
        Array.isArray(filterValue)
          ? filterValue.filter((item) => !!item).join(",")
          : filterValue
      }`;
    });

  const queryArr = !!query ? [`query=${query}`] : [];

  return queryArr.concat(filtersArr).join("&");
};

const statusMapper = {
  0: "Aktif",
  1: "Pasif",
};

const actionModalInitialValues = {
  visible: false,
  data: null,
  type: "delete",
};

export function CustomersList(props) {
  const [actionModal, setActionModal] = useState(actionModalInitialValues);
  const [selectedTab, setSelectedTab] = useState(0);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState(initialFilters);
  const history = useHistory();
  const [exportModalIsVisible, setExportModalIsVisible] = useState(false);

  const debouncedQuery = useDebounce(makeQuery(filters, query), 700);

  const { toggleSnack } = useSnackbar();

  const deleteMutation = useMutation(deleteOneCustomer);

  const queryStr = [debouncedQuery, `status=${statusMapper[selectedTab]}`]
    .filter((item) => !!item)
    .join("&");

  const customersQuery = useQuery(["customersWithAggregate", queryStr], () =>
    aggregateCustomers(`?${queryStr || ""}`)
  );

  const resourceName = {
    singular: "üye",
    plural: "üyeler",
  };

  const customers = array(customersQuery.data?.docs);

  const openActionModal = (
    data = actionModalInitialValues.data,
    type = actionModalInitialValues.type
  ) => {
    setActionModal({
      visible: true,
      data,
      type,
    });
  };

  const closeActionModal = () => {
    setActionModal(actionModalInitialValues);
  };

  const onDeleteSubmit = () => {
    deleteMutation.mutate(actionModal?.data?._id, {
      onSuccess: async () => {
        closeActionModal();
        toggleSnack({
          key: "deleteCustomerSuccess",
          message: "Üye başarıyla silindi",
        });
        await customersQuery.refetch();
      },
      onError: (err) => {
        toggleSnack({
          key: "deleteCustomerError",
          error: true,
          message:
            err?.response?.data?.message || error?.message || "Hata oluştu",
        });
      },
    });
  };

  return (
    <Page
      title="Üyeler"
      fullWidth
      secondaryActions={[
        {
          key: "export",
          content: "Dışarı Aktar",
          onAction: () => {
            setExportModalIsVisible(true);
          },
        },
      ]}
      primaryAction={{
        content: "Yeni Üye",
        onAction: () => history.push("/customers/new"),
      }}
    >
      <ExportModal
        visible={exportModalIsVisible}
        onClose={() => setExportModalIsVisible(false)}
        totalData={customers.length}
        filter=""
      />
      <Modal
        title="Üye Sil"
        open={actionModal?.visible && actionModal?.type === "delete"}
        secondaryActions={[
          {
            key: "cancel",
            content: "İptal",
            onAction: closeActionModal,
          },
        ]}
        primaryAction={{
          content: "Sil",
          onAction: onDeleteSubmit,
          loading: deleteMutation.isLoading,
          destructive: true,
        }}
      >
        <Modal.Section>
          <Banner title="Üye Bilgileri" status="warning">
            <DescriptionList
              items={[
                { term: "No", description: actionModal?.data?.memberNo },
                {
                  term: "İsim Soyisim",
                  description: actionModal?.data?.name,
                },
                {
                  term: "Email",
                  description: actionModal?.data?.email,
                },
              ]}
            />
          </Banner>
          <div className="mt-10">
            <TextStyle>
              Yukarıdaki bilgilere ait üye kaydı{" "}
              <span className="font-bold">silinecektir</span>. Onaylıyor
              musunuz?
            </TextStyle>
          </div>
        </Modal.Section>
      </Modal>
      <Card>
        <Tabs
          selected={selectedTab}
          tabs={[
            { id: "aktif", panelID: "aktif", content: "Aktif Olanlar" },
            { id: "pasif", panelID: "pasif", content: "Pasif Olanlar" },
          ]}
          onSelect={(selectedTabIndex) => setSelectedTab(selectedTabIndex)}
        >
          <div className="px-6 py-6">
            <CustomerFilter
              query={query}
              setQuery={setQuery}
              filters={filters}
              setFilters={setFilters}
              initialFilters={initialFilters}
            />
          </div>
          <IndexTable
            selectable={false}
            loading={customersQuery.isFetching}
            resourceName={resourceName}
            itemCount={customers.length}
            lastColumnSticky
            headings={[
              { title: "Üye Numarası" },
              { title: "İsim Soyisim" },
              { title: "Telefon" },
              { title: "Üyelik Türü" },
              { title: "Durum" },
              { title: "Son Rezervasyon" },
              { title: "Toplam Rezervasyon" },
              { title: "Kalan Kredi" },
              { title: "Toplam Kredi" },
              { title: "Aksiyonlar" },
            ]}
          >
            <CustomerRows
              data={customers}
              onDelete={(data) => openActionModal(data)}
            />
          </IndexTable>
        </Tabs>
      </Card>
    </Page>
  );
}
