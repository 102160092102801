import {
  Card,
  FormLayout,
  Label,
  Layout,
  Select,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { PhoneNumber } from "components";
import { useFormikContext } from "formik";
import { useAddressHook } from "hooks/useAddressHook";
import { MeslekSearch } from "./MeslekSearch";

export function OtherInfoSection({ isDetail }) {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    errors,
    touched,
  } = useFormikContext();

  const { illerData, ilcelerData, illerQuery, ilcelerQuery } = useAddressHook({
    il: values?.il,
  });
  return (
    <Layout.AnnotatedSection
      title="Diğer Bilgiler"
      description="Bu bölümde üyeye ait diğer bilgileri eksiksiz ve doğru girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            multiline={2}
            label="Adres"
            name="address"
            requiredIndicator={true}
            value={values.address}
            error={errors?.address && touched?.address && errors?.address}
            onChange={(value) => setFieldValue("address", value)}
            onBlur={handleBlur}
          />
          {illerQuery?.isFetching ? (
            <>
              <Label requiredIndicator={true}>İl</Label>
              <div className="mt-3"></div>
              <Spinner size="small" />
            </>
          ) : (
            <Select
              options={[""].concat(illerData)}
              label="İl"
              requiredIndicator={true}
              name="il"
              value={values?.il}
              onChange={(value) => {
                setFieldValue("il", value);
                setFieldValue("ilce", "");
              }}
              onBlur={() => setFieldTouched("il", true)}
              error={touched?.il && errors?.il}
            />
          )}

          {ilcelerQuery?.isFetching ? (
            <>
              <Label requiredIndicator={true}>İlçe</Label>
              <div className="mt-3"></div>
              <Spinner size="small" />
            </>
          ) : (
            <Select
              options={[""].concat(ilcelerData)}
              label="İlçe"
              requiredIndicator={true}
              name="ilce"
              value={values?.ilce}
              onChange={(value) => setFieldValue("ilce", value)}
              onBlur={() => setFieldTouched("ilce", true)}
              error={touched?.ilce && errors?.ilce}
            />
          )}
          <MeslekSearch />
          <TextField
            label="İletişime geçilebilecek ikinci kişi - Yakınlığı - Velisi"
            requiredIndicator
            name="yakini"
            error={errors?.yakini && touched?.yakini && errors?.yakini}
            value={values.yakini}
            onChange={(value) => setFieldValue("yakini", value)}
            onBlur={handleBlur}
          />
          <PhoneNumber
            label="İletişime geçilebilecek ikinci kişi GSM No"
            requiredIndicator={true}
            allowEmptyFormatting={true}
            name="yakinTel"
            error={errors?.yakinTel && touched?.yakinTel && errors?.yakinTel}
            value={values.yakinTel}
            onChange={(value) => setFieldValue("yakinTel", value)}
            onBlur={handleBlur}
          />
          <TextField
            label="Alerji Durumu"
            name="alerjiDurumu"
            error={
              errors?.alerjiDurumu &&
              touched?.alerjiDurumu &&
              errors?.alerjiDurumu
            }
            value={values.alerjiDurumu}
            onChange={(value) => setFieldValue("alerjiDurumu", value)}
            onBlur={handleBlur}
            helpText="Alerji durumu yoksa boş bırakın"
          />
          <Select
            label="Kan Grubu"
            name="kanGrubu"
            requiredIndicator
            error={touched?.kanGrubu && errors?.kanGrubu}
            onBlur={handleBlur}
            options={["", "0-", "0+", "A-", "A+", "B-", "B+", "AB-", "AB+"]}
            onChange={(value) => setFieldValue("kanGrubu", value)}
            value={values.kanGrubu}
          />
          <Select
            label="Gözlük - Lens Kullanım Durumu"
            options={[
              "",
              "Gözlük kullanıyorum",
              "Lens kullanıyorum",
              "İkisini de kullanmıyorum",
            ].map((opt) => ({ label: opt, value: opt }))}
            requiredIndicator
            name="gozlukLensDurumu"
            error={touched?.gozlukLensDurumu && errors?.gozlukLensDurumu}
            onBlur={handleBlur}
            onChange={(value) => setFieldValue("gozlukLensDurumu", value)}
            value={values?.gozlukLensDurumu}
          />
          <TextField
            type="date"
            helpText="Binicilik sporu yapmak için sağlık engelinizin olmadığına dair bir sağlık raporunuz varsa rapor tarihini ve kurumunu giriniz."
            label="Doktor Raporu Tarihi"
            name="doktorRaporuTarihi"
            error={touched?.doktorRaporuTarihi && errors?.doktorRaporuTarihi}
            value={
              values?.doktorRaporuTarihi
                ? values.doktorRaporuTarihi?.split("T")?.[0]
                : ""
            }
            onChange={(value) => setFieldValue("doktorRaporuTarihi", value)}
            onBlur={handleBlur}
          />
          <TextField
            label="Raporu Veren Kurum"
            name="doktorRaporuKurum"
            error={touched?.doktorRaporuKurum && errors?.doktorRaporuKurum}
            value={values.doktorRaporuKurum}
            onChange={(value) => setFieldValue("doktorRaporuKurum", value)}
            onBlur={handleBlur}
          />
          <TextField
            label="Referans Kişisi"
            name="referans"
            error={touched?.referans && errors?.referans}
            value={values.referans}
            onChange={(value) => setFieldValue("referans", value)}
            onBlur={handleBlur}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
