import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { Label } from "@shopify/polaris";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/tr";

import { useEffect, useState } from "react";
import "./style.css";

export default function RangePicker({
  startDate,
  startDateId,
  endDate,
  endDateId,
  onDatesChange,
  onFocusChange,
  label,
}) {
  const [focusedInput, setFocusedInput] = useState();

  return (
    <div className="flex-1">
      {label && (
        <div style={{ paddingBottom: "0.4rem" }}>
          <Label>{label}</Label>
        </div>
      )}
      <div style={{ zIndex: 9999, height: "100%" }}>
        <DateRangePicker
          block={false}
          startDate={startDate}
          endDate={endDate}
          startDatePlaceholderText="Başlangıç Tarihi"
          endDatePlaceholderText="Bitiş Tarihi"
          isOutsideRange={(day) => false}
          renderCalendarInfo={false}
          onDatesChange={({ startDate, endDate }) =>
            onDatesChange?.({ startDate, endDate })
          }
          minimumNights={0}
          firstDayOfWeek={1}
          onFocusChange={(i) => setFocusedInput(i)}
          focusedInput={focusedInput}
        />
      </div>
    </div>
  );
}
