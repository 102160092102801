import { Card, FormLayout, Layout, Avatar, TextStyle } from "@shopify/polaris";
import { useFormikContext } from "formik";

import { Link } from "components";

export function Customer({ isDetail }) {
  const { values } = useFormikContext();

  console.log(values);
  return (
    <Layout.AnnotatedSection title="Müşteri Detay">
      <Card sectioned>
        <FormLayout>
          <div className="py-5 flex items-start">
            <div>
              <Avatar customer name={values?.customer?.name} />
            </div>
            <div className="pl-2  ml-2">
              <div>
                <Link url={`/customers/${values?.customer?._id}`}>
                  <TextStyle variation="strong">İsim: </TextStyle>
                  <TextStyle>{values?.customer?.name}</TextStyle>
                </Link>
              </div>
              <div>
                <TextStyle variation="strong">Email: </TextStyle>
                <TextStyle>{values?.customer?.email}</TextStyle>
              </div>
              <div>
                <TextStyle variation="strong">Telefon: </TextStyle>
                <TextStyle>{values?.customer?.tel}</TextStyle>
              </div>
            </div>
          </div>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
