import {
  Layout,
  Page,
  Card,
  Heading,
  TextStyle,
  Spinner,
  Banner,
  Avatar,
  FormLayout,
  Label,
  IndexTable,
  Select,
  TextField,
  Tag,
  Button,
  Badge,
} from "@shopify/polaris";
import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useMutation, useQuery } from "react-query";
import { ToggleButton, SkeletonPage } from "components";
import {
  queryHorseHours,
  queryHorseUnavailabilities,
  createHorseUnavailability,
  deleteHorseUnavailability,
  upsertHorseHours,
  getOneHorse,
  getAllHorses,
  upsertHorseTime,
} from "api";
import { useToast } from "hooks";
import { DeleteMinor } from "@shopify/polaris-icons";
import { endOfDay, startOfDay } from "date-fns";

export function HorseUnavailability(props) {
  const [form, setForm] = useState({ from: "", to: "" });
  const history = useHistory();
  const [loadingCurrent, setLoadingCurrent] = useState(null);
  const skipToContentRef = useRef(null);
  const { horseId } = useParams();
  const { addMessage } = useToast();
  const horsesQuery = useQuery(["horses"], () => getAllHorses());

  const horsesData = horsesQuery?.data || [];

  const horseUnavailabilitiesQuery = useQuery(
    ["horseUnavailibilities", horseId],
    () => queryHorseUnavailabilities({ horse: horseId, isDeleted: false })
  );

  const createHorseUnavailabilityMutation = useMutation(
    createHorseUnavailability
  );
  const deleteHorseUnavailabilityMutation = useMutation(
    deleteHorseUnavailability
  );

  const createHandler = () => {
    const payload = { ...form, horse: horseId };

    payload.from = startOfDay(new Date(payload.from));
    payload.to = endOfDay(new Date(payload.to));

    createHorseUnavailabilityMutation?.mutate(payload, {
      onSuccess: async (response) => {
        addMessage({ content: "Başarılı" });
        setForm({ from: "", to: "" });
        await horseUnavailabilitiesQuery.refetch();
      },
      onError: (error) => {
        const errorMessage =
          error?.response?.data?.message || error?.message || "Bir hata oluştu";
        addMessage({ content: errorMessage, duration: 1000, error: true });
        console.log(error?.message);
      },
    });
  };

  const deleteHandler = (id) => {
    deleteHorseUnavailabilityMutation?.mutate(
      { id, horse: horseId },
      {
        onSuccess: async (response) => {
          addMessage({ content: "Başarılı" });
          await horseUnavailabilitiesQuery.refetch();
        },
        onError: (error) => {
          const errorMessage =
            error?.response?.data?.message ||
            error?.message ||
            "Bir hata oluştu";
          addMessage({ content: errorMessage, duration: 1000, error: true });
          console.log(error?.message);
        },
      }
    );
  };

  const unavailabilityData = horseUnavailabilitiesQuery?.data || [];

  if (horsesQuery?.isLoading || horseUnavailabilitiesQuery?.isFetching) {
    return <SkeletonPage narrowWidth />;
  }

  if (horsesQuery?.isError || horseUnavailabilitiesQuery?.isError) {
    return (
      <Page
        title="At İzin Günleri"
        narrowWidth={true}
        breadcrumbs={[{ content: "At Detay", url: `/horses/${horseId}` }]}
      >
        <Layout sectioned>
          <Layout.Section fullWidth>
            <Banner
              status="critical"
              title={
                horsesQuery?.error?.response?.data?.message ||
                horsesQuery?.error?.message ||
                horseUnavailabilitiesQuery?.error?.response?.data?.message ||
                horseUnavailabilitiesQuery?.error?.message ||
                "Bir hata oluştu"
              }
            ></Banner>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <Page
      title="At İzin Günleri"
      narrowWidth={true}
      breadcrumbs={[{ content: "At Detay", url: `/horses/${horseId}` }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <Layout.Section fullWidth>
          <Card sectioned>
            <FormLayout>
              <Select
                label="At Seçiniz"
                value={horseId}
                onChange={(eId) => {
                  history.push(`/horses/${eId}/unavailability`);
                }}
                options={horsesData?.map((e) => ({
                  label: e?.name,
                  value: e?._id,
                }))}
              />
            </FormLayout>
          </Card>
        </Layout.Section>
        <Layout.Section fullWidth>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  label="Başlangıç"
                  type="date"
                  value={form.from}
                  onChange={(value) => setForm({ ...form, from: value })}
                />
                <TextField
                  label="Bitiş"
                  type="date"
                  value={form.to}
                  onChange={(value) => setForm({ ...form, to: value })}
                />
                <div>
                  <div style={{ visibility: "hidden", marginBottom: "3px" }}>
                    <Label>ASD</Label>
                  </div>
                  <Button
                    disabled={!form.from || !form.to}
                    primary
                    loading={createHorseUnavailabilityMutation?.isLoading}
                    onClick={createHandler}
                  >
                    Ekle
                  </Button>
                </div>
              </FormLayout.Group>
            </FormLayout>
            <IndexTable
              selectable={false}
              resourceName={{
                singular: "İzin günleri",
                plural: "İzin günleri",
              }}
              itemCount={unavailabilityData?.length}
              headings={[
                { title: "Başlangıç Tarihi" },
                { title: "Bitiş Tarihi" },
                { title: "İşlem" },
              ]}
              loading={horseUnavailabilitiesQuery?.isFetching}
            >
              {unavailabilityData?.map(({ _id, from, to }, index) => (
                <IndexTable.Row id={_id} key={_id} position={index}>
                  <IndexTable.Cell>
                    <TextStyle>{new Date(from)?.toLocaleString()}</TextStyle>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {new Date(to)?.toLocaleString()}
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Button
                      icon={DeleteMinor}
                      onClick={() => deleteHandler(_id)}
                      loading={deleteHorseUnavailabilityMutation?.isLoading}
                      size="slim"
                      plain
                      destructive
                    ></Button>
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
