import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function InfoSection({ isDetail }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();
  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="Atın temel bilgilerini eksiksiz ve doğru girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            label="İsim"
            name="name"
            value={values.name}
            onChange={(value) => setFieldValue("name", value)}
            onBlur={handleBlur}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
