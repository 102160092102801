import { tcknCheck } from "utils/tcknCheck";
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  isDetail: yup.boolean(),
  memberNo: yup.string().required("Lütfen bir üye numarası oluşturunuz"),
  tckn: yup
    .string()
    .required("T.C. Kimlik No boş olamaz.")
    .max(11, "T.C. Kimlik No 11 karakter olmalıdır.")
    .min(11, "T.C. Kimlik No 11 karakter olmalıdır.")
    .test("tckn", "T.C. Kimlik No geçersiz.", tcknCheck),
  name: yup
    .string()
    .required("İsim alanı zorunludur")
    .min(4, "İsim alanı en az 4 karakterden oluşmalıdır."),
  email: yup
    .string()
    .required("Email alanı zorunludur")
    .email("Geçerli bir email adresi girin."),
  password: yup.string().when("isDetail", {
    is: true,
    then: yup.string().notRequired().nullable(),
    otherwise: yup
      .string()
      .required("Şifre alanı zorunludur")
      .min(6, "Şifre en az 6 karakterden oluşmalıdır."),
  }),
  birthDate: yup.date().required("Doğum tarihi alanı zorunludur."),
  memberType: yup.string().required("Üyelik türü alanı zorunludur."),
  mainEducator: yup.string().nullable(),
  backupEducator: yup.string().nullable(),
  address: yup.string().required("Lütfen bir adres giriniz."),
  il: yup.string().required("Lütfen bir il seçiniz."),
  ilce: yup.string().required("Lütfen bir ilçe seçiniz."),
  meslek: yup.string().required("Lütfen bir meslek seçiniz."),
  yakini: yup
    .string()
    .required("Yakınının ismi alanı zorunludur")
    .min(5, "Yakını alanı en az 5 karakterden oluşmalıdır."),
  tel: yup
    .string()
    .required("Telefon alanı zorunludur.")
    .matches(
      /^[+]90.[(]\d{3}[)].\d{3}-\d{4}/,
      "Lütfen uygun formatta bir telefon numarası giriniz"
    ),
  kanGrubu: yup.string().required("Lütfen bir kan grubu seçiniz."),
  gozlukLensDurumu: yup
    .string()
    .required("Lütfen bir gozluk lens durumu seçiniz."),
  yakinTel: yup
    .string()
    .required("Yakınının GSM Numarası alanı zorunludur.")
    .matches(
      /^[+]90.[(]\d{3}[)].\d{3}-\d{4}/,
      "Lütfen uygun formatta bir yakını için bir telefon numarası giriniz"
    ),
});
