import { Fragment } from "react";

import { Toast, Frame } from "@shopify/polaris";
import { useSnackbar } from "providers";

export function Snackbar(props) {
  const { state, toggleSnack } = useSnackbar();
  return (
    <Frame>
      {state.map((s, index) => (
        <Fragment key={index}>
          {s.active ? (
            <Toast
              content={s.message}
              error={s.error}
              onDismiss={() => toggleSnack({ ...s })}
            />
          ) : null}
        </Fragment>
      ))}
      {props.children}
    </Frame>
  );
}
