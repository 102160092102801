import { SHORT_DAYS, SHORT_MONTHS } from "@constants";
import { Button, ButtonGroup, Icon } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import {
  addWeeks,
  intlFormat,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  startOfToday,
} from "date-fns";
import { useWeekDates } from "hooks";
import { useEffect } from "react";

function dateFormat(date) {
  const newDate = new Date(date);

  return [
    newDate.getDate(),
    SHORT_MONTHS[newDate.getMonth()],
    SHORT_DAYS[newDate.getDay()],
  ];
}

export function WeekDates(props) {
  const {
    weeks,
    goToday,
    previousWeek,
    nextWeek,
    selectedDay,
    handleSelectedDate,
  } = useWeekDates();

  const isDisabledDate = (date) => {
    return (
      !props?.renderWithoutDisabled &&
      (isBefore(date, startOfToday()) || isAfter(date, addWeeks(new Date(), 2)))
    );
  };

  const isSelectedDayDisabled = isDisabledDate(selectedDay);

  useEffect(() => {
    if (isSelectedDayDisabled) return;
    const isoDate = `${selectedDay.getFullYear()}-${String(
      selectedDay.getMonth() + 1
    ).padStart(2, "0")}-${String(selectedDay.getDate()).padStart(2, "0")}`;
    props.onChange(isoDate);
  }, [selectedDay]);

  const onSelectHandler = (date) => {
    handleSelectedDate(date);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <ButtonGroup segmented fullWidth>
          <Button
            size="slim"
            icon={<Icon source={ChevronLeftMinor} />}
            onClick={previousWeek}
          />
          <Button onClick={nextWeek} size="slim">
            {intlFormat(
              new Date(selectedDay),
              {
                year: "numeric",
                month: "short",
              },
              {
                locale: "tr-TR",
              }
            )}
          </Button>
          <Button
            size="slim"
            icon={<Icon source={ChevronRightMinor} />}
            onClick={nextWeek}
          />
        </ButtonGroup>
        <Button onClick={goToday} size="slim">
          Bugün
        </Button>
      </div>
      <div className="grid grid-cols-7 gap-2 my-8">
        {weeks.map((week, index) => (
          <Button
            size="slim"
            key={index}
            fullWidth={true}
            disabled={isDisabledDate(week)}
            primary={isSameDay(week, selectedDay)}
            onClick={() => onSelectHandler(startOfDay(week))}
          >
            {dateFormat(week).map((date, index) => (
              <div className="text-lg md:text-xl" key={index}>
                {date}
              </div>
            ))}
          </Button>
        ))}
      </div>
    </div>
  );
}
