import { Switch, Route } from "react-router";
import { Login, ResetPassword, ForgotPassword } from "views";

export function UnauthenticatedApp(props) {
  return (
    <Switch>
      <Route exact path="/resetPassword/:resetToken">
        <ResetPassword />
      </Route>
      <Route exact path="/forgotPassword">
        <ForgotPassword />
      </Route>
      <Login />
    </Switch>
  );
}
