import { Avatar, Button, IndexTable, TextStyle } from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";

export function EducatorRows({ data = [], membersCount = {} }) {
  return data.map(
    ({ _id, name, email, statistics, tel, status, avatar }, index) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>{index + 1}</IndexTable.Cell>
        <IndexTable.Cell>
          <div className="py-5 flex items-center">
            <div>
              <Avatar customer name={name} source={avatar} />
            </div>
            <div className="pl-2 flex flex-col">
              <Link url={`/educators/${_id}`}>
                <TextStyle variation="strong">{name}</TextStyle>
              </Link>
              <TextStyle variation="subdued">{email}</TextStyle>
            </div>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>{tel}</IndexTable.Cell>
        <IndexTable.Cell>{membersCount?.[_id]?.mainMembers} / 70</IndexTable.Cell>
        <IndexTable.Cell>{membersCount?.[_id]?.backupMembers} / 70</IndexTable.Cell>
        <IndexTable.Cell>{statistics?.lessons}</IndexTable.Cell>
        <IndexTable.Cell>{status}</IndexTable.Cell>
        <IndexTable.Cell>
          <Button
            plain
            monochrome
            icon={EditMinor}
            url={`/educators/${_id}`}
          ></Button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
