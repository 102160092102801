import { IndexTable, TextStyle } from "@shopify/polaris";
import { Link } from "components";

export function AccountingCategoriesRows({ data = [], selectedResources }) {
  return data.map(({ _id, title, description, type }, index) => (
    <IndexTable.Row
      id={_id}
      key={_id}
      selected={selectedResources.includes(_id)}
      position={index}
      subdued
    >
      <IndexTable.Cell>
        <Link url={`/accounting/categories/${_id}`}>
          <TextStyle variation="strong">{title}</TextStyle>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{description}</IndexTable.Cell>
      <IndexTable.Cell>{type}</IndexTable.Cell>
    </IndexTable.Row>
  ));
}
