import { createContext, useReducer, useContext } from "react";

import { useMutation } from "react-query";
import { adminLogin, checkAdminToken, adminLogout } from "api";
import { axios } from "utils";

const AuthContext = createContext();

function authReducer(state, action) {
  switch (action.type) {
    case "login": {
      const { user, token } = action.payload;
      return { ...state, user, token, isLoggedIn: true };
    }
    case "logout": {
      return { ...state, user: null, token: null, isLoggedIn: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function AuthenticationProvider(props) {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    token: null,
    isLoggedIn: false,
  });

  const value = { state, dispatch };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  const adminLoginMutation = useMutation(adminLogin);
  const checkAdminTokenMutation = useMutation(checkAdminToken);
  const adminLogoutMutation = useMutation(adminLogout);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  const { state, dispatch } = context;

  const loginSetter = (payload) => {
    const { token, user } = payload;
    window?.localStorage?.setItem("token", token);
    window?.localStorage?.setItem("user", JSON.stringify(user));
    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    return dispatch({ type: "login", payload });
  };

  const logoutSetter = () => {
    window?.localStorage?.removeItem("token");
    window?.localStorage?.removeItem("user");
    return dispatch({ type: "logout" });
  };

  const makeAdminLoginRequest = ({
    payload,
    onSuccess,
    onError,
    onSettled,
  }) => {
    adminLoginMutation.mutate(payload, {
      onSuccess: (response) => {
        const { token, user } = response;
        loginSetter({ token, user });
        return onSuccess(response);
      },
      onError: (error) => {
        logoutSetter();
        return onError(error?.response?.data);
      },
      onSettled: () => {
        if (onSettled) {
          return onSettled();
        }
      },
    });
  };

  const makeAdminLogoutRequest = ({ onSuccess, onError }) => {
    adminLogoutMutation.mutate(null, {
      onSuccess: () => {
        logoutSetter();
        return onSuccess();
      },
      onError: (error) => {
        return onError(error?.response?.data);
      },
    });
  };

  const makeCheckAdminTokenRequest = ({ payload, onSuccess, onError }) => {
    checkAdminTokenMutation.mutate(payload, {
      onSuccess: (response) => {
        const { token, user } = response;
        loginSetter({ token, user });
        return onSuccess(response);
      },
      onError: (error) => {
        logoutSetter();
        return onError(error);
      },
    });
  };

  return {
    state,
    loginSetter,
    logoutSetter,
    isLoading: adminLoginMutation.isLoading,
    makeAdminLoginRequest,
    makeCheckAdminTokenRequest,
    makeAdminLogoutRequest,
    logoutLoading: adminLogoutMutation.isLoading,
  };
}
