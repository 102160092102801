import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./style.css";

const CheckedIcon = () => <></>;
const UncheckedIcon = () => <></>;

export function ToggleButton(props) {
  const { value, onChange, disabled, className } = props;

  const triggerToggle = () => {
    if (disabled) {
      return;
    }
    if (typeof onChange === "function") {
      onChange(!value);
    }
  };

  const getIcon = (type) => {
    const {
      icons = {
        checked: <CheckedIcon />,
        unchecked: <UncheckedIcon />,
      },
    } = props;

    return icons[type];
  };

  const toggleClasses = classNames(
    "wrg-toggle",
    {
      "wrg-toggle--checked": value,
      "wrg-toggle--disabled": disabled,
    },
    className
  );

  return (
    <div onClick={triggerToggle} className={toggleClasses}>
      <div className="wrg-toggle-container">
        <div className="wrg-toggle-check">
          <span>{getIcon("checked")}</span>
        </div>
        <div className="wrg-toggle-uncheck">
          <span>{getIcon("unchecked")}</span>
        </div>
      </div>
      <div className="wrg-toggle-circle"></div>
      <input
        type="checkbox"
        aria-label="Toggle Button"
        className="wrg-toggle-input"
      />
    </div>
  );
}
