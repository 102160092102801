import {
  Card,
  ChoiceList,
  FormLayout,
  Layout,
  Select,
  TextField,
} from "@shopify/polaris";
import { queryAccountingCategories } from "api";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";

export function TypeSection({ isDetail }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  const { data = [] } = useQuery("accountingCategories", () =>
    queryAccountingCategories({ isDeleted: false })
  );
  return (
    <Layout.AnnotatedSection title="Diğer">
      <Card sectioned>
        <FormLayout>
          <ChoiceList
            title="Tür"
            choices={[
              { label: "Masraf", value: "Masraf" },
              { label: "Tahsilat", value: "Tahsilat" },
            ]}
            selected={values.type}
            onChange={(value) => {
              setFieldValue("category", "");
              setFieldValue("type", value[0] || value);
            }}
          />
          <Select
            label="Kategori"
            requiredIndicator
            options={[
              { label: "Bir kategori seçiniz", value: "" },
              ...data
                .filter((_data) => _data.type === values.type)
                .map((_data) => ({ label: _data.title, value: _data._id })),
            ]}
            onChange={(value) => setFieldValue("category", value)}
            value={values.category}
          />
          <TextField
            type="date"
            value={values.date}
            label="Tarih"
            requiredIndicator
            onChange={(value) => setFieldValue("date", value)}
          />
          <TextField
            type="number"
            inputMode="numeric"
            value={String(values.amount)}
            requiredIndicator
            label="Tutar"
            onChange={(value) => setFieldValue("amount", value)}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
