import { useCallback } from "react";
import { useState } from "react";

export function useActions(
  _filters = {},
  _sort = "createdAt",
  _query = "",
  _appliedFilters = []
) {
  const [filters, setFilters] = useState(_filters);
  const [sort, setSort] = useState(_sort);
  const [query, setQuery] = useState(_query);

  const appliedFilters = [..._appliedFilters];

  const handleChangeFilter = useCallback((key, value) => {
    const copyFilters = { ...filters };
    copyFilters[key] = value;

    setFilters(copyFilters);
  }, []);

  const handleChangeQuery = useCallback((value) => {
    setQuery(value);
  }, []);

  const handleChangeSort = useCallback((value) => {
    setSort(value);
  }, []);

  const handleFilterClear = useCallback((key) => {
    const copyFilters = { ...filters };
    copyFilters[key] = "";
    setFilters(copyFilters);
  }, []);

  const handleQueryClear = useCallback(() => {
    setQuery("");
  }, []);

  const handleClearAll = useCallback(() => {
    handleFilterClear();
    handleQueryClear();
  }, [handleQueryClear, handleFilterClear]);

  Object.keys(_filters).forEach((filterKey) => {
    if (!isEmpty(filters[filterKey])) {
      appliedFilters.push({
        key: filterKey,
        label: `${filterKey}: ${filters[filterKey]}`,
        onRemove: () => handleFilterClear(filterKey),
      });
    }
  });

  return {
    handleClearAll,
    handleFilterClear,
    handleQueryClear,
    filters,
    sort,
    query,
    appliedFilters,
    handleChangeFilter,
    handleChangeSort,
    handleChangeQuery,
  };
}

function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}
