import { useContext } from "react";

import { ToastContext } from "contexts";

export function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  const { dismissToast, messages, addMessage } = context;

  return { dismissToast, messages, addMessage };
}
