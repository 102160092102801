import { axios } from "utils";

export const getAllUsers = () =>
  axios.get(`/users`).then((res) => res.data?.docs);

export const queryUsers = (payload) =>
  axios.post(`/users/query`, payload).then((res) => res.data?.docs);

export const getOneUser = (userId) =>
  axios.get(`/users/${userId}`).then((res) => res.data?.doc);

export const createOneUser = (payload) =>
  axios.post("/users", payload).then((res) => res.data?.doc);

export const updateOneUser = (payload, userId) =>
  axios.patch(`/users/${userId}`, payload).then((res) => res.data?.doc);

export const deleteManyUsers = (payload) =>
  axios.post(`/users/deleteMany`, payload).then((res) => res.data?.docs);
