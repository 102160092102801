import { useState } from "react";
import { useDebounce } from "./useDebounce";

const isObject = (value) =>
  typeof value === "object" && !!value && !Array.isArray(value);

/**
 *
 * @param {{appliedFilters: object, page:number,limit:number }} options
 */
export function useQueryParams({
  appliedFilters = {},
  page: appliedPage = 0,
  limit: appliedLimit = 25,
  sort: appliedSort = "",
  debounceTime = 200,
}) {
  const [queryParams, setQueryParams] = useState({
    filters: appliedFilters,
    page: appliedPage,
    limit: appliedLimit,
    sort: appliedSort,
  });

  const debouncedQueryParams = useDebounce(
    JSON.stringify(queryParams),
    debounceTime
  );
  const parsedDebouncedQueryParams = JSON.parse(debouncedQueryParams);

  const convertQueryString = (paramsObject, acceptEmptyValue = false) => {
    if (isObject(paramsObject)) {
      return Object.keys(paramsObject)
        .filter((key) => (acceptEmptyValue ? true : !!paramsObject[key]))
        .map((key) => `${key}=${paramsObject[key]}`)
        .join("&");
    }
    return "";
  };

  const parseQuery = (str) => {
    const query = {};
    str.split("&").forEach((part) => {
      const [key, value] = part.split("=");
      query[key] = value;
    });
    return query;
  };

  const setQueryParam = (key, value) => {
    const keys = ["filters", "page", "limit", "sort"].join("-");
    const copyQueryParams = JSON.parse(JSON.stringify(queryParams));
    if (keys.includes(key)) {
      copyQueryParams[key] = value;
    } else {
      copyQueryParams.filters
        ? (copyQueryParams.filters[key] = value)
        : (copyQueryParams.filters = { [key]: value });

      // if filter value changed, reset page to 0
      copyQueryParams.page = 0;
    }
    setQueryParams(copyQueryParams);
  };

  const handleChangeQueryParam = (value) => {
    const copyQueryParams = JSON.parse(JSON.stringify(queryParams));
    setQueryParams({ ...copyQueryParams, ...value });
  };

  return {
    setQueryParam,
    setQueryParams: handleChangeQueryParam,
    queryParams,
    debouncedQueryParams: parsedDebouncedQueryParams,
    queryString: convertQueryString({
      page: parsedDebouncedQueryParams?.page,
      limit: parsedDebouncedQueryParams?.limit,
      sort: parsedDebouncedQueryParams?.sort,
      ...parsedDebouncedQueryParams?.filters,
    }),
    convertQueryString,
    parseQuery,
  };
}
