import {
  Avatar,
  Badge,
  Button,
  IndexTable,
  TextStyle,
} from "@shopify/polaris";
import { Link } from "components";
import { useFormikContext } from "formik";

const statusMapper = {
  Aktif: "success",
  Pasif: "critical",
};

export function CustomerRows({ data = [] }) {
  const { values, setFieldValue } = useFormikContext()

  const selectedCustomers = Array.isArray(values.customers) ? values.customers : []

  const selectOrDeselect = (id, name, phone) => {
    const copySelectedCustomers = [...selectedCustomers];

    const findIndex = copySelectedCustomers?.findIndex(c => c?.customerId === id)

    if (findIndex > -1) {
      copySelectedCustomers.splice(findIndex, 1);
    } else {
      copySelectedCustomers.push({
        customerId: id,
        name,
        phone
      })
    }

    setFieldValue("customers", copySelectedCustomers)
  }

  return data.map(
    (
      {
        _id,
        name,
        memberNo,
        email,
        statistics,
        memberType,
        status,
        totalReservations,
        totalCreditsValue,
        tel,
        lastReservationDate,
      },
      index
    ) => {
      const isSelected = selectedCustomers?.find(c => c?.customerId === _id)
      return (
        <IndexTable.Row id={_id} key={_id} position={index} subdued>
          <IndexTable.Cell>{memberNo || "-"}</IndexTable.Cell>

          <IndexTable.Cell>
            <div className="py-5 flex items-center">
              <div>
                <Avatar customer name={name} />
              </div>
              <div className="pl-2 flex flex-col">
                <Link url={`/customers/${_id}`}>
                  <TextStyle variation="strong">{name}</TextStyle>
                </Link>
                <TextStyle variation="subdued">{email}</TextStyle>
              </div>
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <TextStyle variation="subdued">{tel}</TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>{memberType || "-"}</IndexTable.Cell>
          <IndexTable.Cell>
            <Badge status={statusMapper?.[status] || "new"}>{status}</Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {lastReservationDate
              ? new Date(lastReservationDate)?.toLocaleDateString?.()
              : "-"}
          </IndexTable.Cell>
          <IndexTable.Cell>{totalReservations}</IndexTable.Cell>

          <IndexTable.Cell>{statistics?.totalCredits}</IndexTable.Cell>
          <IndexTable.Cell>{totalCreditsValue}</IndexTable.Cell>
          <IndexTable.Cell>
            <Button size="slim" onClick={() => selectOrDeselect(_id, name, tel)}>
              {isSelected ? "Çıkar" : "Seç"}
            </Button>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    }
  );
}
