import { Modal, TextStyle, DescriptionList } from "@shopify/polaris";
import { useFormikContext } from "formik";
import { array } from "utils";
import { seansLabelMapper } from ".";

export function ReservationConfirmModal(props) {
  const { values, setFieldValue, handleSubmit, isSubmitting } =
    useFormikContext();

  const onClose = () => {
    setFieldValue("isOpenConfirmModal", false);
  };

  const selectedReservationType = array(values?.reservationTypes).find(
    (item) => item?._id === values?.reservationTypeId
  );

  const seansValueMapper = {
    Saat: `${values?.from} - ${values?.to}`,
    Adet: values?.seansCount,
  };
  return (
    <Modal
      open={values?.isOpenConfirmModal}
      onClose={onClose}
      title="Rezervasyon Onayı"
      primaryAction={{
        content: "Onayla",
        onAction: () => handleSubmit(),
        loading: isSubmitting,
      }}
      secondaryActions={[
        {
          content: "İptal",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <DescriptionList
          items={[
            {
              term: "Tarih",
              description: new Date(values?.date).toLocaleDateString("tr-TR"),
            },
            {
              term: "Rezervasyon Türü",
              description: selectedReservationType?.title,
            },
            {
              term:
                seansLabelMapper[selectedReservationType?.selectType] ||
                "Rezervasyon Saati",
              description:
                seansValueMapper[selectedReservationType?.selectType],
            },
            {
              term: "Kredi Değeri",
              description: values?.seansCount * 1 + " adet",
            },
            {
              term: "Üye",
              description: (
                <div>
                  <div>{values?.customer?.name}</div>
                  <TextStyle variation="subdued">
                    <div>Email: {values?.customer?.email}</div>
                    <div>Tel: {values?.customer?.tel}</div>
                  </TextStyle>
                </div>
              ),
            },
            {
              term: "Eğitmen",
              description: values?.educator?.name,
            },
          ]}
        />
      </Modal.Section>
    </Modal>
  );
}
