import {
  Layout,
  Page,
  Card,
  Heading,
  TextStyle,
  Spinner,
  Banner,
  Avatar,
  FormLayout,
  Select,
  Tag,
  Button,
  Badge,
} from "@shopify/polaris";
import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ToggleButton, SkeletonPage } from "components";
import {
  queryHorseHours,
  upsertHorseHours,
  getOneHorse,
  getAllHorses,
  upsertHorseTime,
} from "api";
import { useToast } from "hooks";
import { times } from "@constants";

export function HorseAvailability(props) {
  const history = useHistory();
  const [currentLoadingTime, setCurrentTime] = useState({
    day: 0,
    from: "0",
    to: "0",
    horse: "",
  });
  const [loadingCurrent, setLoadingCurrent] = useState(null);
  const skipToContentRef = useRef(null);
  const { horseId } = useParams();
  const { addMessage } = useToast();
  const horsesQuery = useQuery(["horses"], () => getAllHorses());

  const horsesData = horsesQuery?.data || [];

  const horseAvailabilitiesQuery = useQuery(
    ["horseAvailibilities", horseId],
    () => queryHorseHours({ horse: horseId })
  );
  const upsertMutation = useMutation(upsertHorseHours);

  const upsertTimeMutation = useMutation(upsertHorseTime);

  const queryClient = useQueryClient();

  const weeks = [
    { title: "Pazartesi", value: 1 },
    { title: "Salı", value: 2 },
    { title: "Çarşamba", value: 3 },
    { title: "Perşembe", value: 4 },
    { title: "Cuma", value: 5 },
    { title: "Cumartesi", value: 6 },
    { title: "Pazar", value: 0 },
  ];

  const availibilities = horseAvailabilitiesQuery?.data || [];

  const upsertHandler = (isAvailable, day) => {
    setLoadingCurrent(day);
    upsertMutation.mutate(
      { day, isAvailable, horse: horseId },
      {
        onSuccess: (response) => {
          console.log("Upsert Mutation Response ==>", response);
          addMessage({ content: "Başarılı" });
          queryClient.setQueryData(
            ["horseAvailibilities", horseId],
            (prevData = []) => {
              const copyPrev = [...prevData];
              const index = prevData?.findIndex(
                (p) => p?._id === response?._id
              );
              if (index > -1) {
                copyPrev[index] = response;
                return copyPrev;
              }
              copyPrev.push(response);
              return copyPrev;
            }
          );
        },
        onError: (error) => {
          const errorMessage =
            error?.response?.data?.message ||
            error?.message ||
            "Bir hata oluştu";
          addMessage({ content: errorMessage, duration: 1000, error: true });
        },
        onSettled: () => {},
      }
    );
  };

  const upsertTimeHandler = (payload) => {
    setCurrentTime(payload);
    upsertTimeMutation.mutate(payload, {
      onSuccess: (response) => {
        queryClient.setQueryData(["horseAvailibilities", horseId], (prev) =>
          prev.map((p) => (p?._id === response?._id ? response : p))
        );
      },
      onError: (error) => {},
      onSettled: () => {
        setCurrentTime({ day: 0, from: "0", to: "0", horse: "" });
      },
    });
  };

  if (horsesQuery?.isLoading || horseAvailabilitiesQuery?.isFetching) {
    return <SkeletonPage narrowWidth />;
  }
  if (horsesQuery?.isError || horseAvailabilitiesQuery?.isError) {
    return (
      <Page
        title="At Saatleri"
        narrowWidth={true}
        breadcrumbs={[{ content: "At Detay", url: `/horses/${horseId}` }]}
      >
        <Layout sectioned>
          <Layout.Section fullWidth>
            <Banner
              status="critical"
              title={
                horsesQuery?.error?.response?.data?.message ||
                horsesQuery?.error?.message ||
                horseAvailabilitiesQuery?.error?.response?.data?.message ||
                horseAvailabilitiesQuery?.error?.message ||
                "Bir hata oluştu"
              }
            ></Banner>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <Page
      title="At Saatleri"
      narrowWidth={true}
      breadcrumbs={[{ content: "At Detay", url: `/horses/${horseId}` }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <Layout.Section fullWidth>
          <Card sectioned>
            <FormLayout>
              <Select
                label="At Seçiniz"
                value={horseId}
                onChange={(eId) => {
                  history.push(`/horses/${eId}/availability`);
                }}
                options={horsesData?.map((e) => ({
                  label: e?.name,
                  value: e?._id,
                }))}
              />
            </FormLayout>
          </Card>
        </Layout.Section>
        {weeks.map((week, index) => (
          <Layout.Section fullWidth key={index}>
            <Card sectioned>
              <div className="flex items-center justify-between">
                <Heading>{week?.title}</Heading>
                <div className="flex items-center gap-2">
                  {upsertMutation.isLoading &&
                    week?.value === loadingCurrent && <Spinner size="small" />}
                  {week.value === 1 ? (
                    <TextStyle variation="subdued">
                      Biraz Dinlenelim 😊
                    </TextStyle>
                  ) : (
                    <ToggleButton
                      disabled={upsertMutation?.isLoading}
                      value={availibilities?.some(
                        (a) => a?.day === week?.value && a?.isAvailable
                      )}
                      onChange={(value) => upsertHandler(value, week?.value)}
                    />
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-10">
                {availibilities?.some(
                  (a) => a?.day === week?.value && a?.isAvailable
                ) &&
                  times.map((t, tIndex) => (
                    <Button
                      key={tIndex}
                      loading={
                        upsertTimeMutation.isLoading &&
                        currentLoadingTime?.day === week.value &&
                        currentLoadingTime?.from === t.from &&
                        currentLoadingTime?.to === t?.to
                      }
                      onClick={() =>
                        upsertTimeHandler({
                          ...t,
                          day: week.value,
                          horse: horseId,
                        })
                      }
                    >
                      <span className="flex flex-col items-center gap-2">
                        <TextStyle>
                          {t?.from}-{t?.to}
                        </TextStyle>
                        {availibilities?.some(
                          (a) =>
                            a?.day === week?.value &&
                            a?.isAvailable &&
                            a?.availableTimes?.some(
                              (av) => av?.from === t?.from && av?.to === t?.to
                            )
                        ) ? (
                          <Badge status="success">Aktif</Badge>
                        ) : (
                          <Badge>Uygun Değil</Badge>
                        )}
                      </span>
                    </Button>
                  ))}
              </div>
            </Card>
          </Layout.Section>
        ))}
      </Layout>
    </Page>
  );
}
