import { Card, DescriptionList, Layout } from "@shopify/polaris";
import { useFormikContext } from "formik";
import { array } from "utils";
export function CreditsSection() {
  const { values } = useFormikContext();

  const credits = array(values?.credits);

  return (
    <Layout.AnnotatedSection
      title="Kalan Kredi Bilgileri"
      description="Üyenin kalan kredi bilgisi burada görünür."
    >
      <Card sectioned>
        <DescriptionList
          items={credits
            .map((credit) => ({
              term: credit?.reservationType?.title,
              description: `${credit?.count} kredi`,
            }))
            .concat([
              {
                term: "Toplam Kredi",
                description: `${values?.statistics?.totalCredits} kredi`,
              },
            ])}
        />
      </Card>
    </Layout.AnnotatedSection>
  );
}
