import { axios } from "utils";

export const getAllAccounting = (query = "") =>
  axios.get(`/accounting` + query).then((res) => res.data?.docs);

export const queryAccounting = (payload) =>
  axios.post(`/accounting/query`, payload).then((res) => res.data?.docs);

export const getOneAccounting = (accountingId) =>
  axios.get(`/accounting/${accountingId}`).then((res) => res.data?.doc);

export const createOneAccounting = (payload) =>
  axios.post("/accounting", payload).then((res) => res.data?.doc);

export const updateOneAccounting = (payload, accountingId) =>
  axios
    .patch(`/accounting/${accountingId}`, payload)
    .then((res) => res.data?.doc);

export const deleteManyAccounting = (payload) =>
  axios.post(`/accounting/deleteMany`, payload).then((res) => res.data?.docs);

export const deleteOneAccounting = (_id) =>
  axios.delete(`/accounting/${_id}`).then((res) => res.data?.doc);
