import { useState, useCallback, useEffect } from "react";
import {
  IndexTable,
  Page,
  TextField,
  Filters,
  Select,
  useIndexResourceState,
  Card,
  Modal,
} from "@shopify/polaris";
import { useHistory } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useActions, useDebounce } from "hooks";
import { AccountingRows } from "./AccountingRows";
import { getAllAccounting, deleteOneAccounting } from "api";

export function AccountingList(props) {
  const history = useHistory();

  const initialData = { isActive: false, type: "", data: { _id: "" } };
  const [current, setCurrent] = useState(initialData);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery("accountings", () =>
    getAllAccounting("?isDeleted=false")
  );
  const deleteMutation = useMutation(deleteOneAccounting);

  const resourceName = {
    singular: "muhasebe kaydı",
    plural: "muhasebe kayıtları",
  };

  const accounting = data || [];

  const openDeleteModalHandler = (_id) => {
    setCurrent({ isActive: true, type: "delete", data: { _id } });
  };

  const closeDeleteModalHandler = () => {
    setCurrent(initialData);
  };

  const deleteAccountingHandler = () => {
    deleteMutation.mutate(current?.data?._id, {
      onSuccess: (response) => {
        if (response?._id) {
          queryClient.setQueryData("accountings", (oldData) =>
            oldData.filter((o) => o._id !== response?._id)
          );
          closeDeleteModalHandler();
          alert("Başarıyla silindi");
        }
      },
      onError: (error) => {
        console.log(error.response, "accounting error");
        alert(`Hata oluştu ${error?.response?.data?.message}`);
      },
    });
  };

  return (
    <Page
      title="Muhasebe"
      primaryAction={{
        content: "Yeni Kayıt",
        onAction: () => history.push("/accounting/new"),
      }}
      secondaryActions={[
        {
          content: "Kategoriler",
          onAction: () => history.push("/accounting/categories"),
        },
      ]}
    >
      <Modal
        open={current?.type === "delete" && current?.isActive}
        title="Sil"
        onClose={closeDeleteModalHandler}
        primaryAction={{
          content: "Sil",
          destructive: true,
          loading: deleteMutation.isLoading,
          onAction: deleteAccountingHandler,
        }}
      >
        <Modal.Section>
          İlgili muhasebe kaydı silinecektir. Onaylıyor musunuz?
        </Modal.Section>
      </Modal>
      <Card>
        <IndexTable
          selectable={false}
          loading={isLoading}
          lastColumnSticky
          resourceName={resourceName}
          itemCount={accounting.length}
          headings={[
            { title: "Başlık" },
            { title: "Tarih" },
            { title: "Açıklama" },
            { title: "Tutar" },
            { title: "Tür" },
            { title: "Kategori" },
            { title: "" },
          ]}
        >
          <AccountingRows
            data={accounting}
            onDeleteClick={openDeleteModalHandler}
          />
        </IndexTable>
      </Card>
    </Page>
  );
}
