import { ContextualSaveBar, Layout, Page } from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { getOneHorse, updateOneHorse } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm";

export function HorseDetail(props) {
  const skipToContentRef = useRef(null);
  const { horseId } = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState(null);

  const { toggleSnack } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getOneHorseOnsuccess = (response) => {
    const payload = {
      name: response?.name,
      avatar: undefined,
      status: response?.status,
    };
    setInitialValues(payload);
  };

  useQuery(["horses", horseId], () => getOneHorse(horseId), {
    onSuccess: getOneHorseOnsuccess,
  });

  const patchMutation = useMutation(
    (payload) => updateOneHorse(payload, horseId),
    {
      onSuccess: (response) => {
        toggleSnack({
          key: "horseUpdateuccess",
          message: "At kaydı başarıyla güncellenmiştir",
          active: true,
        });
      },
      onError: (error) => {
        toggleSnack({
          key: "horseUpdaterror",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    }
  );

  return (
    <Page
      title="At Detay"
      narrowWidth={true}
      breadcrumbs={[{ content: "Atlar", url: "/horses" }]}
      primaryAction={{
        content: "At Saatleri",
        onAction: () => history.push(`/horses/${horseId}/availability`),
      }}
      secondaryActions={[
        {
          content: "İzin günleri",
          onAction: () => history.push(`/horses/${horseId}/unavailability`),
        },
      ]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {initialValues && (
          <SharedForm
            initialValues={initialValues}
            isDetail={true}
            onSubmit={(values) => patchMutation.mutate(values)}
            savebarRender={(values) => (
              <Savebar {...values} isLoading={patchMutation.isLoading} />
            )}
          />
        )}
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Güncelle",
      }}
      message="At Detay"
      discardAction={{
        onAction: () => history.push("/horses"),
        content: "Geri",
      }}
    />
  );
}
