import {
  Page,
  Layout,
  Spinner,
  DescriptionList,
  TextStyle,
} from "@shopify/polaris";
import { getAllStatistics } from "api";
import { useQuery } from "react-query";
import { array } from "utils";
import "./style.css";

const customerStatusNameMapper = {
  Aktif: "Aktif durumdaki üyeler:",
  Pasif: "Pasif durumdaki üyeler:",
  Freeze: "Hesabını kapatan üyeler:",
};

function Card({ title, children }) {
  return (
    <div
      className="bg-white shadow border p-8 rounded-md"
      style={{ maxWidth: "100%", width: 500 }}
    >
      <div className="text-3xl font-medium">{title}</div>
      <div className="pt-14 w-full">{children}</div>
    </div>
  );
}

export function AdminHome(props) {
  const { data, isLoading } = useQuery("totalStatistics", getAllStatistics);
  const {
    customers,
    payments,
    reservations,
    customerCredits,
    customerEmptyCredits,
    customerTypes,
  } = data || {};

  const payment = array(payments)?.[0] || {};

  return (
    <Page title="Ana sayfa" fullWidth>
      <Layout>
        {isLoading ? (
          <Spinner />
        ) : (
          <Layout.Section fullWidth>
            <div className="flex flex-wrap justify-center text-center gap-10 home-reports">
              <Card title="Ödemeler">
                <DescriptionList
                  items={[
                    {
                      term: "Toplam yapılan ödeme:",
                      description: new Intl.NumberFormat("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                      }).format(payment?.amount),
                    },
                    {
                      term: "Toplam verilen kredi adeti:",
                      description: payment?.creditValue,
                    },
                  ]}
                />
              </Card>
              <Card title="Rezervasyonlar">
                <DescriptionList
                  items={[
                    {
                      term: "Toplam rezervasyon:",
                      description: reservations?.reduce(
                        (a, b) => a + b?.status,
                        0
                      ),
                    },
                    ...reservations?.map((reservation) => ({
                      term: reservation?._id + " olan rezervasyonlar:",
                      description: reservation?.status,
                    })),
                  ]}
                />
              </Card>
              <Card title="Üyeler">
                <div className="space-y-12">
                  <div>
                    <h3 className="text-left">
                      <TextStyle variation="subdued">
                        Üyelerin Genel Durumu
                      </TextStyle>
                    </h3>
                    <DescriptionList
                      items={[
                        {
                          term: "Toplam üye:",
                          description: customers?.reduce(
                            (a, b) => a + b?.status,
                            0
                          ),
                        },
                        ...customers?.map((customer) => ({
                          term: customerStatusNameMapper[customer?._id],
                          description: customer?.status,
                        })),
                      ]}
                    />
                  </div>
                  <div>
                    <h3 className="text-left">
                      <TextStyle variation="subdued">
                        Aktif Üyelerin Durumu
                      </TextStyle>
                    </h3>
                    <DescriptionList
                      items={[
                        ...customerTypes?.map((customerType) => ({
                          term:
                            customerType?._id || "Üyelik Tanımı Girilmeyenler",
                          description: customerType?.status,
                        })),
                        {
                          term: "Kredisi olan üyeler",
                          description: customerCredits?.[0]?.status,
                        },
                        {
                          term: "Kredisi biten üyeler",
                          description: customerEmptyCredits?.[0]?.status,
                        },
                      ]}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </Layout.Section>
        )}
      </Layout>
    </Page>
  );
}
