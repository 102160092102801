import { axios } from "utils";

export const getAllPackageRequests = (query = "") =>
  axios.get(`/packageRequests` + query).then((res) => res.data?.docs);

export const cancelPackageRequest = (id) =>
  axios.patch(`/packageRequests/${id}/cancel/byAdmin`).then((res) => res?.data);

export const acceptPackageRequest = (id) =>
  axios.patch(`/packageRequests/${id}/accept`).then((res) => res?.data);
