import { ContextualSaveBar, Layout, Page } from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { getOnePackage, updateOnePackage } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm";
import { array } from "utils";

export default function RecordDetail(props) {
  const skipToContentRef = useRef(null);
  const { reservationPackageId } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { toggleSnack } = useSnackbar();

  const queryOnsuccess = (response) => {
    const payload = {
      _id: response?._id || "",
      title: response?.title || "",
      isDetail: true,
      description: response?.description || "",
      active: response?.active || false,
      totalPrice: response?.totalPrice || 0,
      definitions: array(response?.definitions).map((def) => ({
        _id: def?._id || "",
        reservationType: def?.reservationType || "",
        price: def?.price?.toString() || "",
        creditCount: def?.creditCount?.toString() || "",
      })),
    };
    setInitialValues(payload);
  };

  useQuery(
    ["packages", reservationPackageId],
    () => getOnePackage(reservationPackageId),
    {
      onSuccess: queryOnsuccess,
    }
  );

  const patchMutation = useMutation(
    (payload) => updateOnePackage(payload, reservationPackageId),
    {
      onSuccess: (response) => {
        toggleSnack({
          key: "reservationPackagesUpdateuccess",
          message: "Paket kaydı başarıyla güncellenmiştir",
          active: true,
        });
      },
      onError: (error) => {
        toggleSnack({
          key: "reservationPackagesUpdaterror",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    }
  );

  return (
    <Page
      title="Kayıt Detay"
      narrowWidth={true}
      breadcrumbs={[{ content: "Paket", url: "/reservationPackages" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {initialValues && (
          <SharedForm
            initialValues={initialValues}
            isDetail={true}
            onSubmit={(values) => patchMutation.mutate(values)}
            savebarRender={(values) => (
              <Savebar {...values} isLoading={patchMutation.isLoading} />
            )}
          />
        )}
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        content: "Güncelle",
      }}
      message="Kayıt Detay"
      discardAction={{
        onAction: () => history.push("/reservationPackages"),
        content: "Geri",
      }}
    />
  );
}
