import {
  Autocomplete,
  Avatar,
  Button,
  Icon,
  Label,
  TextStyle,
} from "@shopify/polaris";
import { CircleCancelMinor, SearchMinor } from "@shopify/polaris-icons";

import { Link } from "components";
import { useSearchCustomers } from "hooks";

export function AutocompleteCustomer(props) {
  const {
    changeQueryValue,
    queryValue,
    options,
    selectedOptions,
    isLoading,
    customers,
  } = useSearchCustomers();

  const textField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={changeQueryValue}
      label="Üye seç"
      value={queryValue || ""}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="İsim, email veya telefon numarasına göre arama yapın"
    />
  );
  return !props.value ? (
    <Autocomplete
      textField={textField}
      options={options}
      selected={selectedOptions}
      loading={isLoading}
      preferredPosition="mostSpace"
      onSelect={(value) => {
        props?.onSelect(
          customers?.find((customer) => customer?._id === value?.[0])
        );
      }}
    />
  ) : (
    <>
      <Label>Üye</Label>
      <div className="py-5 flex items-start relative border rounded-md px-4 mt-4">
        <div>
          <Avatar customer name={props?.value?.name} />
        </div>
        <div className="pl-2 ml-2">
          <div>
            <Link url={`/customers/${props?.value?._id}`} target="_blank">
              <TextStyle variation="strong">İsim: </TextStyle>
              <TextStyle>{props?.value?.name}</TextStyle>
            </Link>
          </div>
          <div>
            <TextStyle variation="strong">Email: </TextStyle>
            <TextStyle>{props?.value?.email}</TextStyle>
          </div>
          <div>
            <TextStyle variation="strong">Telefon: </TextStyle>
            <TextStyle>{props?.value?.tel}</TextStyle>
          </div>
        </div>
        {props?.clearable && (
          <div className="absolute right-2 top-2">
            <Button
              plain
              monochrome
              onClick={() => props?.onSelect("")}
              icon={<Icon source={CircleCancelMinor} />}
            />
          </div>
        )}
      </div>
    </>
  );
}
