import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function InfoSection({ isDetail }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();
  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="Eğitmenin temel bilgilerini eksiksiz ve doğru girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            label="İsim Soyisim"
            name="name"
            value={values.name}
            onChange={(value) => setFieldValue("name", value)}
            onBlur={handleBlur}
          />
          <TextField
            type="email"
            label="Email"
            name="email"
            value={values.email}
            onChange={(value) => setFieldValue("email", value)}
            onBlur={handleBlur}
          />

          <TextField
            label="Telefon"
            name="tel"
            type="tel"
            maxLength={10}
            value={values.tel}
            onChange={(value) => setFieldValue("tel", value)}
            onBlur={handleBlur}
          />
          {!isDetail && (
            <TextField
              type="type"
              label="Şifre"
              helpText="Eğitmen sisteme ilk kez giriş yaptığında şifresini yenilemesi gerekmektedir."
              disabled={true}
              name="password"
              value={values.password}
              onChange={(value) => setFieldValue("password", value)}
              onBlur={handleBlur}
            />
          )}
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
