import { Caption, DropZone, Icon, Stack, Thumbnail } from "@shopify/polaris";
import { CancelSmallMinor, NoteMinor } from "@shopify/polaris-icons";
import { useCallback } from "react";

export function SingleFileUpload({
  file,
  type = "image",
  accept = "image/*",
  validImageTypes = ["image/gif", "image/jpeg", "image/png"],
  setFile,
}) {
  const handleDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => setFile(acceptedFiles[0]),
    []
  );

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <div className="flex items-center justify-center h-full">
      <div>
        <Stack alignment="center">
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name} <Caption>{file.size} bytes</Caption>
          </div>
        </Stack>
      </div>
      <div
        className="pl-5"
        onClick={(e) => {
          e.stopPropagation();
          setFile(null);
        }}
      >
        <Icon source={CancelSmallMinor} />
      </div>
    </div>
  );
  return (
    <DropZone
      accept={accept}
      type={type}
      allowMultiple={false}
      onDrop={handleDrop}
    >
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );
}
