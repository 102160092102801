import { axios } from "utils";

export const getAllHorses = () =>
  axios.get(`/horses`).then((res) => res.data?.docs);

export const queryHorses = (payload) =>
  axios.post(`/horses/query`, payload).then((res) => res.data?.docs);

export const getOneHorse = (horseId) =>
  axios.get(`/horses/${horseId}`).then((res) => res.data?.doc);

export const createOneHorse = (payload) =>
  axios.post("/horses", payload).then((res) => res.data?.doc);

export const updateOneHorse = (payload, horseId) =>
  axios.patch(`/horses/${horseId}`, payload).then((res) => res.data?.doc);

export const deleteManyHorses = (payload) =>
  axios.post(`/horses/deleteMany`, payload).then((res) => res.data?.docs);
