import { Modal, Banner } from "@shopify/polaris";
import { exportReservations } from "api";
import { useSnackbar } from "providers";
import { useState } from "react";
import { useMutation } from "react-query";

export function ExportModal({ visible, onClose, totalData, filter }) {
  const [loading, setLoading] = useState(false);
  const exportMutation = useMutation(exportReservations);
  const { toggleSnack } = useSnackbar();

  const exportHandler = () => {
    setLoading(true);
    exportMutation.mutate(
      { query: filter, payload: {} },
      {
        onSuccess: async (res) => {
          toggleSnack({
            key: "successexportcustomeres",
            message: "Rezervasyon listesi başarıyla dışa aktarıldı",
            active: true,
          });
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `rezervasyonlar-${new Date().toISOString()}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          onClose();
          setLoading(false);
        },
        onError: (error) => {
          setLoading(false);
          alert(
            error?.response?.data?.message || error?.message || "Hata oluştu"
          );
        },
      }
    );
  };
  const title = "Excel'e Aktar";
  return (
    <Modal
      open={visible}
      title={title}
      primaryAction={{
        content: "Excel'e Aktar",
        onAction: exportHandler,
        loading,
      }}
      secondaryActions={[{ key: "iptal", content: "İptal", onAction: onClose }]}
    >
      <Modal.Section>
        <div className="space-y-10 my-5">
          <Banner
            status="warning"
            title={
              "Dışarı aktarma işlemi başladığında sayfayı yenilemeyin veya kapatmayın."
            }
          ></Banner>
          <div>
            <strong>{totalData} adet</strong> rezervasyon kaydı excel olarak
            dışarı aktarılacaktır. Bu işlem biraz zaman alabilir.
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
}
