import * as yup from "yup";

export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email alanı zorunludur")
    .email("Lütfen geçerli bir email adresi giriniz"),
});

export const resetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required("Şifre alanı zorunludur")
    .min(6, "En az 6 karakter içermelidir"),
  passwordConfirm: yup.string().required("Şifre alanı zorunludur").test("passwords-match", "Yeni şifre ile uyuşmuyor", function (value) {
    return this.parent.password === value;
  })
    .min(6, "En az 6 karakter içermelidir"),
  token: yup.string().required("Kod alanı zorunludur."),
});


export const updatePasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Şifre alanı zorunludur")
    .min(6, "En az 6 karakter içermelidir"),
  currentPassword: yup
    .string()
    .required("Şifre alanı zorunludur")
    .min(6, "En az 6 karakter içermelidir"),
  passwordConfirm: yup
    .string()
    .test("passwords-match", "Yeni şifre ile uyuşmuyor", function (value) {
      return this.parent.password === value;
    })
    .min(6, "En az 6 karakter içermelidir"),
});