import { Autocomplete, Button, Icon, Select } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { RangePicker } from "components";
import { endOfDay, startOfDay } from "date-fns";
import { useSearchCustomers } from "hooks";
import moment from "moment";
import { useRef } from "react";

export default function PaymentFilter({ filters, onChangeFilter }) {
  const hiddenInputRef = useRef();
  const {
    changeQueryValue,
    queryValue,
    options,
    selectedOptions,
    isLoading,
    customers,
    changeSelectedOptions,
  } = useSearchCustomers();

  const customersOptions = Array.isArray(options) ? options : [];

  const customersData = Array.isArray(customers) ? customers : [];

  const handleChangeFilter = (key, value) => {
    onChangeFilter((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  return (
    <>
      <input ref={hiddenInputRef} className="absolute w-0 h-0" />
      <div className="px-10 py-8 grid grid-cols-1 lg:grid-cols-2 items-center gap-6">
        <div className="col-span-2">
          <RangePicker
            startDate={
              filters?.["date[gte]"] ? moment(filters?.["date[gte]"]) : null
            }
            endDate={
              filters?.["date[lte]"] ? moment(filters?.["date[lte]"]) : null
            }
            label={
              <div className="flex items-center gap-x-5">
                <div>Tarih</div>
                {!!filters?.["date[gte]"] && (
                  <Button
                    onClick={() => {
                      onChangeFilter((prev) => ({
                        ...prev,
                        "date[gte]": null,
                        "date[lte]": null,
                        page: 1,
                      }));
                    }}
                    plain
                  >
                    Temizle
                  </Button>
                )}
              </div>
            }
            onDatesChange={({ startDate, endDate }) => {
              onChangeFilter((prev) => ({
                ...prev,
                "date[gte]": startDate
                  ? startOfDay(startDate?.toDate?.())?.toISOString?.()
                  : undefined,
                "date[lte]": endDate
                  ? endOfDay(endDate?.toDate?.())?.toISOString?.()
                  : undefined,
                page: 1,
              }));
            }}
          />
        </div>
        <Autocomplete
          textField={
            <Autocomplete.TextField
              onChange={changeQueryValue}
              label={
                <div className="flex items-center gap-x-5">
                  <div>Üye</div>
                  {!!filters?.customer && (
                    <Button
                      onClick={() => {
                        handleChangeFilter("customer", "");
                        changeQueryValue("");
                        changeSelectedOptions([]);
                      }}
                      plain
                    >
                      Temizle
                    </Button>
                  )}
                </div>
              }
              value={queryValue}
              prefix={<Icon source={SearchMinor} color="base" />}
              placeholder="İsim, email veya telefon numarasına göre arama yapın"
            />
          }
          options={customersOptions}
          selected={selectedOptions}
          loading={isLoading}
          onSelect={(value) => {
            const populatedCustomer = customersData.find(
              (customer) => customer?._id === value[0]
            );
            changeSelectedOptions(value);
            handleChangeFilter("customer", value[0]);
            changeQueryValue(populatedCustomer?.name);
            hiddenInputRef.current.focus();
          }}
        />
        <Select
          value={filters.title}
          onChange={(selected) => {
            handleChangeFilter("title", selected);
          }}
          placeholder="Ödeme tipine göre filtreleyebilirsiniz"
          label={
            <div className="flex items-center gap-x-5">
              <div>Ödeme Tipi</div>
              {!!filters?.title && (
                <Button
                  onClick={() => {
                    handleChangeFilter("title", "");
                  }}
                  plain
                >
                  Temizle
                </Button>
              )}
            </div>
          }
          options={[
            "Bakiye Yükleme",
            "Ders Ücreti",
            "Ders Ücret İadesi",
            "Paket Talebi ile Bakiye Yükleme",
          ]}
        ></Select>
      </div>
    </>
  );
}
