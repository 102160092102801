import {
  Button,
  Card,
  FormLayout,
  Layout,
  Page,
  TextField,
} from "@shopify/polaris";
import { useMutation } from "react-query";
import { Form, Formik } from "formik";

import { forgotPassword } from "api";
import { forgotPasswordValidationSchema } from "./validationSchema";

export function ForgotPassword(props) {
  const forgotPasswordMutation = useMutation(forgotPassword);

  const onSubmitHandler = (values) => {
    forgotPasswordMutation.mutate(values, {
      onSuccess: (response) => {
        console.log(response, "response");
        alert("Eposta başarıyla gönderildi");
      },
      onError: (error) => {
        console.log(error?.response?.data, "error");
        alert("Hata oluştu");
      },
    });
  };
  return (
    <Page
      title="Hara Admin Panel"
      narrowWidth
      breadcrumbs={[{ content: "Giriş yap", url: "/" }]}
    >
      <Layout>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPasswordValidationSchema}
          validateOnMount={true}
          onSubmit={onSubmitHandler}
        >
          {({ values, handleBlur, isValid, handleSubmit, setFieldValue }) => (
            <Layout.AnnotatedSection
              title="Şifre Yenile"
              description="Sistemde kayıtlı olan email adresinizi giriniz. Email adresinize şifrenizi yenilemeniz için gerekli olan link gönderilecektir."
            >
              <Form onSubmit={handleSubmit}>
                <Card sectioned>
                  <FormLayout>
                    <TextField
                      label="Email"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={(value) => setFieldValue("email", value)}
                      onBlur={handleBlur}
                    />

                    <Button
                      submit
                      primary
                      loading={forgotPasswordMutation.isLoading}
                      disabled={!isValid}
                    >
                      Gönder
                    </Button>
                  </FormLayout>
                </Card>
              </Form>
            </Layout.AnnotatedSection>
          )}
        </Formik>
      </Layout>
    </Page>
  );
}
