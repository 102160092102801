import { Select } from "@shopify/polaris";
import { getAllEducators } from "api";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { array } from "utils";

export function EducatorSection() {
  const { values, setValues, errors, touched } = useFormikContext();
  const educatorQuery = useQuery("educators", () => getAllEducators());

  const educatorData = array(educatorQuery.data).filter(
    (status) => status.status === "Aktif"
  );

  const reservationType = array(values?.reservationTypes).find(
    (type) => type?._id === values?.reservationTypeId
  );

  if (!reservationType) {
    return null;
  }

  return (
    <div className="space-y-6">
      {reservationType?.isSelectableEducator && (
        <Select
          error={touched?.educatorId && errors?.educatorId}
          value={values?.educatorId}
          onChange={(value) => {
            setValues({
              ...values,
              educatorId: value,
              educator: educatorData?.find((item) => item?._id === value),
              from: "",
              to: "",
            });
          }}
          options={[
            { key: "1", label: "", value: "" },
            ...educatorData.map((educator) => ({
              key: educator?._id,
              label: educator?.name,
              value: educator?._id,
            })),
          ]}
          label="Eğitmen"
        />
      )}
    </div>
  );
}
