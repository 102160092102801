import * as yup from "yup";

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("İsim alanı zorunludur")
    .min(4, "İsim alanı en az 4 karakterden oluşmalıdır."),

  email: yup
    .string()
    .required("Email alanı zorunludur")
    .email("Geçerli bir email adresi girin."),
  tel: yup.number().required("Telefon alanı zorunludur."),
});
