import {
  IndexTable,
  TextStyle,
  Avatar,
  Button,
  Badge,
  ButtonGroup,
} from "@shopify/polaris";
import {
  CircleCancelMinor,
  CircleTickOutlineMinor,
} from "@shopify/polaris-icons";
import { Link } from "components";
import { formatCurrency } from "utils";

export const packageRequestStatusBadgeMapper = {
  pending: "info",
  accepted: "success",
  canceled: "critical",
  error: "warning",
  paymentScreen: "attention",
};

export const packageStatusMapper = {
  pending: "Beklemede",
  paymentScreen: "Ödeme Sayfasında",
  accepted: "Kabul Edildi",
  canceled: "İptal Edildi",
  error: "Hata",
};

export default function Rows({ data = [], onCancel, onAccept }) {
  return data.map(
    ({ _id, createdAt, title, customer, totalPrice, status, paymentType }, index) => {
      const paymentData = {
        id: _id,
        customer,
        totalPrice: formatCurrency(totalPrice),
        title,
        createdAt: new Date(createdAt)?.toLocaleDateString(),
      };
      return (
        <IndexTable.Row id={_id} key={_id} position={index} subdued>
          <IndexTable.Cell>
            <Link to={`/paymentRequests/${_id}`} className="underline">
              {new Date(createdAt).toLocaleDateString()}
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div className="py-5 flex items-center">
              <div>
                <Avatar customer name={customer?.name} />
              </div>
              <div className="pl-2 flex flex-col">
                <Link url={`/customers/${customer?._id}`}>
                  <TextStyle variation="strong">{customer?.name}</TextStyle>
                </Link>
                <TextStyle variation="subdued">{customer?.email}</TextStyle>
              </div>
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <TextStyle variation="strong">
              <Badge>
                {paymentType}
              </Badge>
            </TextStyle>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Link url={`/reservationPackages/${_id}`}>
              <TextStyle variation="strong">{title}</TextStyle>
            </Link>
          </IndexTable.Cell>
          <IndexTable.Cell>{formatCurrency(totalPrice)}</IndexTable.Cell>
          <IndexTable.Cell>
            <Badge status={packageRequestStatusBadgeMapper?.[status] || "new"}>
              {packageStatusMapper?.[status] || ""}
            </Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {status === "pending" && (
              <ButtonGroup spacing="extraTight">
                <Button
                  plain
                  icon={CircleTickOutlineMinor}
                  onClick={() => onAccept(paymentData)}
                ></Button>
                <Button
                  icon={CircleCancelMinor}
                  plain
                  destructive
                  onClick={() => onCancel(paymentData)}
                ></Button>
              </ButtonGroup>
            )}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );
}
