import * as yup from "yup";

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required("İsim alanı zorunludur")
    .min(4, "İsim alanı en az 4 karakterden oluşmalıdır."),
  amount: yup.number().required("Tutar alanı zorunludur"),
  customer: yup.object().required("Müşteri alanı zorunludur"),
  date: yup.string().required("Tarih alanı zorunludur."),
});
