import { axios } from "utils";

export const getAllPackages = (query = "") =>
  axios.get(`/packages` + query).then((res) => res.data?.docs);

export const getOnePackage = (packageId) =>
  axios.get(`/packages/${packageId}`).then((res) => res.data?.doc);

export const createOnePackage = (payload) =>
  axios.post("/packages", payload).then((res) => res.data?.doc);

export const updateOnePackage = (payload, packageId) =>
  axios.patch(`/packages/${packageId}`, payload).then((res) => res.data?.doc);

export const deleteOnePackage = (_id) =>
  axios.delete(`/packages/${_id}`).then((res) => res.data?.doc);
