import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function InfoSection() {
  const { values, setFieldValue, handleBlur, errors, touched } =
    useFormikContext();

  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="SMS göndermek için gerekli bilgileri girin."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            label="Başlık"
            requiredIndicator
            name="title"
            value={values.title}
            error={touched?.title && errors?.title}
            onChange={(value) => setFieldValue("title", value)}
            onBlur={handleBlur}
          />
          <TextField
            label="Mesaj"
            multiline={4}
            requiredIndicator
            name="message"
            value={values.message}
            error={touched?.message && errors?.message}
            onChange={(value) => setFieldValue("message", value)}
            onBlur={handleBlur}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
