import { Card, ChoiceList, FormLayout, Layout } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function TypeSection({ isDetail }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Layout.AnnotatedSection title="Diğer">
      <Card sectioned>
        <FormLayout>
          <ChoiceList
            title="Tür"
            choices={[
              { label: "Masraf", value: "Masraf" },
              { label: "Tahsilat", value: "Tahsilat" },
            ]}
            selected={values.type}
            onChange={(value) => setFieldValue("type", value[0] || value)}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
