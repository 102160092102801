import { Button, ButtonGroup, IndexTable, TextStyle } from "@shopify/polaris";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";

export function AccountingRows({ data = [], onDeleteClick }) {
  return data.map(
    ({ _id, date, title, description, amount, type, category }, index) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>
          <Link url={`/accounting/${_id}`}>
            <TextStyle variation="strong">{title}</TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{new Date(date).toLocaleDateString()}</IndexTable.Cell>
        <IndexTable.Cell>{description}</IndexTable.Cell>
        <IndexTable.Cell>{amount}</IndexTable.Cell>
        <IndexTable.Cell>{type}</IndexTable.Cell>
        <IndexTable.Cell>
          <Link url={`/accounting/categories/${category?._id}`}>
            <TextStyle variation="strong">{category?.title}</TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ButtonGroup>
            <Button
              url={`/accounting/${_id}`}
              icon={EditMinor}
              plain
              monochrome
            />
            <Button
              onClick={() => onDeleteClick(_id)}
              icon={DeleteMinor}
              plain
              destructive
            />
          </ButtonGroup>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
