import { Autocomplete, Icon } from "@shopify/polaris";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

import meslekler from "@constants/meslekler.json";
import { SearchMinor } from "@shopify/polaris-icons";
import { useDebounce } from "hooks";

export function MeslekSearch() {
  const [inputValue, setInputValue] = useState("");
  const { values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext();

  useEffect(() => {
    setInputValue(values?.meslek);
  }, [values?.meslek]);

  const debouncedQuery = useDebounce(inputValue, 500);

  const updateText = (value) => {
    if (value === "") {
      setInputValue("");
      return setFieldValue("meslek", "");
    }
    setInputValue(value);
  };

  const updateSelection = (selected) => {
    setFieldValue("meslek", selected?.[0]);
  };

  const mesleklerOptions = meslekler
    .map((meslek) => ({ label: meslek, value: meslek }))
    .filter((meslek) =>
      meslek?.label?.toLowerCase().includes(debouncedQuery?.toLowerCase())
    );

  return (
    <Autocomplete
      options={mesleklerOptions}
      selected={values?.meslek ? [values?.meslek] : []}
      onSelect={updateSelection}
      textField={
        <Autocomplete.TextField
          onChange={updateText}
          label="Meslek"
          name="meslek"
          requiredIndicator={true}
          value={inputValue}
          error={errors?.meslek && touched?.meslek && errors?.meslek}
          prefix={<Icon source={SearchMinor} color="base" />}
          placeholder="Meslek adı ile arama yapabilirsiniz"
          onBlur={() => setFieldTouched("meslek", true)}
        />
      }
    />
  );
}
