import * as yup from "yup";

const isRequiredSeansTime = (value, ctx, filter) => {
  const reservationType = ctx.parent?.reservationTypes?.find(
    (type) => type?._id === ctx.parent?.reservationTypeId
  );
  return reservationType?.selectType === filter ? !!value : true;
};

export function validationSchema() {
  return yup.object().shape({
    reservationTypeId: yup.string().required("Rezervasyon tipi seçiniz."),
    customerId: yup.string().required("Bir üye seçiniz."),
    educatorId: yup
      .string()
      .nullable()
      .test(
        "isRequiredEducator",
        "Bir eğitmen seçimi yapınız.",
        (value, ctx) => {
          const reservationType = ctx.parent?.reservationTypes?.find(
            (type) => type?._id === ctx.parent?.reservationTypeId
          );
          return reservationType?.isSelectableEducator === true
            ? !!value
            : true;
        }
      ),
    hasCredit: yup
      .boolean()
      .nullable()
      .test(
        "hasCredit",
        "Seçilen müşterinin bu rezervasyon türü için yeterli kredisi bulunmamaktadır.",
        (value) => {
          return value === true;
        }
      ),
    from: yup
      .string()
      .nullable()
      .test("isRequired", "Bir rezervasyon saati seçiniz", (value, ctx) => {
        return isRequiredSeansTime(value, ctx, "Saat");
      }),
    to: yup
      .string()
      .nullable()
      .test("isRequired", "Bir rezervasyon saati seçiniz", (value, ctx) => {
        return isRequiredSeansTime(value, ctx, "Saat");
      }),
  });
}
