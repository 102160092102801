import {
  Button,
  Card,
  FormLayout,
  Layout,
  Page,
  TextField,
  Banner
} from "@shopify/polaris";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { Form, Formik } from "formik";
import { useAuth, useSnackbar } from "providers";

import { updatePassword } from "api";
import { updatePasswordValidationSchema } from "./validationSchema";

export function UpdatePassword(props) {
  const [error, setError] = useState(null);
  const updatePasswordMutation = useMutation(updatePassword);
  const { loginSetter } = useAuth();
  const history = useHistory();

  const onSubmitHandler = (values) => {
    setError(null);
    updatePasswordMutation.mutate(values, {
      onSuccess: (response) => {
        setError(null);
        alert("Şifreniz başarıyla güncellenmiştir")
        loginSetter(response);
        history.push("/");
      },
      onError: (error) => {
        setError(error?.response?.data?.message);
      },
    });
  };
  return (
    <Page
      title="Hara Admin"
      narrowWidth
      breadcrumbs={[{ content: "Giriş yap", onAction: () => history.goBack() }]}
    >
      <Layout>
        <Formik
          initialValues={{
            currentPassword: "",
            password: "",
            passwordConfirm: "",
          }}
          validationSchema={updatePasswordValidationSchema}
          validateOnMount={true}
          onSubmit={onSubmitHandler}
        >
          {({
            values,
            handleBlur,
            errors,
            touched,
            isValid,
            handleSubmit,
            setFieldValue,
          }) => (
            <Layout.AnnotatedSection title="Yeni Şifre Belirleyin">
              <Form onSubmit={handleSubmit}>
                <Card sectioned>
                  <FormLayout>
                    {
                      error && <Banner status="critical">
                        {error}
                      </Banner>
                    }
                    <TextField
                      label="Şifre"
                      type="password"
                      name="currentPassword"
                      value={values.currentPassword}
                      error={
                        errors.currentPassword &&
                        touched.currentPassword &&
                        errors.currentPassword
                      }
                      onChange={(value) =>
                        setFieldValue("currentPassword", value)
                      }
                      onBlur={handleBlur}
                    />
                    <TextField
                      label="Yeni Şifre"
                      type="password"
                      name="password"
                      value={values.password}
                      error={
                        errors.password && touched.password && errors.password
                      }
                      onChange={(value) => setFieldValue("password", value)}
                      onBlur={handleBlur}
                    />
                    <TextField
                      label="Yeni Şifre Doğrula"
                      type="password"
                      error={
                        errors.passwordConfirm &&
                        touched.passwordConfirm &&
                        errors.passwordConfirm
                      }
                      name="passwordConfirm"
                      value={values.passwordConfirm}
                      onChange={(value) =>
                        setFieldValue("passwordConfirm", value)
                      }
                      onBlur={handleBlur}
                    />
                    <Button
                      submit
                      primary
                      loading={updatePasswordMutation.isLoading}
                      disabled={!isValid}
                    >
                      Gönder
                    </Button>
                  </FormLayout>
                </Card>
              </Form>
            </Layout.AnnotatedSection>
          )}
        </Formik>
      </Layout>
    </Page>
  );
}
