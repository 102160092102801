import { useQuery } from "react-query";
import { Card, FormLayout, Layout, Select } from "@shopify/polaris";
import { useFormikContext } from "formik";
import { getAllEducators } from "api";
import { array } from "utils";

export function MemberInfoSection() {
  const { values, setFieldValue, handleBlur, touched, errors } =
    useFormikContext();

  const educatorsQuery = useQuery("educators", () =>
    getAllEducators("&status=Aktif")
  );

  const educators = educatorsQuery?.data || [];

  const educatorsOptions = [
    { label: "Bir eğitmen seçiniz", value: "" }
  ].concat(array(educators).map((e) => ({ label: e?.name, value: e?._id })))

  return (
    <Layout.AnnotatedSection
      title="Üyelik Seçenekleri"
      description="İlgili üyeye eğitmen ve at bilgisi tanımlamalısınız."
    >
      <Card sectioned>
        <FormLayout>
          <Select
            value={values?.mainEducator}
            name="mainEducator"
            label="Ana Eğitmen"
            onBlur={handleBlur}
            error={touched?.mainEducator && errors?.mainEducator}
            onChange={(value) => setFieldValue("mainEducator", value)}
            options={educatorsOptions}
          />
          <Select
            value={values?.backupEducator}
            name="backupEducator"
            label="Yedek Eğitmen"
            onBlur={handleBlur}
            error={touched?.backupEducator && errors?.backupEducator}
            onChange={(value) => setFieldValue("backupEducator", value)}
            options={educatorsOptions}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
