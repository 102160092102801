import { Badge, Banner, Label } from "@shopify/polaris";
import { useFormikContext } from "formik";
import { AutocompleteCustomer } from "components";
import { array } from "utils";
import { useHistory } from "react-router-dom";

export function CustomerSection() {
  const { values, errors, setValues } = useFormikContext();

  const history = useHistory();

  return (
    <>
      <div>
        <AutocompleteCustomer
          value={values?.customer || ""}
          onSelect={async (value) => {
            setValues({
              ...values,
              customer: value,
              customerId: value?._id || "",
              from: "",
              to: "",
              seansCount: 1,
            });
          }}
          clearable
        />
      </div>
      {values?.reservationTypeId && values?.customerId && (
        <div className="mt-6 space-y-1">
          <Label>Kalan Kredi Adeti</Label>
          <Badge status="info">
            {array(values?.customer?.credits).find(
              (item) => item?.reservationType === values?.reservationTypeId
            )?.count || 0}
          </Badge>
        </div>
      )}
      {errors?.hasCredit && (
        <div className="mt-4">
          <Banner
            title="Yetersiz Kredi"
            action={{
              content: "Ödeme Ekle",
              onAction: () => {
                history.push("/payments/new");
              },
            }}
            status="critical"
          >
            Seçilen müşterinin bu rezervasyon türü için yeterli kredisi
            bulunmamaktadır.
          </Banner>
        </div>
      )}
    </>
  );
}
