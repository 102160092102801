import { Select } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function ReservationType() {
  const { values, setValues, setFieldTouched, errors, touched } =
    useFormikContext();

  return (
    <Select
      label="Rezervasyon Tipi"
      options={[
        "",
        ...values?.reservationTypes?.map((reservationType) => ({
          label: reservationType?.title,
          value: reservationType?._id,
        })),
      ]}
      error={touched.reservationTypeId && errors.reservationTypeId}
      value={values?.reservationTypeId}
      onChange={(value) => {
        setValues({
          ...values,
          reservationTypeId: value || "",
          seansCount: 1,
          hasCredit: false,
          from: "",
          to: "",
          educator: null,
          educatorId: "",
        });
      }}
      onBlur={() => setFieldTouched("reservationTypeId", true)}
    />
  );
}
