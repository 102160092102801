import { getIlcelerService, getIllerService } from "api";
import { useQuery } from "react-query";
import { array } from "utils";

export function useAddressHook({ il }) {
  const illerQuery = useQuery(["iller"], () => getIllerService());
  const ilcelerQuery = useQuery(
    ["ilceler", il],
    () => getIlcelerService(`il=${il}`),
    {
      enabled: !!il,
    }
  );

  const illerData = array(illerQuery.data);
  const ilcelerData = array(ilcelerQuery.data);

  return {
    illerQuery,
    ilcelerQuery,
    illerData,
    ilcelerData,
  };
}
