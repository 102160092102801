import { IndexTable, Page, Card, Pagination } from "@shopify/polaris";
import { useHistory } from "react-router";
import { useQuery } from "react-query";

import { PaymentsRows } from "./PaymentsRows";
import { getAllPayments } from "api";
import { AutocompleteCustomer } from "components";
import { useState } from "react";
import PaymentFilter from "./PaymentFilter";

export function PaymentsList() {
  const [filters, setFilters] = useState({
    customer: "",
    "date[gte]": "",
    "date[lte]": "",
    title: "",
    page: 1,
    limit: 20,
    isDeleted: false,
  });
  const history = useHistory();

  const queryString = Object.entries(filters)
    .filter(
      ([_key, value]) => value !== undefined && value !== null && value !== ""
    )
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  const query = useQuery(["payments", queryString], () =>
    getAllPayments(`?${queryString}`)
  );

  const resourceName = {
    singular: "ödeme",
    plural: "ödemeler",
  };

  const payments = Array.isArray(query.data?.docs) ? query.data?.docs : [];

  const count = query.data?.results || 0;
  const hasPrevious = filters.page > 1;
  const hasNext = count > filters.page * filters.limit;

  return (
    <Page
      title="Ödemeler"
      primaryAction={{
        content: "Yeni ödeme",
        onAction: () => history.push("/payments/new"),
      }}
    >
      <Card>
        <PaymentFilter filters={filters} onChangeFilter={setFilters} />
        <IndexTable
          lastColumnSticky
          selectable={false}
          loading={query.isLoading}
          resourceName={resourceName}
          itemCount={payments.length}
          headings={[
            { title: "Başlık" },
            { title: "Tarih" },
            { title: "Üye" },
            { title: "Açıklama" },
            { title: "Tutar" },
            { title: "Kredi Adedi" },
            { title: "Oluşturan" },
            { title: "" },
          ]}
        >
          <PaymentsRows data={payments} />
        </IndexTable>
        {count > filters.limit && (
          <div className="py-5 flex items-center justify-end px-10">
            <Pagination
              label={`Sayfa ${filters.page}/${Math.ceil(
                count / filters.limit
              )}`}
              hasPrevious={hasPrevious}
              onPrevious={() => {
                setFilters((prev) => ({ ...prev, page: prev.page - 1 }));
              }}
              hasNext={hasNext}
              onNext={() => {
                setFilters((prev) => ({
                  ...prev,
                  page: prev.page + 1,
                }));
              }}
            />
          </div>
        )}
      </Card>
    </Page>
  );
}
