import { useState, useEffect, useCallback } from "react";
import { useMutation } from "react-query";

import {
  startOfWeek,
  eachDayOfInterval,
  addWeeks,
  lastDayOfWeek,
  startOfToday,
  startOfDay,
} from "date-fns";

export function useWeekDates(date = startOfToday()) {
  const [weeks, setWeeks] = useState([]);
  const [selectedDay, setSelectedDay] = useState(startOfDay(date));

  useEffect(() => {
    setWeeks(
      eachDayOfInterval({
        start: startOfWeek(selectedDay, { weekStartsOn: 1 }),
        end: lastDayOfWeek(selectedDay, { weekStartsOn: 1 }),
      })
    );
  }, [selectedDay]);

  const handleSelectedDate = useCallback((value) => {
    setSelectedDay(value);
  }, []);

  const nextWeek = () => {
    setSelectedDay((oldDate) =>
      startOfWeek(addWeeks(oldDate, 1), { weekStartsOn: 1 })
    );
  };

  const previousWeek = () => {
    setSelectedDay((oldDate) => {
      return lastDayOfWeek(addWeeks(oldDate, -1), { weekStartsOn: 1 });
    });
  };

  const goToday = () => {
    setSelectedDay(startOfToday());
  };

  return {
    weeks,
    handleSelectedDate,
    nextWeek,
    previousWeek,
    selectedDay,
    goToday,
  };
}
