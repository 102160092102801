import { Avatar, Button, IndexTable, TextStyle } from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";

export function HorseRows({ data = [] }) {
  return data.map(({ _id, name, status, avatar }, index) => (
    <IndexTable.Row id={_id} key={_id} position={index} subdued>
      <IndexTable.Cell>
        <div className="py-5 flex items-center">
          <div>
            <Avatar customer name={name} source={avatar} />
          </div>
          <div className="pl-2 flex flex-col">
            <Link url={`/horses/${_id}`}>
              <TextStyle variation="strong">{name}</TextStyle>
            </Link>
          </div>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>{status}</IndexTable.Cell>
      <IndexTable.Cell> </IndexTable.Cell>
      <IndexTable.Cell> </IndexTable.Cell>
      <IndexTable.Cell> </IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell></IndexTable.Cell>
      <IndexTable.Cell>
        <Button
          plain
          monochrome
          icon={EditMinor}
          url={`/horses/${_id}`}
        ></Button>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));
}
