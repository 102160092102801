import {
  Card,
  ChoiceList,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";
import { useFormikContext } from "formik";
import { array } from "utils";

const definitionKeys = [
  "isSelectableEducator",
  "isSelectableHorse",
  "isCreatableOnlyAdmin",
  "isInEffectiveSeansTime",
];

const definitionsArrayToObject = (arr) => {
  const obj = {};
  const _array = array(arr);

  for (let index = 0; index < definitionKeys.length; index++) {
    const defKey = definitionKeys[index];

    obj[defKey] = _array.some((item) => item === defKey);
  }

  return obj;
};
export function InfoSection() {
  const { values, setFieldValue, setValues, handleBlur, touched, errors } =
    useFormikContext();

  const handleSelectType = (value) => {
    setFieldValue("selectType", value?.[0]);
    setFieldValue("seansTime", 0);
    setFieldValue("maxSelectableSeans", 1);

    if (value?.[0] === "Adet") {
      setFieldValue("isInEffectiveSeansTime", true);
    }
  };

  const makeDefinitionsArray = () => {
    return Object.keys(values).filter(
      (valueKey) =>
        definitionKeys.includes(valueKey) && values[valueKey] === true
    );
  };

  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="Formlardaki alanları eksiksiz bir şekilde girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            label="Başlık"
            name="title"
            requiredIndicator
            value={values.title}
            onChange={(value) => setFieldValue("title", value)}
            onBlur={handleBlur}
            error={touched?.title && errors?.title}
          />
          <TextField
            label="Açıklama"
            multiline={true}
            name="description"
            value={values.description}
            onChange={(value) => setFieldValue("description", value)}
            onBlur={handleBlur}
          />
          <ChoiceList
            allowMultiple={false}
            title="Seçim Türü"
            choices={[
              { label: "Saat", value: "Saat" },
              { label: "Adet", value: "Adet" },
            ]}
            selected={[values?.selectType]}
            onChange={handleSelectType}
          />
          {values?.selectType === "Saat" && (
            <div className="grid grid-cols-2 gap-10">
              <TextField
                name="seansTime"
                type="number"
                label="Seans Süresi"
                value={values?.seansTime?.toString()}
                helpText="Dakika cinsinden seans süresini girin."
                onChange={(value) => setFieldValue("seansTime", value)}
              />
              <TextField
                name="maxSelectableSeans"
                type="number"
                label="Max Seans Adeti"
                value={values?.maxSelectableSeans?.toString()}
                min={1}
                helpText="Rezarvasyon oluştururken seçilebilecek max seans adetini giriniz."
                onChange={(value) => setFieldValue("maxSelectableSeans", value)}
              />
            </div>
          )}
          <ChoiceList
            allowMultiple={true}
            title="Tanımlar"
            choices={[
              {
                label: "Eğitmen seçilebilir",
                value: "isSelectableEducator",
                helpText:
                  "Bu madde seçildiğinde rezervasyon oluştururken eğitmen seçimi zorunlu olur.",
              },
              {
                label: "At seçilebilir",
                value: "isSelectableHorse",
                helpText:
                  "Bu madde seçildiğinde rezervasyon oluştururken at seçimi zorunlu olur.",
              },
              {
                label: "Sadece admin oluşturabilir",
                value: "isCreatableOnlyAdmin",
                helpText:
                  "Bu madde seçildiğinde bu rezervasyon türünde sadece admin rezervasyon girişi yapabilir",
              },
              {
                label: "Rezervasyon saatleri etkilenmez",
                helpText:
                  "Bu madde seçildiğinde aynı saatlerde birden fazla rezervasyon oluşturulabilir. Seçim türü 'Adet' olarak seçildiğinde bu seçenek default true olarak gelir",
                value: "isInEffectiveSeansTime",
              },
            ]}
            selected={makeDefinitionsArray()}
            onChange={(value) => {
              setValues({ ...values, ...definitionsArrayToObject(value) });
            }}
          />
          <ChoiceList
            allowMultiple={false}
            title="Durum"
            choices={[
              { label: "Aktif", value: true },
              { label: "Pasif", value: false },
            ]}
            selected={[!!values?.active]}
            onChange={(value) => {
              setFieldValue("active", value?.[0]);
            }}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
