import { ContextualSaveBar, Layout, Page } from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { getOneAccountingCategory, updateOneAccountingCategory } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm";

export function AccountingCategoryDetail(props) {
  const skipToContentRef = useRef(null);
  const { accountingCategoryId } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { toggleSnack } = useSnackbar();

  const getOneAccountingCategoryOnsuccess = (response) => {
    const payload = {
      title: response.title,
      description: response.description,
      type: response.type,
    };
    setInitialValues(payload);
  };

  useQuery(
    ["accountingCategory", accountingCategoryId],
    () => getOneAccountingCategory(accountingCategoryId),
    {
      onSuccess: getOneAccountingCategoryOnsuccess,
    }
  );

  const patchMutation = useMutation(
    (payload) => updateOneAccountingCategory(payload, accountingCategoryId),
    {
      onSuccess: (response) => {
        toggleSnack({
          key: "accountingCategorUpdateSuccess",
          message: "Muhasebe Kategori kaydı başarıyla güncellenmiştir.",
          active: true,
        });
      },
      onError: (error) => {
        toggleSnack({
          key: "accountingCategorUpdateError",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    }
  );

  return (
    <Page
      title="Kategori Detay"
      narrowWidth={true}
      breadcrumbs={[
        { content: "Muhasebe Kategoriler", url: "/accounting/categories" },
      ]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {initialValues && (
          <SharedForm
            initialValues={initialValues}
            isDetail={true}
            onSubmit={(values) => patchMutation.mutate(values)}
            savebarRender={(values) => (
              <Savebar {...values} isLoading={patchMutation.isLoading} />
            )}
          />
        )}
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Güncelle",
      }}
      message="Kategori Detay"
      discardAction={{
        onAction: () => history.push("/accounting/categories"),
        content: "Geri",
      }}
    />
  );
}
