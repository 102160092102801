import { axios } from "utils";

export const queryHorseHours = (payload) =>
  axios
    .post(`/horseAvailabilities/query`, payload)
    .then((res) => res.data?.docs);

export const queryHorseUnavailabilities = (payload) =>
  axios
    .post(`/horseUnavailabilities/query`, payload)
    .then((res) => res.data?.docs);

export const createHorseUnavailability = (payload) =>
  axios.post(`/horseUnavailabilities`, payload).then((res) => res.data?.doc);

export const deleteHorseUnavailability = ({ id, ...payload }) =>
  axios
    .delete(`/horseUnavailabilities/${id}`, payload)
    .then((res) => res.data?.doc);

export const getOneHorseHour = (payload) =>
  axios
    .post(`/horseAvailabilities/getOne`, payload)
    .then((res) => res.data?.doc);

export const upsertHorseHours = (payload) =>
  axios
    .post("/horseAvailabilities/upsert", payload)
    .then((res) => res.data?.doc);

export const upsertHorseTime = (payload) =>
  axios
    .post("/horseAvailabilities/upsertTime", payload)
    .then((res) => res.data?.doc);
