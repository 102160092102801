import { axios } from "utils";

export const getIllerService = async (query = "") => {
  const res = await axios.get(`/adresler/iller?${query}`);
  return res?.data?.data;
};

export const getIlcelerService = async (query = "") => {
  const res = await axios.get(`/adresler/ilceler?${query}`);
  return res?.data?.data;
};

export const getMahallelerService = async (query = "") => {
  const res = await axios.get(`/adresler/mahalleler?${query}`);
  return res?.data?.data;
};
