import { Formik, Form } from "formik";
import { validationSchema } from "./validationSchema";
import { InfoSection, ProfilePhotoSection, StatusSection } from "./Sections";

export function SharedForm(props) {
  return (
    <Formik
      initialValues={props.initialValues}
      validateOnMount={true}
      onSubmit={props.onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isValid }) => (
        <Form onSubmit={handleSubmit}>
          {isValid && props.savebarRender({ handleSubmit, isValid })}
          <InfoSection isDetail={props.isDetail} />
          <ProfilePhotoSection />
          <StatusSection />
        </Form>
      )}
    </Formik>
  );
}
