import { Layout, Page, ContextualSaveBar } from "@shopify/polaris";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

import { createOnePayment } from "api";
import { SharedForm } from "../SharedForm/SharedForm";
import { useSnackbar } from "providers";

export function NewPayment(props) {
  const skipToContentRef = useRef(null);

  const initialValues = useRef({
    title: "Bakiye Yükleme",
    description: "",
    date: new Date().toISOString().split("T")[0],
    amount: 0,
    credits: [],
    customer: "",
    isVerified: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { toggleSnack } = useSnackbar();

  const postMutation = useMutation(createOnePayment, {
    onSuccess: (response) => {
      toggleSnack({
        key: "paymentCreateSuccess",
        message: "Ödeme kaydı başarıyla oluşturulmuştur",
        active: true,
      });
    },
    onError: (error) => {
      toggleSnack({
        key: "paymentCreateError",
        error: true,
        message: error?.response?.data?.message,
        active: true,
      });
    },
  });

  return (
    <Page
      title="Yeni Ödeme"
      narrowWidth={true}
      breadcrumbs={[{ content: "Ödemeler", url: "/payments" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <SharedForm
          onSubmit={(values) =>
            postMutation.mutate({ ...values, customer: values?.customer?._id })
          }
          initialValues={initialValues.current}
          savebarRender={(values) => (
            <Savebar {...values} isLoading={postMutation.isLoading} />
          )}
        />
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Kaydet",
      }}
      message="Yeni ödeme"
      discardAction={{
        onAction: () => history.push("/payments"),
        content: "Geri",
      }}
    />
  );
}
