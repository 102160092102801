import { tcknCheck } from "utils/tcknCheck";
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required("Başlık alanı zorunludur")
    .min(4, "Başlık alanı en az 4 karakterden oluşmalıdır."),
  message: yup
    .string()
    .required("Mesaj alanı zorunludur")
    .min(4, "Mesaj alanı en az 4 karakterden oluşmalıdır.")
    .max(917, "Mesaj alanı en fazla 917 karakterden oluşmalıdır."),
});
