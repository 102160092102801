import { axios } from "utils";

export const aggregateCustomers = (payload) =>
  axios.get(`/customers/with-aggregate${payload}`).then((res) => res.data);

export const queryCustomers = (payload) =>
  axios.post(`/customers/query`, payload).then((res) => res.data);

export const getAllCustomers = (query = "") =>
  axios.get(`/customers${query}`).then((res) => res.data?.docs);

export const searchCustomers = (payload) =>
  axios.post(`/customers/search`, payload).then((res) => res.data);

export const getOneCustomer = (customerId) =>
  axios.get(`/customers/${customerId}`).then((res) => res.data);

export const createOneCustomer = (payload) =>
  axios.post("/customers", payload).then((res) => res.data);

export const updateOneCustomer = (payload, customerId) =>
  axios.patch(`/customers/${customerId}`, payload).then((res) => res.data);

export const generateMemberNo = (customerId) =>
  axios
    .get(
      `/customers/generateMemberNo?${
        customerId ? `customerId=${customerId}` : ""
      }`
    )
    .then((res) => res.data);

export const deleteOneCustomer = (customerId) =>
  axios.delete(`/customers/${customerId}`).then((res) => res.data);

export const exportCustomers = async ({ query = "", payload }) => {
  const res = await axios.post(`/customers/export${query}`, payload, {
    responseType: "arraybuffer",
  });
  return res?.data;
};
