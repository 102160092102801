import {
  IndexTable,
  Page,
  Card,
  Modal,
  DescriptionList,
  TextStyle,
} from "@shopify/polaris";
import { useQuery, useMutation } from "react-query";

import Rows from "./Rows";
import { getAllPackageRequests } from "api";
import { array } from "utils";
import { acceptPackageRequest, cancelPackageRequest } from "api";
import { useState } from "react";
import { useSnackbar } from "providers";

const ACTION_TYPES = {
  ACCEPT: "accept",
  CANCELED: "canceled",
};

const ACTION_TYPES_MAPPER = {
  [ACTION_TYPES.ACCEPT]: {
    title: "Kabul Et",
    content: (
      <div>
        Paket yükleme talebi <strong>onaylanacaktır</strong>. Bu işlem geri
        alınamaz. Devam etmek istiyor musunuz?
      </div>
    ),
    okText: "Kabul Et",
  },
  [ACTION_TYPES.CANCELED]: {
    title: "İptal Et",
    content: (
      <div>
        Paket yükleme talebi <strong>iptal edilecektir</strong>. Bu işlem geri
        alınamaz. Devam etmek istiyor musunuz?
      </div>
    ),
    okText: "İptal Et",
  },
};

const initialActionModal = {
  visible: false,
  data: {
    id: 0,
  },
  type: ACTION_TYPES.ACCEPT,
};

export default function List(props) {
  const query = useQuery("packageRequests", () =>
    getAllPackageRequests("?isDeleted=false&sort=-createdAt")
  );
  const { toggleSnack } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [actionModal, setActionModal] = useState(initialActionModal);

  const cancelMutation = useMutation(cancelPackageRequest);
  const acceptMutation = useMutation(acceptPackageRequest);

  const resourceName = {
    singular: "Paket Yükleme",
    plural: "Paket Yüklemeleri",
  };

  const payments = array(query.data);

  const openActionModal = ({
    data = actionModal.data,
    type = actionModal.type,
  }) => {
    setActionModal({
      visible: true,
      data,
      type,
    });
  };

  const closeActionModal = () => {
    setActionModal(initialActionModal);
  };

  const onCancelHandler = () =>
    cancelMutation.mutateAsync(actionModal?.data?.id);
  const onAcceptHandler = () =>
    acceptMutation.mutateAsync(actionModal?.data?.id);

  const onSubmitHandler = async () => {
    try {
      setSubmitting(true);
      switch (actionModal.type) {
        case ACTION_TYPES.ACCEPT:
          await onAcceptHandler();
          break;
        case ACTION_TYPES.CANCELED:
          await onCancelHandler();
          break;
        default:
          break;
      }
      toggleSnack({
        active: true,
        key: "cancelOrAcceptPackageRequestSuccess",
        message: "İşlem başarıyla gerçekleştirildi",
      });
      closeActionModal();
      await query.refetch();
    } catch (error) {
      toggleSnack({
        active: true,
        key: "cancelOrAcceptPackageRequestError",
        error: true,
        message:
          error?.response?.data?.message || error?.message || "Hata oluştu",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Page title="Paket Yüklemeleri">
      <Modal
        open={actionModal.visible}
        onClose={closeActionModal}
        title={ACTION_TYPES_MAPPER?.[actionModal.type]?.title}
        secondaryActions={[
          {
            key: "cancel",
            content: "Geri",
            onAction: closeActionModal,
          },
        ]}
        primaryAction={{
          content: ACTION_TYPES_MAPPER?.[actionModal.type]?.okText,
          onAction: onSubmitHandler,
          loading: submitting,
          destructive: ACTION_TYPES.CANCELED === actionModal.type,
        }}
      >
        <Modal.Section>
          <div className="border rounded-md px-4 py-4 mb-6 bg-gray-50">
            <div className="pb-4">
              <TextStyle variation="strong">Paket Detayı</TextStyle>
            </div>
            <DescriptionList
              items={[
                { term: "Talep No", description: actionModal.data?.id },
                { term: "Tarih", description: actionModal?.data?.createdAt },
                { term: "Üye", description: actionModal.data?.customer?.name },
                { term: "Paket", description: actionModal.data?.title },
                { term: "Tutar", description: actionModal.data?.totalPrice },
              ]}
            />
          </div>
          {ACTION_TYPES_MAPPER?.[actionModal.type]?.content}
        </Modal.Section>
      </Modal>
      <Card>
        <IndexTable
          lastColumnSticky
          selectable={false}
          loading={query.isFetching}
          resourceName={resourceName}
          itemCount={payments.length}
          headings={[
            { title: "Tarih" },
            { title: "Üye" },
            { title: "Yöntem" },
            { title: "Paket" },
            { title: "Tutar" },
            { title: "Durum" },
            { title: "-" },
          ]}
        >
          <Rows
            data={payments}
            onCancel={(data) =>
              openActionModal({
                data,
                type: ACTION_TYPES.CANCELED,
              })
            }
            onAccept={(data) =>
              openActionModal({
                data,
                type: ACTION_TYPES.ACCEPT,
              })
            }
          />
        </IndexTable>
      </Card>
    </Page>
  );
}
