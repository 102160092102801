import { ContextualSaveBar, Layout, Page } from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { getOneAccounting, updateOneAccounting } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm";

export function RecordDetail(props) {
  const skipToContentRef = useRef(null);
  const { accountingId } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { toggleSnack } = useSnackbar();

  const getOneAccountingOnsuccess = (response) => {
    const payload = {
      title: response.title,
      description: response.description,
      amount: response?.amount,
      type: response.type,
      date: response.date?.split("T")[0],
      category: response.category,
    };
    setInitialValues(payload);
  };

  useQuery(["accounting", accountingId], () => getOneAccounting(accountingId), {
    onSuccess: getOneAccountingOnsuccess,
  });

  const patchMutation = useMutation(
    (payload) => updateOneAccounting(payload, accountingId),
    {
      onSuccess: (response) => {
        toggleSnack({
          key: "accountingUpdateuccess",
          message: "Muhasebe kaydı başarıyla güncellenmiştir",
          active: true,
        });
      },
      onError: (error) => {
        toggleSnack({
          key: "accountingUpdaterror",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    }
  );

  return (
    <Page
      title="Kayıt Detay"
      narrowWidth={true}
      breadcrumbs={[{ content: "Muhasebe", url: "/accounting" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {initialValues && (
          <SharedForm
            initialValues={initialValues}
            isDetail={true}
            onSubmit={(values) => patchMutation.mutate(values)}
            savebarRender={(values) => (
              <Savebar {...values} isLoading={patchMutation.isLoading} />
            )}
          />
        )}
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Güncelle",
      }}
      message="Kayıt Detay"
      discardAction={{
        onAction: () => history.push("/accounting"),
        content: "Geri",
      }}
    />
  );
}
