import { Layout, Page, ContextualSaveBar } from "@shopify/polaris";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

import { createOneReservationType } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm/SharedForm";
import { array } from "utils";

const initialValues = {
  title: "",
  description: "",
  isSelectableEducator: false,
  isSelectableHorse: false,
  isCreatableOnlyAdmin: false,
  isInEffectiveSeansTime: false,
  selectType: "Saat",
  seansTime: 0,
  maxSelectableSeans: 1,
  active: true,
};
export default function NewRecord(props) {
  const skipToContentRef = useRef(null);
  const history = useHistory();

  const { toggleSnack } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postMutation = useMutation(createOneReservationType);

  const onSubmitHandler = (values) => {
    const payload = JSON.parse(JSON.stringify(values));

    if (payload.selectType === "Adet") {
      payload.seansTime = 0;
      payload.maxSelectableSeans = 1;
      payload.isInEffectiveSeansTime = true;
    }

    if (payload.selectType === "Saat") {
      payload.seansTime = 0;
      payload.maxSelectableSeans = 1;
    }
    postMutation.mutate(payload, {
      onSuccess: (response) => {
        toggleSnack({
          key: "resTypeSuccess",
          message: "Rezervasyon türü başarıyla oluşturulmuştur",
          active: true,
        });
        history.push("/reservationTypes");
      },
      onError: (error) => {
        toggleSnack({
          key: "resTypeError",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    });
  };

  return (
    <Page
      title="Yeni Rezervasyon Türü"
      narrowWidth={true}
      breadcrumbs={[{ content: "Paket", url: "/reservationTypes" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <SharedForm
          onSubmit={onSubmitHandler}
          initialValues={initialValues}
          savebarRender={(values) => (
            <Savebar {...values} isLoading={postMutation.isLoading} />
          )}
        />
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        content: "Kaydet",
      }}
      discardAction={{
        onAction: () => history.push("/reservationTypes"),
        content: "Geri",
      }}
    />
  );
}
