import {
  Banner,
  Button,
  List,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from "@shopify/polaris";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { getAvailableReservationTimes } from "api";
import { array } from "utils";

const includeKeys = [
  "reservationTypeId",
  "customerId",
  "educatorId",
  "date",
  "seansCount",
];
function makeQuery(obj) {
  return Object.keys(obj)
    .filter((key) => {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "")
        return false;
      return includeKeys.includes(key);
    })
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
}

export function SeansTimes() {
  const { errors, values, setFieldValue, setValues } = useFormikContext();
  const queryStr = makeQuery(values);
  const errorsToShow = Object.keys(errors).filter((key) =>
    includeKeys.includes(key)
  );

  const selectedReservationType = array(values?.reservationTypes).find(
    (item) => item?._id === values?.reservationTypeId
  );

  const selectTypeIsSaat = selectedReservationType?.selectType === "Saat";
  const selectTypeIsAdet = selectedReservationType?.selectType === "Adet";

  const availableReservationTimes = useQuery(
    ["availableReservationTimes", queryStr],
    () => getAvailableReservationTimes(queryStr),
    {
      enabled:
        !!queryStr &&
        selectTypeIsSaat &&
        errorsToShow.length === 0 &&
        !errors?.hasCredit &&
        !!values?.reservationTypeId,
    }
  );

  const times = array(availableReservationTimes.data?.times);

  if (availableReservationTimes.isFetching) {
    return (
      <div className="mt-5">
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={2} />
        </TextContainer>
      </div>
    );
  }

  if (errorsToShow.length > 0) {
    return (
      <Banner status="warning" title="Rezervasyon saatini seçebilmek için:">
        <List type="bullet">
          {errorsToShow.map((key) => (
            <List.Item key={key}>{errors[key]}</List.Item>
          ))}
        </List>
      </Banner>
    );
  }

  if (availableReservationTimes?.isError) {
    return (
      <Banner status="warning">
        {availableReservationTimes?.error?.response?.data?.message ||
          availableReservationTimes?.error?.message ||
          "Hata oluştu"}
      </Banner>
    );
  }

  if (selectTypeIsAdet)
    return (
      <Select
        name="seansCount"
        value={values?.seansCount?.toString()}
        onChange={(value) => setFieldValue("seansCount", +value)}
        options={Array.from(Array(20).keys()).map((item) => ({
          label: item + 1,
          value: (item + 1)?.toString(),
        }))}
      />
    );

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
      {times.map((time) => (
        <Button
          key={time?.fromStr}
          primary={values?.to === time?.toStr && values?.from === time?.fromStr}
          onClick={() =>
            setValues({ ...values, to: time?.toStr, from: time?.fromStr })
          }
          disabled={time?.status === "Rezerve"}
        >
          {time?.fromStr} - {time?.toStr}
        </Button>
      ))}
    </div>
  );
}
