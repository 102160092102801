import { Formik, Form } from "formik";
import { validationSchema } from "./validationSchema";
import {
  CustomersList,
  InfoSection,
  SelectedCustomers
} from "./Sections";
import { Banner, Layout, List } from "@shopify/polaris";

export function SharedForm(props) {
  return (
    <Formik
      initialValues={props.initialValues}
      validateOnMount={true}
      onSubmit={props.onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isValid, submitCount, errors }) => (
        <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Layout>
            {submitCount > 0 && errors && Object.keys(errors)?.length > 0 && (
              <Banner
                status="critical"
                title={`Toplam ${Object.keys(errors).length} hata var:`}
              >
                <List type="bullet">
                  {Object.keys(errors).map((key) => (
                    <List.Item key={key}>{errors[key]}</List.Item>
                  ))}
                </List>
              </Banner>
            )}
            <InfoSection />
            <CustomersList />
            <SelectedCustomers />
          </Layout>
        </Form>
      )}
    </Formik>
  );
}
