import { IndexTable, TextStyle, Avatar, Button } from "@shopify/polaris";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";

export function PaymentsRows({ data = [] }) {
  return data.map(
    (
      {
        _id,
        date,
        title,
        customer,
        description,
        amount,
        creditValue,
        createdBy
      },
      index
    ) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>
          <Link url={`/payments/${_id}`}>
            <TextStyle variation="strong">{title}</TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{new Date(date).toLocaleDateString()}</IndexTable.Cell>
        <IndexTable.Cell>
          <div className="py-5 flex items-center">
            <div>
              <Avatar customer name={customer?.name} />
            </div>
            <div className="pl-2 flex flex-col">
              <Link url={`/customers/${customer?._id}`}>
                <TextStyle variation="strong">{customer?.name}</TextStyle>
              </Link>
              <TextStyle variation="subdued">{customer?.email}</TextStyle>
            </div>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>{description}</IndexTable.Cell>
        <IndexTable.Cell>{amount}</IndexTable.Cell>
        <IndexTable.Cell>{creditValue}</IndexTable.Cell>
        <IndexTable.Cell>{createdBy || "-"}</IndexTable.Cell>
        <IndexTable.Cell>
          <div className="flex items-center gap-5">
            <Button
              plain
              monochrome
              url={`/payments/${_id}`}
              icon={EditMinor}
            ></Button>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
