import { Card, FormLayout, Layout } from "@shopify/polaris";
import { AutocompleteCustomer } from "components";
import { useFormikContext } from "formik";

export function Customer({ isDetail }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Layout.AnnotatedSection
      title="Üye"
      description="Ödemeyi yapan üyeyi seçiniz"
    >
      <Card sectioned>
        <FormLayout>
          <AutocompleteCustomer
            value={values.customer}
            onSelect={(value) => setFieldValue("customer", value)}
            clearable={!isDetail}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
