import { axios } from "utils";

export const queryPayments = (payload) =>
  axios.post(`/payments/query`, payload).then((res) => res.data?.docs);

export const getAllPayments = (query = "") =>
  axios.get("/payments" + query).then((res) => res.data);

export const getOnePayment = (paymentId) =>
  axios.get(`/payments/${paymentId}`).then((res) => res.data?.doc);

export const createOnePayment = (payload) =>
  axios.post("/payments", payload).then((res) => res.data?.doc);

export const updateOnePayment = (payload, paymentId) =>
  axios.patch(`/payments/${paymentId}`, payload).then((res) => res.data?.doc);

export const deleteManyPayments = (payload) =>
  axios.post(`/payments/deleteMany`, payload).then((res) => res.data?.docs);

export const deleteOnePayment = (paymentId) =>
  axios.delete(`/payments/${paymentId}`).then((res) => res.data);
