import { Fragment, useState } from "react";
import { Toast, Frame } from "@shopify/polaris";
import { nanoid } from "nanoid";

import { ToastContext } from "contexts";

export function ToastProvider(props) {
  const [messages, setMessages] = useState([]);

  const dismissToast = (key) => {
    const copyMessages = [...messages];
    const index = copyMessages.findIndex((c) => c?.key === key);
    if (index > -1) {
      copyMessages.splice(index, 1);
    }
    setMessages(copyMessages);
  };

  const addMessage = ({ content, error = false, duration = 2000 }) => {
    const copyMessages = [...messages];
    const payload = { content, error, duration, active: true, key: nanoid() };
    copyMessages.push(payload);
    setMessages(copyMessages);
  };

  return (
    <ToastContext.Provider value={{ dismissToast, messages, addMessage }}>
      <Frame>
        {props.children}
        {messages?.map((m, index) => (
          <Fragment key={index}>
            {m?.active ? (
              <Toast
                duration={m?.duration}
                error={m?.error}
                content={m?.content}
                onDismiss={() => dismissToast(m?.key)}
              />
            ) : null}
          </Fragment>
        ))}
      </Frame>
    </ToastContext.Provider>
  );
}
