import { axios } from "utils";

export const queryEducatorHours = (payload) =>
  axios
    .post(`/educatorAvailabilities/query`, payload)
    .then((res) => res.data?.docs);

export const queryEducatorUnavailabilities = (payload) =>
  axios
    .post(`/educatorUnavailabilities/query`, payload)
    .then((res) => res.data?.docs);

export const createEducatorUnavailability = (payload) =>
  axios.post(`/educatorUnavailabilities`, payload).then((res) => res.data?.doc);

export const deleteEducatorUnavailability = ({ id, ...payload }) =>
  axios
    .delete(`/educatorUnavailabilities/${id}`, payload)
    .then((res) => res.data?.doc);

export const getOneEducatorHour = (payload) =>
  axios
    .post(`/educatorAvailabilities/getOne`, payload)
    .then((res) => res.data?.doc);

export const upsertEducatorHours = (payload) =>
  axios
    .post("/educatorAvailabilities/upsert", payload)
    .then((res) => res.data?.doc);

export const upsertEducatorTime = (payload) =>
  axios
    .post("/educatorAvailabilities/upsertTime", payload)
    .then((res) => res.data?.doc);
