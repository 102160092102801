import { Card, FormLayout, Layout, Select, TextField } from "@shopify/polaris";
import { generateMemberNo } from "api";
import { PhoneNumber } from "components";
import { useFormikContext } from "formik";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

export function InfoSection({ isDetail }) {
  const { values, setFieldValue, handleBlur, errors, touched } =
    useFormikContext();
  const { customerId } = useParams();

  const generateMemberNoMutation = useMutation(generateMemberNo);

  const onGenerateMemberNo = () => {
    generateMemberNoMutation.mutate(customerId, {
      onSuccess: (response) => {
        if (!response?.memberNo) return alert("Member no oluşturalamadı.");

        setFieldValue("memberNo", response.memberNo);
      },
      onError: (error) => {
        alert(error?.response?.data?.message || "Hata oluştu");
      },
    });
  };
  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="Üyenin temel bilgilerini eksiksiz ve doğru girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            label="Üye Numarası"
            requiredIndicator={true}
            onBlur={handleBlur}
            name="memberNo"
            value={values?.memberNo?.toString()}
            onChange={(value) => setFieldValue("memberNo", value)}
            error={touched?.memberNo && errors?.memberNo}
            helpText="Üye numarasını oluşturmak için 'Oluştur' butonuna tıklayınız."
            labelAction={{
              content: "Oluştur",
              onAction: onGenerateMemberNo,
              loading: generateMemberNoMutation.isLoading,
            }}
          />
          <TextField
            label="T.C. Kimlik No"
            name="tckn"
            showCharacterCount
            requiredIndicator
            maxLength={11}
            value={values.tckn}
            error={touched?.tckn && errors?.tckn}
            onChange={(value) => setFieldValue("tckn", value)}
            onBlur={handleBlur}
          />
          <TextField
            label="İsim Soyisim"
            requiredIndicator
            name="name"
            value={values.name}
            error={touched?.name && errors?.name}
            onChange={(value) => setFieldValue("name", value)}
            onBlur={handleBlur}
          />
          <TextField
            type="email"
            label="Email"
            requiredIndicator
            name="email"
            error={touched?.email && errors?.email}
            value={values.email}
            onChange={(value) => setFieldValue("email", value)}
            onBlur={handleBlur}
          />

          <PhoneNumber
            label="Telefon"
            name="tel"
            requiredIndicator={true}
            allowEmptyFormatting={true}
            value={values.tel}
            error={touched?.tel && errors?.tel}
            onChange={(value) => setFieldValue("tel", value)}
            onBlur={handleBlur}
          />
          <TextField
            type="date"
            label="Doğum Tarihi"
            name="birthDate"
            requiredIndicator
            error={touched?.birthDate && errors?.birthDate}
            value={values?.birthDate ? values.birthDate?.split("T")?.[0] : ""}
            onChange={(value) => setFieldValue("birthDate", value)}
            onBlur={handleBlur}
          />
          <Select
            label="Üyelik Türü"
            name="memberType"
            requiredIndicator
            onBlur={handleBlur}
            options={["", "Normal Üye", "Misafir Üye"].map((opt) => ({
              label: opt,
              value: opt,
            }))}
            error={touched?.memberType && errors?.memberType}
            onChange={(value) => setFieldValue("memberType", value)}
            value={values.memberType}
          />
          {!isDetail && (
            <TextField
              type="type"
              label="Şifre"
              helpText="Üye sisteme ilk kez giriş yaptığında şifresini yenilemesi gerekmektedir."
              name="password"
              requiredIndicator
              minLength={6}
              error={touched?.password && errors?.password}
              value={values.password}
              onChange={(value) => setFieldValue("password", value)}
              onBlur={handleBlur}
            />
          )}
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
