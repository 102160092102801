export const times = [
  { from: "06:00", to: "07:00" },
  { from: "07:00", to: "08:00" },
  { from: "08:00", to: "09:00" },
  { from: "09:00", to: "10:00" },
  { from: "10:00", to: "11:00" },
  { from: "11:00", to: "12:00" },
  { from: "12:00", to: "13:00" },
  { from: "13:00", to: "14:00" },
  { from: "14:00", to: "15:00" },
  { from: "15:00", to: "16:00" },
  { from: "16:00", to: "17:00" },
  { from: "17:00", to: "18:00" },
  { from: "18:00", to: "19:00" },
  { from: "19:00", to: "20:00" },
  { from: "20:00", to: "21:00" },
  { from: "21:00", to: "22:00" },
  { from: "22:00", to: "23:00" },
  { from: "23:00", to: "00:00" },
  { from: "00:00", to: "01:00" },
];
