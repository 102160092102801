import { Card, ChoiceList, FormLayout, Layout } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function StatusSection(props) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Layout.AnnotatedSection
      title="Durum"
      description="Durumu pasif olan üyeler rezervasyon yapamazlar."
    >
      <Card sectioned>
        <FormLayout>
          <ChoiceList
            title="Durum"
            choices={[
              { label: "Aktif", value: "Aktif" },
              { label: "Pasif", value: "Pasif" },
            ]}
            name="status"
            selected={values.status}
            onChange={(value) => setFieldValue("status", value[0] || value)}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
