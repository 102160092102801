import { Badge, Label, Select } from "@shopify/polaris";
import { useFormikContext } from "formik";
import { array } from "utils";

const formatSeansTime = (seansTime) => {
  return seansTime % 60 === 0
    ? `${seansTime / 60} saat`
    : `${seansTime} dakika`;
};

export function SelectSeansCount() {
  const { values, setFieldValue } = useFormikContext();
  const reservationType = array(values?.reservationTypes).find(
    (type) => type?._id === values?.reservationTypeId
  );

  if (reservationType?.selectType !== "Saat") return null;

  const seansCountOptions = Array.from(
    { length: +reservationType?.maxSelectableSeans },
    (_, i) => i + 1
  ).map((item) => ({ label: item, value: item }));

  return (
    <div className="space-y-6">
      <div>
        <Label>Seans Süresi</Label>
        <div className="mt-3"></div>
        <Badge status="info">
          {formatSeansTime(reservationType?.seansTime)}
        </Badge>
      </div>
      {reservationType?.maxSelectableSeans > 1 && (
        <div className="flex">
          <Select
            value={+values?.seansCount}
            onChange={(value) => setFieldValue("seansCount", +value || 1)}
            options={seansCountOptions}
            label="Seans Adeti"
          />
        </div>
      )}
    </div>
  );
}
