import * as yup from "yup";

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email alanı zorunludur")
    .email("Lütfen geçerli bir email adresi giriniz"),
  password: yup
    .string()
    .required("Şifre alanı zorunludur")
    .min(6, "Şifre alanı en az 4 karakterden oluşmalıdır."),
});
