import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";
import { queryAccountingCategories } from "api";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";

export function TypeSection({ isDetail }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  const { data = [] } = useQuery("accountingCategories", () =>
    queryAccountingCategories({ isDeleted: false })
  );
  return (
    <Layout.AnnotatedSection title="Diğer">
      <Card sectioned>
        <FormLayout>
          <TextField readOnly value={values.type} label="Tür" />

          <TextField
            readOnly
            type="createdAt"
            value={new Date(values.createdAt).toLocaleString()}
            label="Tarih"
            onChange={(value) => setFieldValue("createdAt", value)}
          />
          <Checkbox
            label="Sonuçlandı mı?"
            name="closed"
            checked={values.closed}
            onChange={(value) => setFieldValue("closed", value)}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
