import { useState, useCallback, useEffect } from "react";
import {
  IndexTable,
  Page,
  Card,
  Tabs,
  ResourceList,
  ResourceItem,
  TextStyle,
  Badge,
  EmptyState,
  Pagination,
  Select,
  Button,
} from "@shopify/polaris";
import { useHistory } from "react-router";
import { useQuery } from "react-query";

import { ReservationsRows } from "./ReservationsRows";
import { getAllReservations } from "api";
import { array } from "utils";
import { RangePicker, WeekDates } from "components";
import { useQueryParams } from "hooks/useQueryParams";
import { endOfDay, startOfDay } from "date-fns";
import moment from "moment";
import { ExportModal } from "./ExportModal";

const tabs = [
  {
    id: "reservations-by-day",
    panelId: "reservations-by-day-panel",
    accessibilityLabel: "Günlük Rezervasyonlar",
    content: "Günlük Rezervasyonlar",
    component: ReservationsByDay,
  },
  {
    id: "reservations-by-all",
    panelId: "reservations-by-all-panel",
    accessibilityLabel: "Tüm Rezervasyonlar",
    content: "Tüm Rezervasyonlar",
    component: AllReservations,
  },
];

export function ReservationsList() {
  const {
    queryString,
    setQueryParam,
    queryParams,
    setQueryParams,
    debouncedQueryParams,
  } = useQueryParams({
    sort: "-date,-from",
    appliedFilters: {
      status: "Aktif",
    },
    page: 1,
    limit: 10,
  });
  const [exportModalIsVisible, setExportModalIsVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const history = useHistory();
  const reservationsQuery = useQuery(
    ["reservations", queryString],
    () => getAllReservations(queryString),
    {
      enabled: selectedTab === 0 ? !!debouncedQueryParams?.filters?.date : true,
    }
  );

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
    const todayOfStart = startOfDay(new Date());
    const todayOfEnd = endOfDay(new Date());
    const appliedFilterDate =
      selectedTabIndex === 0
        ? { date: todayOfStart.toISOString() }
        : {
          date: undefined,
          "date[gte]": todayOfStart,
          "date[lte]": todayOfEnd,
        };
    setQueryParams({
      page: 1,
      filters: {
        ...queryParams?.filters,
        ...appliedFilterDate,
      },
    });
  }, []);

  const data = array(reservationsQuery?.data?.docs);
  const count = reservationsQuery?.data?.results || 0;

  const SelectedTabContent = tabs[selectedTab].component;

  return (
    <Page
      title="Rezervasyonlar"
      primaryAction={{
        content: "Yeni Rezervasyon",
        onAction: () => history.push("/reservations/new"),
      }}
    >
      <ExportModal
        visible={exportModalIsVisible}
        onClose={() => setExportModalIsVisible(false)}
        totalData={data?.length}
        filter={`?${queryString}`}
      />
      <Card>
        <Tabs selected={selectedTab} tabs={tabs} onSelect={handleTabChange}>
          <Card.Section>
            <div className="space-y-10">
              <div className="w-full flex md:items-center flex-col gap-y-4 md:flex-row gap-x-5">
                <Select
                  options={["Aktif", "İptal"]}
                  label="Durum"
                  value={queryParams?.filters?.status}
                  onChange={(value) => setQueryParam("status", value)}
                  autoComplete="off"
                />
                {selectedTab === 1 && (
                  <div className="flex-1 flex flex-col md:flex-row gap-y-4 justify-between md:items-end">
                    <RangePicker
                      startDate={moment(queryParams.filters?.["date[gte]"])}
                      endDate={moment(queryParams.filters?.["date[lte]"])}
                      label={"Tarih"}
                      onDatesChange={({ startDate, endDate }) => {
                        setQueryParams({
                          filters: {
                            ...queryParams?.filters,
                            "date[gte]": startDate
                              ? startOfDay(
                                startDate?.toDate?.()
                              )?.toISOString?.()
                              : undefined,
                            "date[lte]": endDate
                              ? endOfDay(endDate?.toDate?.())?.toISOString?.()
                              : undefined,
                          },
                          page: 1,
                        });
                      }}
                    />
                    {data.length > 0 && (
                      <div>
                        <Button onClick={() => setExportModalIsVisible(true)}>
                          Excel'e Aktar
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <SelectedTabContent
                onChange={(date) => {
                  setQueryParam("date", date);
                }}
                data={data}
                count={count}
                isLoading={reservationsQuery.isFetching}
                queryParams={debouncedQueryParams}
                setQueryParams={setQueryParams}
              />
            </div>
          </Card.Section>
        </Tabs>
      </Card>
    </Page>
  );
}

function AllReservations({
  data,
  isLoading,
  count,
  queryParams,
  setQueryParams,
}) {
  const resourceName = {
    singular: "rezervasyon",
    plural: "rezervasyonlar",
  };
  const page = isNaN(queryParams?.page) ? 1 : queryParams?.page || 1;
  const limit = queryParams?.limit || 10;

  const hasPrevious = page > 1;
  const hasNext = count > page * limit;
  return (
    <>
      <IndexTable
        lastColumnSticky
        selectable={false}
        loading={isLoading}
        resourceName={resourceName}
        itemCount={data.length}
        headings={[
          { title: "Tarih" },
          { title: "R.Tipi" },
          { title: "Ders Saati" },
          { title: "Üye" },
          { title: "Eğitmen" },
          { title: "Kredi Değeri" },
          { title: "Kayıt Tarihi" },
          { title: "Durum" },
          { title: "" },
        ]}
      >
        <ReservationsRows data={data} />
      </IndexTable>
      {count > queryParams?.limit && (
        <div className="mt-5 flex items-center justify-end">
          <Pagination
            label={`Sayfa ${page}/${Math.ceil(count / limit)}`}
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQueryParams({
                page: page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQueryParams({
                page: page + 1,
              });
            }}
          />
        </div>
      )}
    </>
  );
}

const resourceName = {
  singular: "rezervasyon",
  plural: "rezervasyonlar",
};
function ReservationsByDay({
  data,
  count,
  onChange,
  isLoading,
  queryParams,
  setQueryParams,
}) {
  const page = isNaN(queryParams?.page) ? 1 : queryParams?.page || 1;
  const limit = queryParams?.limit || 10;

  const hasPrevious = page > 1;
  const hasNext = count > page * limit;

  return (
    <>
      <div
        style={{ position: "sticky", top: 56, background: "#fff", zIndex: 99 }}
        className="border-b"
      >
        <WeekDates
          onChange={(date) => onChange(date)}
          renderWithoutDisabled={true}
        />
      </div>
      <ResourceList
        resourceName={resourceName}
        items={data}
        renderItem={RezervationRow}
        loading={isLoading}
        emptyState={
          <EmptyState>
            <p>Bu tarihte herhangi bir rezervasyon bulunamadı</p>
          </EmptyState>
        }
      />
      {count > queryParams?.limit && (
        <div className="mt-5 flex items-center justify-end">
          <Pagination
            label={`Sayfa ${page}/${Math.ceil(count / limit)}`}
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQueryParams({
                page: page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQueryParams({
                page: page + 1,
              });
            }}
          />
        </div>
      )}
    </>
  );
}

const statusMapper = {
  İptal: {
    color: "critical",
    content: "İptal",
  },
  Aktif: {
    color: "success",
    content: "Aktif",
  },
};

function RezervationRow(props) {
  const {
    _id,
    reservationType,
    from,
    to,
    status,
    customer,
    educator,
    creditValue,
    cancelDescription,
  } = props;

  const currentStatus = statusMapper[status];

  return (
    <ResourceItem id={_id} url={`/reservations/${_id}`}>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="space-y-5" style={{ minWidth: 170 }}>
          <h3>
            <TextStyle variation="strong">
              {reservationType?.title || "-"}
            </TextStyle>
          </h3>
          <h6>
            <TextStyle>
              {from}-{to}
            </TextStyle>
          </h6>
          <Badge status={currentStatus?.color}>{currentStatus?.content}</Badge>
        </div>
        <div className="space-y-5">
          <div>
            <span>Üye: </span>
            <span>{customer?.name}</span>
          </div>
          <div>
            <span>Eğitmen: </span>
            <span>{educator?.name || "-"}</span>
          </div>
          <div>
            <span>Kredi Değeri: </span>
            <Badge status="info">{creditValue} adet</Badge>
          </div>
          {cancelDescription && (
            <div>
              <span>İptal Açıklaması: </span>
              <span>{cancelDescription}</span>
            </div>
          )}
        </div>
      </div>
    </ResourceItem>
  );
}
