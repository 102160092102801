import { RESERVATION_TYPES } from "@constants";
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  ChoiceList,
  DataTable,
  EmptyState,
  FormLayout,
  Label,
  Layout,
  Modal,
  Select,
  TextField,
} from "@shopify/polaris";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { useFormik, useFormikContext } from "formik";
import { useState } from "react";
import { sum } from "lodash";

import { array, formatCurrency } from "utils";
import validationSchema from "./validationSchema";
import { useQuery } from "react-query";
import { getAllReservationTypes } from "api";

const initialModalValues = {
  visible: false,
  data: { reservationType: "", creditCount: "", price: "" },
  index: -1,
  type: "new",
};

export function InfoSection() {
  const [modalState, setModalState] = useState(initialModalValues);
  const { values, setFieldValue, handleBlur, touched, errors } =
    useFormikContext();

  const definitionsData = array(values?.definitions);
  const query = "?isDeleted=false&active=true";

  const reservationTypesQuery = useQuery(["reservationTypes", query], () =>
    getAllReservationTypes(query)
  );

  const reservationTypesData = array(reservationTypesQuery?.data);

  const openModal = (payload = initialModalValues) => {
    setModalState({ ...payload, visible: true });
  };

  const closeModal = () => {
    setModalState(initialModalValues);
  };

  const onSubmitHandler = (payload, helpers) => {
    const copyDefinitions = [...definitionsData];
    if (modalState.type === "update") {
      const findIndex = copyDefinitions.findIndex(
        (def) => def?.reservationType === payload?.reservationType
      );

      if (findIndex > -1 && findIndex !== modalState.index) {
        alert("Bu rezervasyon tipi daha önceden eklenmiş!");
        return helpers.setSubmitting(false);
      }
      copyDefinitions[modalState.index] = payload;
      setFieldValue("definitions", copyDefinitions);
      helpers.setSubmitting(false);
      helpers.resetForm();
      return closeModal();
    }

    if (
      copyDefinitions.some(
        (d) => d?.reservationType === payload?.reservationType
      )
    ) {
      alert("Bu rezervasyon tipi daha önceden eklenmiş!");
      return helpers.setSubmitting(false);
    }
    copyDefinitions.push(payload);
    setFieldValue("definitions", copyDefinitions);
    helpers.setSubmitting(false);
    helpers.resetForm();
    return closeModal();
  };

  const onDeleteHandler = (index) => {
    const isComfirmed = confirm("Bu içeriği silmek istediğinize emin misiniz?");

    if (!isComfirmed) return;
    const copyDefinitions = [...definitionsData];
    copyDefinitions.splice(index, 1);

    setFieldValue("definitions", copyDefinitions);
  };

  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="Formlardaki alanları eksiksiz bir şekilde girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            label="Başlık"
            name="title"
            requiredIndicator
            value={values.title}
            onChange={(value) => setFieldValue("title", value)}
            onBlur={handleBlur}
            error={touched?.title && errors?.title}
          />
          <TextField
            label="Açıklama"
            multiline={true}
            name="description"
            value={values.description}
            onChange={(value) => setFieldValue("description", value)}
            onBlur={handleBlur}
          />
          <ChoiceList
            allowMultiple={false}
            title="Durum"
            choices={[
              { label: "Aktif", value: true },
              { label: "Pasif", value: false },
            ]}
            selected={[!!values?.active]}
            onChange={(value) => {
              setFieldValue("active", value?.[0]);
            }}
          />
        </FormLayout>

        <div>
          <div className="pt-10"></div>
          <div className="flex items-center justify-between">
            <Label requiredIndicator>Paket İçerikleri</Label>
            {definitionsData?.length > 0 && (
              <div>
                <Button size="slim" primary onClick={() => openModal()}>
                  Yeni Paket İçeriği
                </Button>
              </div>
            )}
          </div>
          {errors?.definitions && touched?.definitions && (
            <div>
              <div className="py-5"></div>
              <Banner status="critical">{errors?.definitions}</Banner>
            </div>
          )}
          {definitionsData?.length > 0 ? (
            <DataTable
              headings={["Rezervasyon Tipi", "Kredi Adeti", "Fiyat", "-"]}
              columnContentTypes={["text", "numeric", "numeric", "numeric"]}
              rows={definitionsData.map((definition, index) => [
                reservationTypesData?.find(
                  (reservationType) =>
                    definition?.reservationType === reservationType?._id ||
                    definition?.reservationType?._id === reservationType?._id
                )?.title || "-",
                `${definition?.creditCount} Kredi`,
                formatCurrency(definition?.price),
                <div className="flex items-center justify-end mt-3">
                  <ButtonGroup spacing="extraTight" segmented>
                    <Button
                      icon={EditMinor}
                      plain
                      monochrome
                      size="slim"
                      onClick={() =>
                        openModal({ data: definition, index, type: "update" })
                      }
                    />
                    <Button
                      icon={DeleteMinor}
                      plain
                      destructive
                      size="slim"
                      onClick={() => onDeleteHandler(index)}
                    />
                  </ButtonGroup>
                </div>,
              ])}
              showTotalsInFooter={true}
              totals={[
                "",
                `${sum(definitionsData.map((d) => +d?.creditCount))} Kredi`,
                formatCurrency(sum(definitionsData?.map((def) => +def?.price))),
                "",
              ]}
            />
          ) : (
            <EmptyState
              heading="Paket içeriği henüz eklenmedi"
              action={{
                content: "Yeni Paket İçeriği",
                onAction: () => openModal(),
              }}
            >
              <p>Rezervasyon türlerine göre fiyatlandırmalarınızı yönetin</p>
            </EmptyState>
          )}
        </div>
        <AddOrUpdatePackagePrice
          {...modalState}
          onClose={closeModal}
          onSubmit={onSubmitHandler}
          reservationTypes={reservationTypesData}
        />
      </Card>
    </Layout.AnnotatedSection>
  );
}

function AddOrUpdatePackagePrice({
  visible,
  data,
  onClose,
  onSubmit,
  type,
  reservationTypes,
}) {
  const title =
    type === "new" ? "Yeni Paket İçeriği" : "Paket İçeriğini Güncelle";

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleReset,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  const onCloseHandler = () => {
    handleReset();
    onClose();
  };
  return (
    <Modal
      title={title}
      open={visible}
      onClose={onCloseHandler}
      primaryAction={{
        content: "Kaydet",
        loading: isSubmitting,
        onAction: handleSubmit,
      }}
      secondaryActions={[
        { key: "update", content: "İptal", onAction: onCloseHandler },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <Select
            requiredIndicator
            name="reservationType"
            value={values?.reservationType}
            onChange={(value) => setFieldValue("reservationType", value)}
            onBlur={handleBlur}
            label="Rezervasyon Tipi"
            options={[""].concat(reservationTypes).map((opt) => ({
              label: opt?.title || "",
              value: opt?._id || "",
            }))}
            error={touched?.reservationType && errors?.reservationType}
          />
          <TextField
            requiredIndicator
            label="Kredi Adeti"
            type="number"
            name="creditCount"
            value={values?.creditCount}
            onChange={(value) => setFieldValue("creditCount", value)}
            onBlur={handleBlur}
            error={touched?.creditCount && errors?.creditCount}
          />
          <TextField
            requiredIndicator
            label="Fiyat"
            type="number"
            name="price"
            value={values?.price}
            onChange={(value) => setFieldValue("price", value)}
            onBlur={handleBlur}
            error={touched?.price && errors?.price}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
