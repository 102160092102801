import { useState, useCallback, useEffect } from "react";
import {
  IndexTable,
  Page,
  TextField,
  Filters,
  Select,
  useIndexResourceState,
  Card,
  Modal,
} from "@shopify/polaris";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

import { useActions, useDebounce } from "hooks";
import { AccountingCategoriesRows } from "./AccountingCategoriesRows";
import { queryAccountingCategories, deleteManyAccountingCategories } from "api";

export function AccountingCategoriesList(props) {
  const history = useHistory();
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const mutation = useMutation(queryAccountingCategories);
  const deleteManyMutation = useMutation(deleteManyAccountingCategories);

  const resourceName = {
    singular: "kategori",
    plural: "kategoriler",
  };

  const accountingCategories =
    mutation.data?.map((d) => ({ id: d._id, ...d })) || [];

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(accountingCategories);
  const {
    filters,
    appliedFilters,
    sort,
    query,
    handleChangeFilter,
    handleChangeQuery,
    handleChangeSort,
    handleClearAll,
    handleFilterClear,
    handleQueryClear,
  } = useActions({ durum: "" });

  const debouncedFilters = useDebounce(filters, 750);
  const debouncedSort = useDebounce(sort, 500);
  const debouncedQuery = useDebounce(query, 500);
  useEffect(() => {
    const status = filters["durum"];
    const payload = {};
    if (status) {
      payload.status = status;
    }

    if (query) {
      payload.name = { regex: query };
    }

    if (sort) {
      payload.sort = sort;
    }
    mutation.mutate(payload);
  }, [debouncedFilters, debouncedSort, debouncedQuery]);

  const _filters = [
    {
      key: "durum",
      label: "Durum",
      filter: (
        <TextField
          label="Durum"
          value={filters["durum"]}
          onChange={(value) => handleChangeFilter("durum", value)}
          labelHidden
        />
      ),
      shortcut: true,
    },
  ];

  const promotedBulkActions = [
    {
      content: "Sil",

      onAction: () =>
        deleteManyMutation.mutate({ documents: selectedResources }),
    },
  ];

  const toggleDeleteModal = useCallback(
    () => setActiveDeleteModal((active) => !active),
    []
  );
  const sortOptions = [
    { label: "Yeniden Eskiye", value: "createdAt" },
    { label: "Eskiden Yeniye", value: "-createdAt" },
    { label: "İsim: Artan", value: "name" },
    { label: "İsim: Azalan", value: "-name" },
  ];
  return (
    <Page
      title="Muhasebe Kategoriler"
      breadcrumbs={[{ content: "Muhasebe", url: "/accounting" }]}
      primaryAction={{
        content: "Yeni Kayıt",
        onAction: () => history.push("/accounting/categories/new"),
      }}
    >
      <Modal
        open={activeDeleteModal}
        onClose={toggleDeleteModal}
        title="Get a shareable link"
        primaryAction={{
          content: "Close",
          onAction: toggleDeleteModal,
        }}
      >
        <Modal.Section></Modal.Section>
      </Modal>
      <Card>
        <div className="flex flex-col md:flex-row px-3 py-4">
          <div className="md:flex-1">
            <Filters
              queryValue={query}
              filters={_filters}
              queryPlaceholder="Muhasebe Kayıtlarında Ara"
              appliedFilters={appliedFilters}
              onQueryChange={handleChangeQuery}
              onQueryClear={handleQueryClear}
              onClearAll={handleClearAll}
            />
          </div>
          <div className="md:pl-2">
            <Select
              labelInline
              label="Sırala"
              options={sortOptions}
              value={sort}
              onChange={handleChangeSort}
            />
          </div>
        </div>
        <IndexTable
          selectable
          loading={mutation.isLoading}
          resourceName={resourceName}
          itemCount={accountingCategories.length}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={promotedBulkActions}
          headings={[
            { title: "Başlık" },
            { title: "Açıklama" },
            { title: "Tür" },
          ]}
        >
          <AccountingCategoriesRows
            data={accountingCategories}
            selectedResources={selectedResources}
          />
        </IndexTable>
      </Card>
    </Page>
  );
}
