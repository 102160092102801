import { Layout, Page, ContextualSaveBar } from "@shopify/polaris";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

import { createOnePackage } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm/SharedForm";

const initialValues = {
  title: "",
  description: "",
  definitions: [],
  active: true,
};
export default function NewRecord(props) {
  const skipToContentRef = useRef(null);
  const history = useHistory();

  const { toggleSnack } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postMutation = useMutation(createOnePackage);

  const onSubmitHandler = (values) => {
    postMutation.mutate(values, {
      onSuccess: (response) => {
        toggleSnack({
          key: "packageCreatingSuccess",
          message: "Paket kaydı başarıyla oluşturulmuştur",
          active: true,
        });
        history.push("/reservationPackages");
      },
      onError: (error) => {
        toggleSnack({
          key: "packageCreatingError",
          error: true,
          message: error?.response?.data?.message,
          active: true,
        });
      },
    });
  };

  return (
    <Page
      title="Yeni Paket"
      narrowWidth={true}
      breadcrumbs={[{ content: "Paket", url: "/reservationPackages" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <SharedForm
          onSubmit={onSubmitHandler}
          initialValues={initialValues}
          savebarRender={(values) => (
            <Savebar {...values} isLoading={postMutation.isLoading} />
          )}
        />
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        content: "Kaydet",
      }}
      message="Yeni paket"
      discardAction={{
        onAction: () => history.push("/reservationPackages"),
        content: "Geri",
      }}
    />
  );
}
