import { Button, IndexTable } from "@shopify/polaris";
import { MobileBackArrowMajor } from "@shopify/polaris-icons";
import { useFormikContext } from "formik";
import { useState } from "react";

export function SelectedCustomers() {
    const { values, setFieldValue, isSubmitting } = useFormikContext();
    const [step, setStep] = useState(0);

    const selectedCustomers = Array.isArray(values.customers)
        ? values.customers
        : [];

    const customersCount = selectedCustomers.length || 0;

    return (
        <div>
            <div
                style={{
                    maxHeight: "80vh",
                    width: "70vw",
                    minWidth: "200px",
                    maxWidth: 600,
                    overflow: "scroll",
                }}
                className="fixed bottom-0 right-3 bg-gray-800 text-white rounded-t-lg shadow-lg z-50"
            >
                {step === 0 && (
                    <div className="flex items-center justify-between gap-x-2 py-6 px-6">
                        <div>
                            <p className="font-semibold">Seçilen Müşteri</p>
                            <p className="text-3xl font-semibold">{customersCount} adet</p>
                        </div>
                        <div>
                            <Button
                                disabled={selectedCustomers.length <= 0}
                                primary
                                onClick={() => setStep(1)}
                            >
                                Devam Et
                            </Button>
                        </div>
                    </div>
                )}
                {step === 1 && (
                    <div className="flex flex-col">
                        <div className="flex items-center justify-between gap-x-2 sticky top-0 bg-gray-800 z-50 py-6 px-6">
                            <div className="flex items-center gap-x-2">
                                <Button
                                    icon={MobileBackArrowMajor}
                                    plain
                                    monochrome
                                    size="slim"
                                    onClick={() => setStep(0)}
                                ></Button>
                                <p className="font-semibold">Müşterileri Önizle</p>
                            </div>
                            <div>
                                <Button loading={isSubmitting} primary submit>Toplu Gönder</Button>
                            </div>
                        </div>
                        <div className="flex-1 overflow-scroll py-6 px-6">
                            <IndexTable
                                selectable={false}
                                loading={false}
                                resourceName={{
                                    plural: "Seçilen üyeler",
                                    singular: "Seçilen üye",
                                }}
                                itemCount={selectedCustomers.length}
                                headings={[{ title: "İsim Soyisim" }, { title: "Telefon" }]}
                            >
                                {selectedCustomers.map((c) => {
                                    return (
                                        <IndexTable.Row
                                            id={c.customerId}
                                            key={c.customerId}
                                            position={0}
                                            subdued
                                        >
                                            <IndexTable.Cell>{c.name}</IndexTable.Cell>
                                            <IndexTable.Cell>{c.phone}</IndexTable.Cell>
                                            <IndexTable.Cell>
                                                <Button
                                                    plain
                                                    destructive
                                                    onClick={() => {
                                                        const copySelectedCustomers = [
                                                            ...selectedCustomers,
                                                        ];
                                                        const findIndex = copySelectedCustomers?.findIndex(
                                                            (c) => c?.customerId === c.customerId
                                                        );
                                                        if (findIndex > -1) {
                                                            copySelectedCustomers.splice(findIndex, 1);
                                                        }
                                                        setFieldValue("customers", copySelectedCustomers);
                                                        if (copySelectedCustomers.length <= 0) setStep(0);
                                                    }}
                                                >
                                                    Çıkar
                                                </Button>
                                            </IndexTable.Cell>
                                        </IndexTable.Row>
                                    );
                                })}
                            </IndexTable>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
