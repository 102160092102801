import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Icon,
  IndexTable,
  TextStyle,
} from "@shopify/polaris";
import { EditMinor, DeleteMinor } from "@shopify/polaris-icons";
import { Link } from "components";

const statusMapper = {
  Aktif: "success",
  Pasif: "critical",
};

export function CustomerRows({ data = [], onDelete }) {
  return data.map(
    (
      {
        _id,
        name,
        memberNo,
        email,
        statistics,
        memberType,
        status,
        totalReservations,
        totalCreditsValue,
        tel,
        lastReservationDate,
      },
      index
    ) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>{memberNo || "-"}</IndexTable.Cell>

        <IndexTable.Cell>
          <div className="py-5 flex items-center">
            <div>
              <Avatar customer name={name} />
            </div>
            <div className="pl-2 flex flex-col">
              <Link url={`/customers/${_id}`}>
                <TextStyle variation="strong">{name}</TextStyle>
              </Link>
              <TextStyle variation="subdued">{email}</TextStyle>
            </div>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextStyle variation="subdued">{tel}</TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>{memberType || "-"}</IndexTable.Cell>
        <IndexTable.Cell>
          <Badge status={statusMapper?.[status] || "new"}>{status}</Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {lastReservationDate
            ? new Date(lastReservationDate)?.toLocaleDateString?.()
            : "-"}
        </IndexTable.Cell>
        <IndexTable.Cell>{totalReservations}</IndexTable.Cell>

        <IndexTable.Cell>{statistics?.totalCredits}</IndexTable.Cell>
        <IndexTable.Cell>{totalCreditsValue}</IndexTable.Cell>

        <IndexTable.Cell>
          <ButtonGroup>
            <Link url={`/customers/${_id}`}>
              <Button plain icon={EditMinor}></Button>
            </Link>
            <Button
              destructive
              plain
              icon={DeleteMinor}
              onClick={() =>
                onDelete({
                  _id,
                  name,
                  email,
                  memberNo,
                })
              }
            ></Button>
          </ButtonGroup>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
