import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import trTranslations from "@shopify/polaris/locales/tr.json";
import { QueryClientProvider, QueryClient } from "react-query";
import { App } from "./app";
import "@shopify/polaris/build/esm/styles.css";

import { Link, Snackbar } from "components";
import {
  AuthenticationProvider,
  SnackbarProvider,
  ToastProvider,
} from "providers";

import reportWebVitals from "./reportWebVitals";

import "styles/index.css";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AppProvider
      theme={{
        colors: {
          primary: "#625ED7",
          critical: "red",
          highlight: "#625ED7",
          decorative: "#625ED7",
          surface: "#E5E7ED",
        },
      }}
      linkComponent={Link}
      i18n={trTranslations}
    >
      <SnackbarProvider>
        <ToastProvider>
          <Snackbar>
            <AuthenticationProvider>
              <Router>
                <App />
              </Router>
            </AuthenticationProvider>
          </Snackbar>
        </ToastProvider>
      </SnackbarProvider>
    </AppProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
