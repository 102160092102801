import { useState, useCallback, useEffect } from "react";
import { useMutation } from "react-query";

import { getAllCustomers, searchCustomers } from "api";
import { useDebounce } from "hooks";
import { Avatar, TextStyle } from "@shopify/polaris";

export function useSearchCustomers(props) {
  const customerMutation = useMutation(searchCustomers);
  const getAllCustomersMutation = useMutation(getAllCustomers);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [options, setOptions] = useState([]);
  const [queryValue, setQueryValue] = useState("");

  const changeQueryValue = (value) => {
    setQueryValue(value);
  };

  const changeOptions = (value) => {
    setOptions(value);
  };
  const changeSelectedOptions = (value) => {
    setSelectedOptions(value);
  };

  const debouncedQueryValue = useDebounce(queryValue, 750);

  useEffect(() => {
    if (!!debouncedQueryValue) return;
    getAllCustomersMutation.mutate("?limit=10&sort=-createdAt", {
      onSuccess: (response) => {
        setCustomers(response);
        const optionsCustomers = response.map((customer) => ({
          value: customer?._id,
          label: (
            <div className="flex items-start">
              <div>
                <Avatar customer name={customer?.name} />
              </div>
              <div className="pl-2 ml-2">
                <div>
                  <TextStyle>{customer?.name}</TextStyle>
                </div>
                <div>
                  <TextStyle>{customer?.email}</TextStyle>
                </div>
              </div>
            </div>
          ),
        }));

        changeOptions(optionsCustomers);
      },
      onError: (error) => {
        console.log(error?.response, "autocompleteerror");
      },
    });
  }, [debouncedQueryValue]);

  useEffect(() => {
    if (debouncedQueryValue === "" || debouncedQueryValue === null) {
      return;
    }
    const payload = {
      text: { search: debouncedQueryValue },
      sort: { score: { $meta: "textScore" } },
    };

    customerMutation.mutate(payload, {
      onSuccess: (response) => {
        setCustomers(response?.docs);
        const optionsCustomers = response?.docs.map((customer) => ({
          value: customer?._id,
          label: (
            <div className="flex items-start">
              <div>
                <Avatar customer name={customer?.name} />
              </div>
              <div className="pl-2 ml-2">
                <div>
                  <TextStyle>{customer?.name}</TextStyle>
                </div>
                <div>
                  <TextStyle>{customer?.email}</TextStyle>
                </div>
              </div>
            </div>
          ),
        }));

        changeOptions(optionsCustomers);
      },
      onError: (error) => {
        console.log(error?.response, "autocompleteerror");
      },
    });
  }, [debouncedQueryValue]);

  return {
    queryValue,
    changeQueryValue,
    options,
    changeOptions,
    selectedOptions,
    changeSelectedOptions,
    customers,
    isLoading: customerMutation.isLoading,
  };
}
