import {
  IndexTable,
  Card,
  Tabs,
  Layout,
  FormLayout,
  Button,
} from "@shopify/polaris";
import { useQuery } from "react-query";

import { aggregateCustomers } from "api";
import { CustomerRows } from "./CustomerRows";
import { useState } from "react";
import { array } from "utils";
import { CustomerFilter } from "./CustomerFilter";
import { useDebounce } from "hooks";
import { useFormikContext } from "formik";

const initialFilters = {
  status: "",
  memberType: "",
  totalCredits: [],
  totalCreditsValue: [],
  totalReservations: [],
};

const makeQuery = (filters, query) => {
  const filtersArr = Object.keys(filters)
    .filter((filterKey) => {
      const filterValue = filters[filterKey];
      return Array.isArray(filterValue)
        ? filterValue.length > 0
        : typeof filterValue === "string" && !!filterValue;
    })
    .map((filterKey) => {
      const filterValue = filters[filterKey];
      return `${filterKey}=${Array.isArray(filterValue)
        ? filterValue.filter((item) => !!item).join(",")
        : filterValue
        }`;
    });

  const queryArr = !!query ? [`query=${query}`] : [];

  return queryArr.concat(filtersArr).join("&");
};

const statusMapper = {
  0: "Aktif",
  1: "Pasif",
};

export function CustomersList(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState(initialFilters);

  const debouncedQuery = useDebounce(makeQuery(filters, query), 700);

  const { values, setFieldValue } = useFormikContext()

  const queryStr = [debouncedQuery, `status=${statusMapper[selectedTab]}`]
    .filter((item) => !!item)
    .join("&");

  const customersQuery = useQuery(["customersWithAggregate", queryStr], () =>
    aggregateCustomers(`?${queryStr || ""}`)
  );

  const resourceName = {
    singular: "üye",
    plural: "üyeler",
  };

  const customers = array(customersQuery.data?.docs);

  return (
    <Layout.AnnotatedSection
      title="Üye Seç"
      description="SMS göndermek istediğiniz üyeleri seçebilirsiniz."
    >
      <Card sectioned>
        <Tabs
          selected={selectedTab}
          tabs={[
            { id: "aktif", panelID: "aktif", content: "Aktif Olanlar" },
            { id: "pasif", panelID: "pasif", content: "Pasif Olanlar" },
          ]}
          onSelect={(selectedTabIndex) => setSelectedTab(selectedTabIndex)}
        >
          <div className="px-6 py-6 flex items-center justify-between gap-x-3">
            <Button onClick={() => {
              const copySelectedCustomers = [...values.customers];
              customers.forEach(c => {
                const findIndex = copySelectedCustomers?.findIndex(cc => cc?.customerId === c._id)
                if (findIndex === -1) {
                  copySelectedCustomers.push({
                    customerId: c._id,
                    name: c.name,
                    phone: c.tel
                  })
                }
              })
              setFieldValue("customers", copySelectedCustomers)
            }}>
              Tümünü Seç
            </Button>
            <div className="flex-1">
              <CustomerFilter
                query={query}
                setQuery={setQuery}
                filters={filters}
                setFilters={setFilters}
                initialFilters={initialFilters}
              />
            </div>

          </div>
          <div style={{ maxWidth: "100%" }}>
            <IndexTable
              selectable={false}
              loading={customersQuery.isFetching}
              resourceName={resourceName}
              itemCount={customers.length}
              lastColumnSticky

              headings={[
                { title: "Üye Numarası" },
                { title: "İsim Soyisim" },
                { title: "Telefon" },
                { title: "Üyelik Türü" },
                { title: "Durum" },
                { title: "Son Rezervasyon" },
                { title: "Toplam Rezervasyon" },
                { title: "Kalan Kredi" },
                { title: "Toplam Kredi" },
                { title: "Seç" }
              ]}
            >
              <CustomerRows
                data={customers}
              />
            </IndexTable>
          </div>
        </Tabs>
      </Card>
    </Layout.AnnotatedSection>
  );
}
