import { Layout, Page, ContextualSaveBar } from "@shopify/polaris";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

import { createOneAccounting } from "api";
import { useSnackbar } from "providers";
import { SharedForm } from "../SharedForm/SharedForm";

export function NewRecord(props) {
  const skipToContentRef = useRef(null);

  const initialValues = useRef({
    title: "",
    description: "",
    date: new Date().toISOString().split("T")[0],
    amount: 0,
    type: "Masraf",
    category: "",
  });
  const { toggleSnack } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postMutation = useMutation(createOneAccounting, {
    onSuccess: (response) => {
      toggleSnack({
        key: "accountingCreateSuccess",
        message: "Muhasebe kaydı başarıyla oluşturulmuştur",
        active: true,
      });
    },
    onError: (error) => {
      toggleSnack({
        key: "accountingCreateError",
        error: true,
        message: error?.response?.data?.message,
        active: true,
      });
    },
  });

  return (
    <Page
      title="Yeni Kayıt"
      narrowWidth={true}
      breadcrumbs={[{ content: "Muhasebe", url: "/accounting" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <SharedForm
          onSubmit={(values) => postMutation.mutate(values)}
          initialValues={initialValues.current}
          savebarRender={(values) => (
            <Savebar {...values} isLoading={postMutation.isLoading} />
          )}
        />
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Kaydet",
      }}
      message="Yeni kayıt"
      discardAction={{
        onAction: () => history.push("/accounting"),
        content: "Geri",
      }}
    />
  );
}
