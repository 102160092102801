import { axios } from "utils";

export const getAllHelps = () =>
  axios.get(`/helps?sort`).then((res) => res.data?.docs);

export const queryHelps = (payload) =>
  axios.post(`/helps/query`, payload).then((res) => res.data?.docs);

export const getOneHelp = (helpId) =>
  axios.get(`/helps/${helpId}`).then((res) => res.data?.doc);

export const createOneHelp = (payload) =>
  axios.post("/helps", payload).then((res) => res.data?.doc);

export const updateOneHelp = (payload, helpId) =>
  axios.patch(`/helps/${helpId}`, payload).then((res) => res.data?.doc);

export const deleteManyHelps = (payload) =>
  axios.post(`/helps/deleteMany`, payload).then((res) => res.data?.docs);
