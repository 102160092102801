import {
  Button,
  Card,
  FormLayout,
  Layout,
  Page,
  TextField,
} from "@shopify/polaris";
import { useMutation } from "react-query";
import { useParams, useHistory } from "react-router";
import { Form, Formik } from "formik";

import { resetPassword } from "api";
import { useAuth } from "providers";
import { resetPasswordValidationSchema } from "./validationSchema";

export function ResetPassword(props) {
  const resetPasswordMutation = useMutation(resetPassword);
  const { loginSetter } = useAuth();
  const { resetToken } = useParams();
  const history = useHistory();

  const onSubmitHandler = ({ token, ...values }) => {
    resetPasswordMutation.mutate(
      { token, ...values },
      {
        onSuccess: (response) => {
          console.log(response, "response");
          const { user, token } = response;
          loginSetter({ user, token });
          alert("Şifre başarıyla değiştirildi");
          history.push("/");
        },
        onError: (error) => {
          console.log(error?.response?.data, "error");
          alert("Hata oluştu");
        },
      }
    );
  };
  return (
    <Page
      title="Hara Admin Panel"
      narrowWidth
      breadcrumbs={[{ content: "Giriş yap", url: "/" }]}
    >
      <Layout>
        <Formik
          initialValues={{
            password: "",
            passwordConfirm: "",
            token: resetToken,
          }}
          validationSchema={resetPasswordValidationSchema}
          validateOnMount={true}
          onSubmit={onSubmitHandler}
        >
          {({ values, handleBlur, isValid, handleSubmit, setFieldValue }) => (
            <Layout.AnnotatedSection title="Şifre Yenile">
              <Form onSubmit={handleSubmit}>
                <Card sectioned>
                  <FormLayout>
                    <TextField
                      label="Şifre"
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={(value) => setFieldValue("password", value)}
                      onBlur={handleBlur}
                    />

                    <TextField
                      label="Şifreyi Doğrula"
                      type="password"
                      name="passwordConfirm"
                      value={values.passwordConfirm}
                      onChange={(value) =>
                        setFieldValue("passwordConfirm", value)
                      }
                      onBlur={handleBlur}
                    />
                    <Button
                      submit
                      primary
                      loading={resetPasswordMutation.isLoading}
                      disabled={!isValid}
                    >
                      Şifreyi Yenile
                    </Button>
                  </FormLayout>
                </Card>
              </Form>
            </Layout.AnnotatedSection>
          )}
        </Formik>
      </Layout>
    </Page>
  );
}
