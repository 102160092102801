import { axios } from "utils";

export const getAllReservationTypes = (query = "") =>
  axios.get(`/reservationTypes` + query).then((res) => res.data?.docs);

export const getOneReservationType = (reservationType) =>
  axios
    .get(`/reservationTypes/${reservationType}`)
    .then((res) => res.data?.doc);

export const createOneReservationType = (payload) =>
  axios.post("/reservationTypes", payload).then((res) => res.data?.doc);

export const updateOneReservationType = (payload, reservationTypeId) =>
  axios
    .patch(`/reservationTypes/${reservationTypeId}`, payload)
    .then((res) => res.data?.doc);

export const deleteOneReservationType = (_id) =>
  axios.delete(`/reservationTypes/${_id}`).then((res) => res.data?.doc);
