import { Card, Checkbox, FormLayout, Layout } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function ContactPermissionsSection(props) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Layout.AnnotatedSection
      title="İletişim İzinleri"
      description="Üyenin iletişim seçeneklerinden hangilerine izin verileceğini seçiniz."
    >
      <Card sectioned>
        <FormLayout>
          <Checkbox
            label="Sms"
            name="permissions.sms"
            checked={values.permissions.sms}
            onChange={(value) => setFieldValue("permissions.sms", value)}
          />
          <Checkbox
            label="Email"
            name="permissions.email"
            checked={values.permissions.email}
            onChange={(value) => setFieldValue("permissions.email", value)}
          />
          <Checkbox
            label="Arama"
            name="permissions.call"
            checked={values.permissions.call}
            onChange={(value) => setFieldValue("permissions.call", value)}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
