import { Card, FormLayout, Layout, Select, TextField } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function InfoSection({ isDetail }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();
  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="Muhasebe kaydı bilgilerini eksiksiz ve doğru girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <Select
            label="Başlık"
            options={["Bakiye Yükleme"].map((val) => ({
              value: val,
              label: val,
            }))}
            value={values.title}
            onChange={(value) => setFieldValue("title", value)}
          />

          <TextField
            label="Açıklama"
            multiline={true}
            name="description"
            value={values.description}
            onChange={(value) => setFieldValue("description", value)}
            onBlur={handleBlur}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
