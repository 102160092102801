import { IndexTable, Page, Card } from "@shopify/polaris";
import { useHistory } from "react-router";
import { useQuery } from "react-query";

import { getAllHorses } from "api";

import { HorseRows } from "./HorseRows";

export function HorsesList(props) {
  const history = useHistory();
  const { data, isLoading } = useQuery("horses", getAllHorses);

  const resourceName = {
    singular: "at",
    plural: "atlar",
  };

  const horses = data || [];

  return (
    <Page
      title="Atlar"
      primaryAction={{
        content: "Yeni At",
        onAction: () => history.push("/horses/new"),
      }}
    >
      <Card>
        <IndexTable
          lastColumnSticky
          selectable={false}
          loading={isLoading}
          resourceName={resourceName}
          itemCount={horses.length}
          headings={[
            { title: "At" },
            { title: "Durum" },
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" },
          ]}
        >
          <HorseRows data={horses} />
        </IndexTable>
      </Card>
    </Page>
  );
}
