import { axios } from "utils";

export const queryAccountingCategories = (payload) =>
  axios
    .post(`/accountingCategory/query`, payload)
    .then((res) => res.data?.docs);

export const getOneAccountingCategory = (accountingCategoryId) =>
  axios
    .get(`/accountingCategory/${accountingCategoryId}`)
    .then((res) => res.data?.doc);

export const createOneAccountingCategory = (payload) =>
  axios.post("/accountingCategory", payload).then((res) => res.data?.doc);

export const updateOneAccountingCategory = (payload, accountingCategoryId) =>
  axios
    .patch(`/accountingCategory/${accountingCategoryId}`, payload)
    .then((res) => res.data?.doc);

export const deleteManyAccountingCategories = (payload) =>
  axios
    .post(`/accountingCategory/deleteMany`, payload)
    .then((res) => res.data?.docs);
