import {
  Badge,
  Banner,
  Card,
  DescriptionList,
  Layout,
  Page,
  TextStyle,
} from "@shopify/polaris";
import { useRef, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router";

import { cancelReservation, getOneReservation } from "api";
import { useSnackbar } from "providers";
import { SkeletonPage } from "components";

export function ReservationDetail(props) {
  const skipToContentRef = useRef(null);
  const history = useHistory();
  const { reservationId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { toggleSnack } = useSnackbar();

  const reservationQuery = useQuery(["reservations", reservationId], () =>
    getOneReservation(reservationId)
  );

  const data = reservationQuery?.data;

  const cancelMutation = useMutation(cancelReservation);

  const onCancelHandler = () => {
    const description = prompt("Rezervasyon iptal açıklamasını giriniz.", "");
    if (!description) {
      return alert("Açıklama girmediniz.");
    }

    const isConfirm = window.confirm(
      "Rezervasyon iptal edilecektir. Onaylıyor musunuz?"
    );

    if (!isConfirm) {
      return;
    }

    cancelMutation.mutate(
      {
        reservationId,
        payload: { cancelDescription: description },
      },
      {
        onSuccess: (response) => {
          toggleSnack({
            key: "cancelReservationSuccess",
            message: "Rezervasyon başarıyla iptal edilmiştir",
            active: true,
          });
          history.push("/reservations");
        },
        onError: (error) => {
          toggleSnack({
            key: "cancelReservationError",
            error: true,
            message: error?.response?.data?.message,
            active: true,
          });
        },
      }
    );
  };

  if (reservationQuery?.isFetching) {
    return <SkeletonPage />;
  }

  if (reservationQuery?.isError) {
    return (
      <Page
        title="Rezervasyon Detay"
        breadcrumbs={[{ content: "Rezervasyonlar", url: "/reservations" }]}
        narrowWidth
      >
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <Banner status="critical">
                <p>
                  {reservationQuery?.error?.response?.data?.message ||
                    reservationQuery?.error?.message ||
                    "Hata oluştu"}
                </p>
              </Banner>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <Page
      title="Rezervasyon Detay"
      narrowWidth
      breadcrumbs={[{ content: "Rezervasyonlar", url: "/reservations" }]}
      primaryAction={
        data?.status !== "İptal" && {
          content: "İptal et",
          destructive: true,
          loading: cancelMutation.isLoading,
          disabled: cancelMutation.isLoading,
          onAction: onCancelHandler,
        }
      }
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <Layout.Section>
          <Card sectioned title="Temel Bilgiler">
            <DescriptionList
              items={[
                {
                  term: "Rezervasyon Türü",
                  description: (
                    <Badge status="info">
                      {data?.reservationType?.title || "-"}
                    </Badge>
                  ),
                },
                {
                  term: "Tarih",
                  description: new Date(data?.date)?.toLocaleDateString(
                    "tr-TR"
                  ),
                },

                {
                  term: "Ders Saati",
                  description: `${data?.from} - ${data?.to}`,
                },
                {
                  term: "Kredi Değeri",
                  description: data?.creditValue,
                },
                {
                  term: "Açıklama",
                  description: data?.description || "-",
                },
                {
                  term: "Üye",
                  description: (
                    <a
                      href={`/customers/${data?.customer?._id}`}
                      target="_blank"
                    >
                      <div>{data?.customer?.name}</div>
                      <TextStyle variation="subdued">
                        {data?.customer?.email}
                      </TextStyle>
                    </a>
                  ),
                },
                {
                  term: "Eğitmen",
                  description: (
                    <a
                      href={`/educators/${data?.educator?._id}`}
                      target="_blank"
                    >
                      {data?.educator?.name}
                    </a>
                  ),
                },
                {
                  term: "Durum",
                  description:
                    data?.status === "İptal" ? (
                      <Badge status="critical">İptal Edildi</Badge>
                    ) : (
                      <Badge status="info">Aktif</Badge>
                    ),
                },
              ]}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
