import { Filters, Select, Stack, TextField } from "@shopify/polaris";

const keysMapper = {
  totalCredits: "Kalan Kredi",
  totalCreditsValue: "Toplam Kredi",
  totalReservations: "Toplam Rezervasyon",
  status: "Durum",
  memberType: "Üye Tipi",
};

function makeLabel(filterValue) {
  switch (typeof filterValue) {
    case "string":
      return filterValue;
    case "object":
      return Array.isArray(filterValue)
        ? filterValue.filter((item) => !!item).join(" - ")
        : "";
    default:
      return;
  }
}

export function CustomerFilter({
  query,
  setQuery,
  filters,
  setFilters,
  initialFilters,
}) {
  const {
    status,
    memberType,
    totalCredits,
    totalCreditsValue,
    totalReservations,
  } = filters;

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const appliedFilters = Object.keys(filters)
    .filter((filterKey) => {
      const filterValue = filters[filterKey];
      return filterValue && filterValue.length > 0;
    })
    .map((filterKey) => {
      const filterValue = filters[filterKey];
      return {
        key: filterKey,
        label: `${keysMapper[filterKey]}: ${makeLabel(filterValue)}`,
        onRemove: () =>
          handleFilterChange(filterKey, Array.isArray(filterValue) ? [] : ""),
      };
    });
  const filterElements = [
    {
      key: "memberType",
      label: "Üye Tipi",
      filter: (
        <Select
          value={memberType}
          onChange={(value) => handleFilterChange("memberType", value)}
          options={["", "Normal Üye", "Misafir Üye"]}
        />
      ),
      onRemove: () => handleFilterChange("memberType", ""),
    },
    {
      key: "totalCredits",
      label: "Kalan Kredi Sayısı",
      onRemove: () => handleFilterChange("totalCredits", []),
      filter: (
        <div className="flex items-center gap-2">
          <TextField
            label="Min"
            type="number"
            value={totalCredits?.[0]?.toString() || ""}
            onChange={(value) => {
              const copyData = [...totalCredits];
              if (copyData.length > 0) {
                copyData[0] = value;
              } else {
                copyData.push(value);
              }
              handleFilterChange("totalCredits", copyData);
            }}
            min={0}
            autoComplete="off"
          />
          <TextField
            label="Max"
            type="number"
            min={0}
            value={totalCredits?.[1]?.toString() || ""}
            onChange={(value) => {
              const copyData = [...totalCredits];
              if (copyData.length > 1) {
                copyData[1] = value;
              } else {
                copyData.push(value);
              }
              handleFilterChange("totalCredits", copyData);
            }}
            autoComplete="off"
          />
        </div>
      ),
    },
    {
      key: "totalCreditsValue",
      label: "Toplam Kredi Sayısı",
      filter: (
        <div className="flex items-center gap-2">
          <TextField
            label="Min"
            type="number"
            value={totalCreditsValue?.[0]?.toString() || ""}
            onChange={(value) => {
              const copyData = [...totalCreditsValue];
              if (copyData.length > 0) {
                copyData[0] = value;
              } else {
                copyData.push(value);
              }
              handleFilterChange("totalCreditsValue", copyData);
            }}
            min={0}
            autoComplete="off"
          />
          <TextField
            label="Max"
            type="number"
            min={0}
            value={totalCreditsValue?.[1]?.toString() || ""}
            onChange={(value) => {
              const copyData = [...totalCreditsValue];
              if (copyData.length > 1) {
                copyData[1] = value;
              } else {
                copyData.push(value);
              }
              handleFilterChange("totalCreditsValue", copyData);
            }}
            autoComplete="off"
          />
        </div>
      ),
      onRemove: () => handleFilterChange("totalCreditsValue", []),
    },
    {
      key: "totalReservations",
      label: "Rezervasyon Sayısı",
      filter: (
        <div className="flex items-center gap-2">
          <TextField
            label="Min"
            type="number"
            value={totalReservations?.[0]?.toString() || ""}
            onChange={(value) => {
              const copyData = [...totalReservations];
              if (copyData.length > 0) {
                copyData[0] = value;
              } else {
                copyData.push(value);
              }
              handleFilterChange("totalReservations", copyData);
            }}
            min={0}
            autoComplete="off"
          />
          <TextField
            label="Max"
            type="number"
            min={0}
            value={totalReservations?.[1]?.toString() || ""}
            onChange={(value) => {
              const copyData = [...totalReservations];
              if (copyData.length > 1) {
                copyData[1] = value;
              } else {
                copyData.push(value);
              }
              handleFilterChange("totalReservations", copyData);
            }}
            autoComplete="off"
          />
        </div>
      ),
      onRemove: () => handleFilterChange("totalReservations", []),
    },
  ];

  const handleClearAll = () => {
    setFilters(initialFilters);
  };

  const handleQueryClear = () => {
    setQuery("");
  };

  const handleQueryChange = (value) => {
    setQuery(value);
  };

  return (
    <Filters
      queryValue={query}
      queryPlaceholder="Üye numarası, isim, email veya telefon numarası ile arayın"
      filters={filterElements}
      appliedFilters={appliedFilters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryClear}
      onClearAll={handleClearAll}
    />
  );
}
