import { axios } from "utils";

export const getAllEducators = (payload = "") =>
  axios.get(`/educators?sort=name${payload}&status=Aktif`).then((res) => res.data?.docs);

export const getMembersCountOfEducator = (educatorId) => axios.get(`/educators/${educatorId}/membersCount`).then((res) => res.data);

export const queryEducators = (payload) =>
  axios.post(`/educators/query`, payload).then((res) => res.data?.docs);

export const getOneEducator = (educatorId) =>
  axios.get(`/educators/${educatorId}`).then((res) => res.data?.doc);

export const createOneEducator = (payload) =>
  axios.post("/educators", payload).then((res) => res.data?.doc);

export const updateOneEducator = (payload, educatorId) =>
  axios.patch(`/educators/${educatorId}`, payload).then((res) => res.data?.doc);

export const deleteManyEducators = (payload) =>
  axios.post(`/educators/deleteMany`, payload).then((res) => res.data?.docs);

export const deleteOneEducator = (payload) =>
  axios.delete(`/educators`, payload).then((res) => res.data?.doc);
