import { Avatar, Button, IndexTable, TextStyle } from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { Link } from "components";

export function HelpsRows({ data = [] }) {
  return data.map(
    ({ _id, createdAt, customer, subject, message, type, closed }, index) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>
          <Link url={`/helps/${_id}`}>
            <TextStyle variation="strong">{subject}</TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="py-5 flex items-center">
            <div>
              <Avatar customer name={customer?.name} />
            </div>
            <div className="pl-2 flex flex-col">
              <Link url={`/customers/${customer?._id}`}>
                <TextStyle variation="strong">{customer?.name}</TextStyle>
              </Link>
              <TextStyle variation="subdued">{customer?.email}</TextStyle>
            </div>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {new Date(createdAt).toLocaleDateString()}
        </IndexTable.Cell>
        <IndexTable.Cell>{message}</IndexTable.Cell>
        <IndexTable.Cell>{type}</IndexTable.Cell>
        <IndexTable.Cell>{closed ? "Evet" : "Hayır"}</IndexTable.Cell>
        <IndexTable.Cell>
          <Button url={`/helps/${_id}`} icon={EditMinor} plain />
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
