import {
  Page,
  DataTable,
  Card,
  Layout,
  FormLayout,
  Button,
  TextField,
  Label,
} from "@shopify/polaris";
import { useMutation, useQuery } from "react-query";
import { useFormik } from "formik";
import { format } from "date-fns";

import { getPaymentsReports } from "api";
import { formatCurrency } from "utils";

import { validationSchema } from "../ReservationsReports/validationSchema";

export function PaymentsReports(props) {
  const paymentsMutation = useMutation(getPaymentsReports);
  const today = new Date();

  const reportsData = paymentsMutation.data || [];

  const postReportsHandler = ({ startDate, endDate }) => {
    const query = `?startDate=${startDate}&endDate=${endDate}`;

    paymentsMutation.mutate(query, {
      onSuccess: (response) => {},
      onError: (error) => {},
    });
  };

  const { values, setFieldValue, errors, handleBlur, touched, handleSubmit } =
    useFormik({
      initialValues: {
        startDate: `${today.getFullYear()}-${today.getMonth() + 1}-01`,
        endDate: `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`,
      },
      onSubmit: postReportsHandler,
      validationSchema,
    });

  return (
    <Page title="Günlük Ödemeler Raporu">
      <Layout sectioned>
        <Layout.Section fullWidth>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  value={values?.startDate}
                  onChange={(value) => setFieldValue("startDate", value)}
                  type="date"
                  label="Başlangıç Tarihi"
                  error={
                    errors.startDate && touched.startDate && errors.startDate
                  }
                  onBlur={handleBlur}
                  name="startDate"
                />
                <TextField
                  value={values?.endDate}
                  onChange={(value) => setFieldValue("endDate", value)}
                  type="date"
                  label="Bitiş Tarihi"
                  onBlur={handleBlur}
                  error={errors.endDate && touched.endDate && errors.endDate}
                  name="endDate"
                />
                <div>
                  <div className="pb-2 opacity-0">
                    <Label>ads</Label>
                  </div>
                  <Button
                    primary
                    loading={paymentsMutation?.isLoading}
                    onClick={handleSubmit}
                  >
                    Sorgula
                  </Button>
                </div>
              </FormLayout.Group>
            </FormLayout>
            <div className="mb-10"></div>
            <DataTable
              columnContentTypes={["text", "numeric", "numeric"]}
              headings={["Tarih", "Toplam Tutar", "Toplam Kredi"]}
              rows={reportsData?.map((v) => [
                format(new Date(v?._id), "dd.MM.yyyy"),
                formatCurrency(v?.totalAmount),
                v?.totalCredit,
              ])}
              totals={[
                "",
                `${formatCurrency(
                  reportsData?.reduce((acc, a) => acc + +a?.totalAmount, 0)
                )}`,
                `${reportsData?.reduce((acc, a) => acc + +a?.totalCredit, 0)}`,
              ]}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
