import { ContextualSaveBar, Layout, Page } from "@shopify/polaris";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { getOneHelp, updateOneHelp } from "api";
import { SharedForm } from "../SharedForm";

export function HelpDetail(props) {
  const skipToContentRef = useRef(null);
  const { helpId } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getOneHelpOnsuccess = (response) => {
    const payload = {
      subject: response.subject,
      message: response.message,
      type: response.type,
      createdAt: response.createdAt,
      closed: response.closed,
      customer: response.customer,
    };
    setInitialValues(payload);
  };

  useQuery(["help", helpId], () => getOneHelp(helpId), {
    onSuccess: getOneHelpOnsuccess,
  });

  const patchMutation = useMutation(
    (payload) => updateOneHelp(payload, helpId),
    {
      onSuccess: (response) => {},
      onError: (error) => {},
    }
  );

  return (
    <Page
      title="Yardım Talebi Detay"
      narrowWidth={true}
      breadcrumbs={[{ content: "Yardım Talepleri", url: "/helps" }]}
    >
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {initialValues && (
          <SharedForm
            initialValues={initialValues}
            isDetail={true}
            onSubmit={(values) => patchMutation.mutate(values)}
            savebarRender={(values) => (
              <Savebar {...values} isLoading={patchMutation.isLoading} />
            )}
          />
        )}
      </Layout>
    </Page>
  );
}

function Savebar({ handleSubmit, isValid, isLoading }) {
  const history = useHistory();
  return (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit,
        loading: isLoading,
        disabled: !isValid,
        content: "Güncelle",
      }}
      message="Kayıt Detay"
      discardAction={{
        onAction: () => history.push("/helps"),
        content: "Geri",
      }}
    />
  );
}
