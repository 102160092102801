import { AppLayout } from "components";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  CustomerDetail,
  CustomersList,
  NewCustomer,
  AdminHome,
  EducatorDetail,
  EducatorsList,
  EducatorUnavailability,
  EducatorMembers,
  NewEducator,
  NewHorse,
  HorsesList,
  HorseDetail,
  NewUser,
  UsersList,
  UserDetail,
  AccountingList,
  NewRecord,
  RecordDetail,
  AccountingCategoriesList,
  NewAccountingCategory,
  AccountingCategoryDetail,
  HelpDetail,
  HelpsList,
  NewPayment,
  PaymentsList,
  PaymentDetail,
  EducatorAvailability,
  HorseAvailability,
  ReservationDetail,
  ReservationsList,
  NewReservation,
  Profile,
  UpdatePassword,
  ReservationsReports,
  PaymentsReports,
  HorseUnavailability,
  PaymentRequestsList,
  SMSList,
  NewSms
} from "views";
import { useAuth } from "providers";
import {
  DetailReservationPackage,
  NewReservationPackage,
  ReservationPackagesList,
} from "views/ReservationPackages";
import {
  DetailReservationType,
  NewReservationType,
  ReservationTypesList,
} from "views/ReservationTypes";

export function AuthenticatedApp() {
  const { state } = useAuth();

  if (!state?.user?.passwordChangedAt) {
    return (
      <Switch>
        <Route exact path="/update-password">
          <UpdatePassword />
        </Route>
        <Route path="/">
          <Redirect to="/update-password" />
        </Route>
      </Switch>
    );
  }
  return (
    <AppLayout>
      <Switch>
        <Route exact path="/reports/reservations">
          <ReservationsReports />
        </Route>
        <Route exact path="/reports">
          <PaymentsReports />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/update-password">
          <UpdatePassword />
        </Route>
        <Route exact path="/reservations/new">
          <NewReservation />
        </Route>
        <Route exact path="/reservations/:reservationId">
          <ReservationDetail />
        </Route>
        <Route exact path="/reservations">
          <ReservationsList />
        </Route>
        <Route exact path="/paymentRequests">
          <PaymentRequestsList />
        </Route>
        <Route exact path="/payments/new">
          <NewPayment />
        </Route>
        <Route exact path="/payments/:paymentId">
          <PaymentDetail />
        </Route>
        <Route exact path="/payments">
          <PaymentsList />
        </Route>
        <Route exact path="/helps/:helpId">
          <HelpDetail />
        </Route>
        <Route exact path="/helps">
          <HelpsList />
        </Route>
        <Route exact path="/reservationPackages/new">
          <NewReservationPackage />
        </Route>
        <Route exact path="/reservationPackages/:reservationPackageId">
          <DetailReservationPackage />
        </Route>
        <Route exact path="/reservationPackages">
          <ReservationPackagesList />
        </Route>
        <Route exact path="/reservationTypes/new">
          <NewReservationType />
        </Route>
        <Route exact path="/reservationTypes/:reservationTypeId">
          <DetailReservationType />
        </Route>
        <Route exact path="/reservationTypes">
          <ReservationTypesList />
        </Route>
        <Route exact path="/accounting/categories/new">
          <NewAccountingCategory />
        </Route>
        <Route exact path="/accounting/categories/:accountingCategoryId">
          <AccountingCategoryDetail />
        </Route>
        <Route exact path="/accounting/categories">
          <AccountingCategoriesList />
        </Route>
        <Route exact path="/accounting/new">
          <NewRecord />
        </Route>
        <Route exact path="/accounting/:accountingId">
          <RecordDetail />
        </Route>
        <Route exact path="/accounting">
          <AccountingList />
        </Route>
        <Route exact path="/users/new">
          <NewUser />
        </Route>
        <Route exact path="/users/:userId">
          <UserDetail />
        </Route>
        <Route exact path="/users">
          <UsersList />
        </Route>
        <Route exact path="/horses/:horseId/availability">
          <HorseAvailability />
        </Route>
        <Route exact path="/horses/:horseId/unavailability">
          <HorseUnavailability />
        </Route>
        <Route exact path="/horses/new">
          <NewHorse />
        </Route>
        <Route exact path="/horses/:horseId">
          <HorseDetail />
        </Route>
        <Route exact path="/horses">
          <HorsesList />
        </Route>
        <Route exact path="/educators/:educatorId/availability">
          <EducatorAvailability />
        </Route>
        <Route exact path="/educators/:educatorId/unavailability">
          <EducatorUnavailability />
        </Route>
        <Route exact path="/educators/:educatorId/members">
          <EducatorMembers />
        </Route>
        <Route exact path="/educators/new">
          <NewEducator />
        </Route>
        <Route exact path="/educators/:educatorId">
          <EducatorDetail />
        </Route>
        <Route exact path="/educators">
          <EducatorsList />
        </Route>
        <Route exact path="/customers/new">
          <NewCustomer />
        </Route>
        <Route exact path="/customers/:customerId">
          <CustomerDetail />
        </Route>
        <Route exact path="/customers">
          <CustomersList />
        </Route>
        <Route exact path="/sms/send">
          <NewSms />
        </Route>
        <Route exact path="/sms">
          <SMSList />
        </Route>
        <Route path="/">
          <AdminHome />
        </Route>
      </Switch>
    </AppLayout>
  );
}
