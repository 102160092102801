import {
  IndexTable,
  TextStyle,
} from "@shopify/polaris";
import { Link } from "components";

export function SMSRows({ data = [], onDelete }) {
  return data.map(
    (
      {
        _id,
        title,
        message,
        customers
      },
      index
    ) => (
      <IndexTable.Row id={_id} key={_id} position={index} subdued>
        <IndexTable.Cell>
          <Link url={`/sms/${_id}`}>
            <TextStyle variation="strong">{title}</TextStyle>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextStyle>
            {message}
          </TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextStyle>
            {customers.filter(c => c?.status !== "error").length} / {customers.length}
          </TextStyle>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
}
