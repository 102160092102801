import {
  Badge,
  Card,
  DescriptionList,
  Heading,
  Layout,
  Modal,
  Page,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { deleteOnePayment, getOnePayment } from "api";
import { SkeletonPage } from "components";
import { useSnackbar } from "providers";
import { useRef, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { array, formatCurrency } from "utils";

export function PaymentDetail(props) {
  const skipToContentRef = useRef(null);
  const { paymentId } = useParams();
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const deleteOneMutation = useMutation(deleteOnePayment);
  const { toggleSnack } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paymentQuery = useQuery(["payments", paymentId], () =>
    getOnePayment(paymentId)
  );

  const data = paymentQuery?.data;

  const openDeleteModal = () => {
    setActiveDeleteModal(true);
  };

  const handleDeleteCloseModal = () => {
    setActiveDeleteModal(false);
  };

  const deleteSubmitHandler = () => {
    deleteOneMutation.mutate(paymentId, {
      onSuccess: async (response) => {
        handleDeleteCloseModal();
        await paymentQuery.refetch();
        toggleSnack({
          key: "paymentDeleteSuccess",
          message: "Ödeme başarıyla silindi",
          active: true,
        });
      },
      onError: (error) => {
        toggleSnack({
          key: "paymentDeleteError",
          message:
            error?.response?.data?.message || error?.message || "Hata oluştu",
          active: true,
          error: true,
        });
      },
    });
  };

  if (paymentQuery?.isFetching) {
    return <SkeletonPage />;
  }

  return (
    <Page
      title="Ödeme Detay"
      narrowWidth={true}
      breadcrumbs={[{ content: "Ödemeler", url: "/payments" }]}
      primaryAction={
        !data?.isDeleted && {
          content: "Ödeme Sil",
          onAction: openDeleteModal,
          destructive: true,
        }
      }
    >
      <Modal
        open={activeDeleteModal}
        onClose={handleDeleteCloseModal}
        primaryAction={{
          content: "Sil",
          destructive: true,
          loading: deleteOneMutation.isLoading,
          onAction: deleteSubmitHandler,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <Heading>Ödeme Silinecektir.</Heading>
            <p>
              İlgili ödeme silinecektir. Üyenin mevcut kredisi, yapılan ödemenin
              kredi değeri kadar azalacaktır. Onaylıyor musunuz?
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Layout>
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        <Layout.Section>
          <Card sectioned title="Genel Bilgiler">
            <DescriptionList
              items={[
                {
                  term: "Başlık",
                  description: data?.title,
                },
                {
                  term: "Açıklama",
                  description: data?.description || "-",
                },
                {
                  term: "Tarih",
                  description: new Date(data?.date)?.toLocaleDateString(),
                },
                {
                  term: "Üye",
                  description: (
                    <Link
                      to={`/customers/${data?.customer?._id}`}
                      target="_blank"
                    >
                      <div>{data?.customer?.name}</div>
                      <TextStyle variation="subdued">
                        {data?.customer?.email}
                      </TextStyle>
                    </Link>
                  ),
                },
                {
                  term: "Durum",
                  description: data?.isDeleted ? (
                    <Badge status="critical">Silindi</Badge>
                  ) : (
                    <Badge status="info">Aktif</Badge>
                  ),
                },
              ]}
            />
          </Card>
          <Card sectioned title="Kredi Bilgileri">
            <DescriptionList
              items={[
                {
                  term: "Paket",
                  description: (
                    <Badge status="info">{data?.package?.packageName}</Badge>
                  ),
                },
                {
                  term: "Tutar",
                  description: formatCurrency(data?.amount),
                },
                ...array(data?.credits).map((credit) => ({
                  term: credit?.reservationType?.title,
                  description: credit?.creditValue + " Adet",
                })),
                {
                  term: "Toplam Kredi",
                  description: data?.creditValue + " Adet",
                },
              ]}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
