import {
  Page,
  Layout,
  Card,
  Banner,
  Avatar,
  Heading,
  TextStyle,
  Spinner,
  FormLayout,
  TextField,
} from "@shopify/polaris";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getMe, updateMe } from "api";

export function Profile(props) {
  const { isLoading, data } = useQuery("getMe", getMe);

  const queryClient = useQueryClient();

  const { mutate, isLoading: updateLoading } = useMutation(updateMe);

  const initialValues = {
    email: data?.email,
    name: data?.name,
    tel: data?.tel,
  };

  const onSubmitHandler = (values) => {
    mutate(values, {
      onSuccess: (response) => {
        queryClient.setQueryData("getMe", response);
        alert("Profil başarıyla güncellendi");
      },
      onError: (error) => {
        console.error(error?.response);
      },
    });
  };

  const {
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  return (
    <Page title="Profil" narrowWidth>
      <Layout>
        {isLoading ? (
          <Layout.Section>
            <Spinner size="large" />
          </Layout.Section>
        ) : (
          <>
            <Layout.Section>
              <Card
                title="Kişisel Bilgiler"
                sectioned
                footerActionAlignment="left"
                primaryFooterAction={{
                  content: "Kaydet",
                  onAction: handleSubmit,
                  loading: updateLoading,
                }}
              >
                <FormLayout>
                  {data && data?.isEmailVerified === false && (
                    <Banner status="critical">
                      Email adresinizi onaylamanız gerekiyor.
                    </Banner>
                  )}
                  {data && data?.isPhoneVerified === false && (
                    <Banner status="critical">
                      Telefon numaranızı onaylamanız gerekiyor.
                    </Banner>
                  )}
                  <TextField
                    label="İsim"
                    value={values.name}
                    name="name"
                    onChange={(value) => setFieldValue("name", value)}
                    onBlur={handleBlur}
                  />
                  <TextField
                    label="Telefon"
                    helpText="Telefon numarası alanında güncelleme yaptığınız takdirde telefon numaranızı onaylamanız gerekmektedir."
                    value={values.tel}
                    name="tel"
                    onChange={(value) => setFieldValue("tel", value)}
                    onBlur={handleBlur}
                  />
                  <TextField
                    label="Email"
                    value={values.email}
                    name="email"
                    type="email"
                    onChange={(value) => setFieldValue("email", value)}
                    onBlur={handleBlur}
                    helpText="Email adresi alanında güncelleme yaptığınız takdirde email adresinizi onaylamanız gerekmektedir."
                  />
                </FormLayout>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <Card
                title="Şifreyi Değiştir"
                sectioned
                footerActionAlignment="left"
                primaryFooterAction={{
                  content: "Değiştir",
                  url: "/update-password",
                }}
              >
                <TextStyle>
                  Mevcut şifrenizi girerek yeni şifrenizi oluşturabilirsiniz.
                </TextStyle>
              </Card>
            </Layout.Section>
          </>
        )}
      </Layout>
    </Page>
  );
}
