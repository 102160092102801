import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";
import { useFormikContext } from "formik";

export function InfoSection({ isDetail }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();
  return (
    <Layout.AnnotatedSection
      title="Temel Bilgiler"
      description="Yardım kaydı bilgilerini eksiksiz ve doğru girdiğinizden emin olun."
    >
      <Card sectioned>
        <FormLayout>
          <TextField
            label="Konu"
            name="subject"
            requiredIndicator
            value={values.subject}
            onChange={(value) => setFieldValue("subject", value)}
            onBlur={handleBlur}
          />
          <TextField
            label="Mesaj"
            multiline={true}
            name="message"
            value={values.message}
            onChange={(value) => setFieldValue("message", value)}
            onBlur={handleBlur}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}
