import React, { useCallback, useRef, useState } from "react";
import { ActionList, Frame, TopBar, Loading } from "@shopify/polaris";
import { LogOutMinor } from "@shopify/polaris-icons";

import { Sidebar } from "components";
import { useAuth } from "providers";

export function AppLayout(props) {
  const skipToContentRef = useRef(null);
  const { makeAdminLogoutRequest, logoutLoading, state } = useAuth();

  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const userMenuActions = [
    {
      items: [
        {
          content: "Çıkış Yap",
          icon: LogOutMinor,
          onAction: () => {
            makeAdminLogoutRequest({
              onSuccess: () => {
                console.log("Çıkış işlemi başarılı");
              },
              onError: (error) => {
                console.log(error, "Çıkış işlemi başarısız");
              },
            });
          },
        },
      ],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={state?.user?.name}
      detail={state?.user?.email}
      initials={state?.user?.name && state?.user?.name[0]}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  return (
    <Frame
      topBar={topBarMarkup}
      navigation={
        <Sidebar onClickedNavItem={() => setMobileNavigationActive(false)} />
      }
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef.current}
    >
      {logoutLoading && <Loading />}
      {props.children}
    </Frame>
  );
}
