import NumberFormat from "react-number-format";
import cn from "classnames";

export function PhoneNumber({
  name,
  label,
  value,
  onChange,
  onBlur,
  requiredIndicator,
  error,
  allowEmptyFormatting,
}) {
  const onChangeHandler = (values) => {
    onChange(values.formattedValue);
  };

  return (
    <>
      <div className="">
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label
              className={cn("Polaris-Label__Text", {
                "Polaris-Label__RequiredIndicator": !!requiredIndicator,
              })}
            >
              {label}
            </label>
          </div>
        </div>
        <div className="Polaris-Connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div
              className={cn("Polaris-TextField", {
                "Polaris-TextField--error": !!error,
              })}
            >
              <NumberFormat
                name={name}
                label={label}
                type="tel"
                allowEmptyFormatting={allowEmptyFormatting}
                className="Polaris-TextField__Input"
                value={value}
                onValueChange={onChangeHandler}
                onBlur={onBlur}
                format="+90 (###) ###-####"
                mask="_"
              />
              <div className="Polaris-TextField__Backdrop"></div>
            </div>
          </div>
        </div>
        {!!error && (
          <div className="Polaris-Labelled__Error">
            <div className="Polaris-InlineError">
              <div className="Polaris-InlineError__Icon">
                <span className="Polaris-Icon">
                  <span className="Polaris-VisuallyHidden"></span>
                  <svg
                    viewBox="0 0 20 20"
                    className="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zM9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"></path>
                  </svg>
                </span>
              </div>
              {error}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
