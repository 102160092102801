import * as yup from "yup";

const validationSchema = yup.object().shape({
  reservationType: yup.string().required("Rezervasyon tipi boş olamaz!"),
  price: yup.number().required("Fiyat boş olamaz!"),
  creditCount: yup
    .number()
    .required("Kredi adeti boş olamaz!")
    .test("isRequired", "Kredi adeti boş olamaz!", (value) => +value > 0),
});

export default validationSchema;
