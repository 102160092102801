import { Formik, Form } from "formik";
import { validationSchema } from "./validationSchema";
import {
  ContactPermissionsSection,
  InfoSection,
  ProfilePhotoSection,
  StatusSection,
  MemberInfoSection,
  OtherInfoSection,
  CreditsSection,
} from "./Sections";
import { Banner, List } from "@shopify/polaris";

export function SharedForm(props) {
  return (
    <Formik
      initialValues={props.initialValues}
      validateOnMount={true}
      onSubmit={props.onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isValid, submitCount, errors }) => (
        <Form onSubmit={handleSubmit}>
          {props.savebarRender({ handleSubmit, isValid })}
          {submitCount > 0 && errors && Object.keys(errors)?.length > 0 && (
            <Banner
              status="critical"
              title={`Toplam ${Object.keys(errors).length} hata var:`}
            >
              <List type="bullet">
                {Object.keys(errors).map((key) => (
                  <List.Item key={key}>{errors[key]}</List.Item>
                ))}
              </List>
            </Banner>
          )}
          <InfoSection isDetail={props.isDetail} />
          {props.isDetail && <CreditsSection />}
          <StatusSection />
          <MemberInfoSection />
          <OtherInfoSection />
          {/* <ProfilePhotoSection /> */}
          <ContactPermissionsSection />
        </Form>
      )}
    </Formik>
  );
}
