import { axios } from "utils";

export const adminLogin = (payload) =>
  axios.post(`/auth/admin/login`, payload).then((res) => res.data);

export const checkAdminToken = ({ token }) =>
  axios.post("/auth/admin/checkToken", {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then((res) => res.data);

export const adminLogout = () =>
  axios.post("/auth/admin/logout").then((res) => res.data);

export const forgotPassword = (payload) =>
  axios.post("/auth/admin/forgotPassword", payload).then((res) => res.data);

export const resetPassword = ({ token, ...payload }) =>
  axios
    .patch("/auth/admin/resetPassword/" + token, payload)
    .then((res) => res.data);


export const updatePassword = (payload) =>
  axios.patch("/auth/admin/updateMyPassword/", payload).then((res) => res.data);
