import { IndexTable, Page, Card } from "@shopify/polaris";
import { useHistory } from "react-router";
import { useQuery } from "react-query";

import { UserRows } from "./UserRows";
import { getAllUsers } from "api";

export function UsersList() {
  const history = useHistory();
  const { data, isLoading } = useQuery("users", getAllUsers);
  const resourceName = {
    singular: "kullanıcı",
    plural: "kullanıcılar",
  };

  const users = data || [];

  return (
    <Page
      title="Kullanıcılar"
      primaryAction={{
        content: "Yeni Kullanıcı",
        onAction: () => history.push("/users/new"),
      }}
    >
      <Card>
        <IndexTable
          selectable={false}
          loading={isLoading}
          resourceName={resourceName}
          itemCount={users.length}
          headings={[
            { title: "Kullanıcı" },
            { title: "Telefon" },
            { title: "Durum" },
            { title: "" },
          ]}
        >
          <UserRows data={users} />
        </IndexTable>
      </Card>
    </Page>
  );
}
