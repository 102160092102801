import { useState, useCallback, useEffect } from "react";
import {
  IndexTable,
  Page,
  Filters,
  Select,
  Card,
  Modal,
} from "@shopify/polaris";
import { useMutation, useQuery } from "react-query";

import { HelpsRows } from "./HelpsRows";
import { getAllHelps } from "api";

export function HelpsList(props) {
  const { data, isLoading } = useQuery("helps", getAllHelps);

  const resourceName = {
    singular: "yardım kaydı",
    plural: "yardım kayıtları",
  };

  const helps = data || [];
  return (
    <Page title="Yardım Talepleri">
      <Card>
        <IndexTable
          selectable={false}
          loading={isLoading}
          resourceName={resourceName}
          itemCount={helps.length}
          headings={[
            { title: "Başlık" },
            { title: "Müşteri" },
            { title: "Tarih" },
            { title: "Mesaj" },
            { title: "Tür" },
            { title: "Sonuçlandı mı?" },
            { title: "" },
          ]}
        >
          <HelpsRows data={helps} />
        </IndexTable>
      </Card>
    </Page>
  );
}
