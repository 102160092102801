import { IndexTable, Page, Card } from "@shopify/polaris";
import { useHistory } from "react-router";
import { useQuery } from "react-query";

import { getAllEducators, getMembersCountOfEducator } from "api";

import { EducatorRows } from "./EducatorRows";
import { array } from "utils";
import { useEffect, useMemo, useState } from "react";

export function EducatorsList(props) {
  const history = useHistory();

  const { data, isLoading } = useQuery("educators", () => getAllEducators());

  const resourceName = {
    singular: "eğitmen",
    plural: "eğitmenler",
  };

  const educators = array(data)

  const [membersCount, setMembersCount] = useState({})

  const educatorIds = useMemo(() => educators.map((educator) => educator._id), [educators]);

  useEffect(() => {
    if (educatorIds.length === 0) return

    const fetchMembersCount = async () => {
      const results = await Promise.all(
        educatorIds.map(async (educatorId) => {
          return getMembersCountOfEducator(educatorId)
        })
      )
      const membersCount = results.reduce((acc, result, index) => {
        acc[educatorIds[index]] = result
        return acc
      }, {})

      setMembersCount(membersCount)
    }
    fetchMembersCount()
  }, [educatorIds])
  return (
    <Page
      title="Eğitmenler"
      primaryAction={{
        content: "Yeni Eğitmen",
        onAction: () => history.push("/educators/new"),
      }}
    >
      <Card>
        <IndexTable
          lastColumnSticky
          selectable={false}
          loading={isLoading}
          resourceName={resourceName}
          itemCount={educators.length}
          headings={[
            { title: "-" },
            { title: "Eğitmen" },
            { title: "Telefon" },
            { title: "Ana Eğitmen" },
            { title: "Yedek Eğitmen" },
            { title: "Toplam Ders" },
            { title: "Durum" },
            { title: "" },
          ]}
        >
          <EducatorRows data={educators} membersCount={membersCount} />
        </IndexTable>
      </Card>
    </Page>
  );
}
