import { array } from "utils";

export const Customer = (p) => {
  let payload = {};
  if (!!p && typeof p === "object" && !Array.isArray(p)) {
    payload = Object.assign({}, p);
  }

  return {
    isDetail: payload.isDetail || true,
    memberNo: payload.memberNo || "",
    tckn: payload.tckn || "",
    birthDate: payload.birthDate || "",
    il: payload.il || "",
    ilce: payload.ilce || "",
    address: payload.address || "",
    meslek: payload.meslek || "",
    yakini: payload.yakini || "",
    yakinTel: payload.yakinTel || "",
    alerjiDurumu: payload.alerjiDurumu || "",
    kanGrubu: payload.kanGrubu || "",
    gozlukLensDurumu: payload.gozlukLensDurumu || "",
    doktorRaporuTarihi: payload.doktorRaporuTarihi || "",
    doktorRaporuKurum: payload.doktorRaporuKurum || "",
    referans: payload.referans || "",
    memberType: payload.memberType || "",
    name: payload.name || "",
    email: payload.email || "",
    mainEducator: payload.mainEducator || "",
    backupEducator: payload.backupEducator || "",
    horse: payload.horse || "",
    tel: payload.tel || "",
    avatar: undefined,
    permissions: {
      sms: payload?.permissions?.sms,
      email: payload?.permissions?.email,
      call: payload?.permissions?.call,
    },
    status: payload.status || "Aktif",
    credits: array(payload?.credits),
    statistics: payload?.statistics,
  };
};
