import { Layout } from "@shopify/polaris";
import { useFormikContext } from "formik";

import { SingleFileUpload } from "components";

export function ProfilePhotoSection(props) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Layout.AnnotatedSection
      title="Profil Fotoğrafı"
      description="Kullanıcının profil fotoğrafını ekleyebilirsiniz."
    >
      <SingleFileUpload
        name="avatar"
        file={values.avatar}
        setFile={(file) => setFieldValue("avatar", file)}
      />
    </Layout.AnnotatedSection>
  );
}
