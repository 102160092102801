import { useState, useEffect } from "react";
import { Frame, Loading } from "@shopify/polaris";

import { useAuth } from "providers";
import { AuthenticatedApp, UnauthenticatedApp } from "app";

export function App(props) {
  const { state, makeCheckAdminTokenRequest } = useAuth();
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    const token = window?.localStorage.getItem("token");
    const user = window?.localStorage.getItem("user");

    if (!token || !user || user === "undefined" || token === "undefined") {
      return setMounted(true);
    }
    makeCheckAdminTokenRequest({
      payload: { token },
      onSuccess: (response) => {
        console.log(response, "TOKEN CHECK VALID");
        setMounted(true);
      },
      onError: (error) => {
        console.log(error, "TOKEN CHECK ERROR");
        setMounted(true);
      },
    });
  }, []);

  if (!isMounted) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  return state.isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}
